<div class="wrapper">
  <div class="page-header bg-white">
    <div class="page-header-image"></div>
    <div class="container">
      <div class="col-lg-7 mx-auto">
        <div class="card bg-secondary shadow border-0">
          <div class="card-header">
            <img alt="Card image" class="card-img" src="assets/img/ill/bg5-1.svg" />

            <h4 class="card-title text-center">Passwort Zurücksetzen</h4>
          </div>
          <div class="card-body px-lg-5 py-lg-5">
            <div class="text-center text-muted mb-4">
              <small> Um das Passwort zurückzusetzen und einen Link anzufordern, bitte E-Mail Adresse angeben </small>
            </div>
            <form role="form" (ngSubmit)="onSubmit(email2.value)" [formGroup]="forgetPasswordForm">
              <div class="form-group mb-3" [ngClass]="{ 'focused': forgetPasswordFocus === true }">
                <div class="input-group input-group-alternative"
                  [ngClass]="{ 'has-danger': submitted && f.email.errors }">
                  <div class="input-group-prepend">
                    <span class="input-group-text">
                      <i class="ni ni-email-83"></i>
                    </span>
                  </div>
                  <input #email2 class="form-control" placeholder="Email" (blur)="forgetPasswordFocus = false"
                    (focus)="forgetPasswordFocus = true" type="email" formControlName="email"
                    [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                </div>
              </div>
              <div class="text-center">
                <button class="btn btn-primary my-4" (click)="onSubmit(email2.value)" type="button">
                  Abschicken
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>