import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Toaster } from 'ngx-toast-notifications';
import { concat, Observable, Subscription } from 'rxjs';
import { catchError, concatAll, finalize, first, switchMap, take } from 'rxjs/operators';
import { BaseComponent } from 'src/app/shared/base/components/base/base-component';
import { Branch } from 'src/app/shared/models/Branch';
import { AccountService } from 'src/app/_services';

@Component({
  selector: 'volkshandwerker-create-branches',
  templateUrl: './edit-branches.component.html',
  styleUrls: ['./edit-branches.component.scss']
})
export class EditBranchesComponent extends BaseComponent implements OnInit, OnDestroy {
  @ViewChild('attachments') attachment: any;

  public form: FormGroup;

  public branch: any;

  public imageFileList: File[] = [];
  public imageDataList: any[] = [];
  public listOfFiles: any[] = [];
  public strapiPicDataList: any[] = [];

  private subscriptions: Subscription[] = [];

  constructor(private formBuilder: FormBuilder,
    private accountService: AccountService,
    private router: Router,
    private route: ActivatedRoute,
    private toaster: Toaster
  ) {
    super();

    this.form = this.formBuilder.group({
      name: ['', Validators.required],
      postalCode: ['', Validators.required],
      city: ['', Validators.required],
      street: ['', Validators.required],
      houseNumber: ['', Validators.required],
      openingHours: ['', Validators.required],
      whatsappNumber: [''],
      telegramUser: ['']
    });

    this.dayForm = this.formBuilder.group({
      day: [''],
      start: [''],
      end: ['']
    });
  }

  selectedDay: string = 'Montag';
  showDayForm: boolean = false;
  dayForm: FormGroup;

  // Date-time picker
  isMeridian = false;
  readonly = true;
  startTime = new Date();
  endTime = new Date();

  workingHoursList = [
    { day: 'Montag', start: '08:00', end: '18:00', active: false },
    { day: 'Dienstag', start: '08:00', end: '18:00', active: false },
    { day: 'Mittwoch', start: '08:00', end: '18:00', active: false },
    { day: 'Donnerstag', start: '08:00', end: '18:00', active: false },
    { day: 'Freitag', start: '08:00', end: '18:00', active: false },
    { day: 'Samstag', start: '08:00', end: '18:00', active: false },
    { day: 'Sonntag', start: '08:00', end: '18:00', active: false }
  ]

  ngOnInit() {
    this.isLoading = true;
    this.startTime.setHours(8, 0, 0);
    this.endTime.setHours(18, 0, 0);

    // load Data
    this.route.params.subscribe(params => {
      this.accountService.makeGetRequest(`/branches/${params['id']}`).then((branch: Branch) => {
        this.branch = branch;

        this.form.get('name').setValue(this.branch?.name);
        this.form.get('postalCode').setValue(this.branch?.postalCode);
        this.form.get('city').setValue(this.branch?.city);
        this.form.get('street').setValue(this.branch?.street);
        this.form.get('houseNumber').setValue(this.branch?.houseNumber);
        this.form.get('openingHours').setValue(this.branch?.openingHours);
        this.form.get('whatsappNumber').setValue(this.branch?.whatsappNumber);
        this.form.get('telegramUser').setValue(this.branch?.telegramUser);

        this.branch?.WorkingTimes?.forEach(wh => {
          const _wh = this.workingHoursList.find(w => w.day === wh.day);
          _wh.start = wh.start;
          _wh.end = wh.end;
          _wh.active = true;
        });


        if (!this.IsNullOrUndefined(this.branch?.images)) {
          this.branch?.images.forEach(img => {
            this.strapiPicDataList.push(img);
          });
        }


        this.isLoading = false;
      });
    });

    this.form.markAllAsTouched();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  openingHourString(): string {
    return `${this.form.controls['startDay'].value}-${this.form.controls['endDay'].value} ${this.formatTime(this.startTime)}-${this.formatTime(this.endTime)}`
  }

  formatTime(time: Date): string {
    return new Intl.DateTimeFormat('en-GB', { hour: '2-digit', minute: '2-digit' }).format(time)
  }

  getActiveWorkingHours() {
    return this.workingHoursList.filter(wh => wh.active);
  }

  getUpperDay() {
    return this.workingHoursList.find(wh => !wh.active)?.day ?? null;
  }

  handleDayForm() {
    this.showDayForm = !this.showDayForm;
  }

  onSelected(day: string) {
    this.selectedDay = day;
  }

  updateSelectedDay() {
    const _upperDay = this.getUpperDay();
    this.selectedDay = _upperDay;
  }

  removeWorkingHour(day: string) {
    const _day = this.workingHoursList.find(a => a.day === day);
    if (_day) {
      _day.active = false;
      this.updateSelectedDay();

    }

  }

  addWorkingHour() {
    console.log(this.workingHoursList)

    const _day = this.workingHoursList.find(a => a.day === this.selectedDay);
    if (_day) {
      _day.active = true;
      _day.start = this.formatTime(this.startTime);
      _day.end = this.formatTime(this.endTime);
    }

    this.updateSelectedDay();

    this.showDayForm = false;
  }

  // convenience getter for easy access to form fields
  get f() { return this.form.controls; }

  onFileChanged(event: any) {
    for (let i = 0; i <= event.target.files.length - 1; i++) {
      const selectedFile = event.target.files[i];
      if (selectedFile.type != 'image/jpeg' && selectedFile.type != 'image/png') {
        this.toaster.open({
          text: 'Only JPG and PNG files are allowed',
          type: 'danger',
          position: 'bottom-left',
          duration: 5000
        });
        return;
      }
      this.imageFileList.push(selectedFile);
      this.listOfFiles.push(selectedFile.name);

      const reader = new FileReader();

      reader.onload = (event: any) => {
        this.imageDataList.push(event.target.result);

        this.form.patchValue({
          fileSource: this.imageFileList
        });
      }

      reader.readAsDataURL(event.target.files[i]);
    }

    this.attachment.nativeElement.value = '';
  }

  removeSelectedFile(index) {
    // Delete the item from fileNames list
    this.listOfFiles.splice(index, 1);
    // Delete the image Data from imageData list
    this.imageDataList.splice(index, 1);
    // delete file from FileList
    this.imageFileList.splice(index, 1);
  }

  removeSelectedStrapiFile(index) {
    // Delete the item from strapi file list
    this.strapiPicDataList.splice(index, 1);
  }

  onSubmit() {
    this.isLoadingSubmit = true;

    // stop here if form is invalid
    if (this.form.invalid) {
      return;
    }

    const uploadObservables$: Observable<File>[] = [];

    for (const fileToUpload of this.imageFileList) {
      uploadObservables$.push(this.accountService.uploadFile(fileToUpload, true));
    }

    concat(...uploadObservables$)
      .pipe(
        switchMap((file: File) => {
          this.strapiPicDataList.push(file);
          const index = this.imageFileList.findIndex(q => q.name === file.name);
          this.removeSelectedFile(index);

          let buffer;

          buffer = this.form.value;

          buffer['images'] = this.strapiPicDataList;
          buffer['WorkingTimes'] = this.workingHoursList.filter(wh => wh.active);

          return this.accountService.put(`/branches/${this.branch.id}`, buffer);
        }),
        catchError((error: any) => {
          this.isLoadingSubmit = false;
          this.toaster.open({
            caption: 'Fehler!',
            type: 'danger',
            text: 'Es ist ein Fehler beim Hochladen der Bilder aufgetreten. Bitte kontaktieren Sie uns.',
            position: 'bottom-left'
          });
          throw error;
        }),
        finalize(() => {
          this.isLoadingSubmit = false;
          if (uploadObservables$.length !== 0) {
            this.toaster.open({
              caption: 'Erfolgreich!',
              type: 'success',
              text: 'Ihre Daten wurden erfolgreich gespeichert.',
              position: 'bottom-left'
            });
          }
        })
      )
      .subscribe({
        next: (branch: Branch) => {
        },
        error: () => {
          this.isLoadingSubmit = false;
          this.toaster.open({
            caption: 'Fehler!',
            type: 'danger',
            text: 'Es ist ein Fehler aufgetreten. Bitte kontaktieren Sie uns.',
            position: 'bottom-left'
          });
        }
      });

    if (uploadObservables$.length === 0) {

      this.isLoadingSubmit = true;

      let buffer;

      buffer = this.form.value;

      buffer['images'] = this.strapiPicDataList;
      buffer['WorkingTimes'] = this.workingHoursList.filter(wh => wh.active);


      this.accountService
        .put(`/branches/${this.branch.id}`, buffer)
        .subscribe({
          next: (branch: Branch) => {
            this.isLoadingSubmit = false;
            this.toaster.open({
              caption: 'Erfolgreich!',
              type: 'success',
              text: 'Ihre Daten wurden erfolgreich gespeichert.',
              position: 'bottom-left'
            });
          },
          error: () => {
            this.isLoadingSubmit = false;
            this.toaster.open({
              caption: 'Fehler!',
              type: 'danger',
              text: 'Es ist ein Fehler aufgetreten. Bitte kontaktieren Sie uns.',
              position: 'bottom-left'
            });
          }
        });
    }

  }

}
