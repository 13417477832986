import { Component, OnInit } from '@angular/core';
import { Toaster } from 'ngx-toast-notifications';
import { BaseComponent } from 'src/app/shared/base/components/base/base-component';
import { Pack } from 'src/app/shared/models/Pack';
import { AccountService } from 'src/app/_services';

@Component({
  selector: 'volkshandwerker-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.scss']
})
export class PricingComponent extends BaseComponent implements OnInit {

  packs: Pack[] = [];

  constructor(
    private accountService: AccountService,
    private toaster: Toaster
  ) {
    super();
  }

  ngOnInit(): void {
    this.isLoading = true;

    // Fetch packs
    this.accountService.makeGetRequest('/packs').then((packs: Pack[]) => {
      this.packs = packs;

      this.isLoading = false;
    });
  }
}
