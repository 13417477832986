<form [formGroup]="form" (ngSubmit)="onSubmit(location.value, category.value)">
  <div class="row">

    <!-- LOCATION -->
    <div class="col-lg-5 col-md-5 col-sm-5 mb-4">
      <input #location class="form-control"
        [ngClass]="{ 'is-invalid': form.get('location').hasError('required') && form.get('location').touched }"
        formControlName="location" placeholder="Ort" type="text" />

      <!-- ERROR MESSAGE -->
      <ng-container *ngIf="form.get('location').hasError('required') && form.get('location').touched">
        <span class="form-field-error-message">
          Bitte geben Sie einen Ort an
        </span>
      </ng-container>
    </div>

    <!-- CATEGORIES -->
    <div class="col-lg-4 col-md-4 col-sm-4 mb-4">
      <select #category formControlName="category" class="form-control">
        <option value="-1">Alle Kategorien</option>
        <option *ngFor="let cat of categories" value="{{ cat.id }}">
          {{ cat.name }}
        </option>
      </select>

      <!-- ERROR MESSAGE -->
      <ng-container *ngIf="form.get('category').hasError('required') && form.get('category').touched">
        <span class="form-field-error-message">
          Bitte wählen Sie eine Kategorie aus
        </span>
      </ng-container>
    </div>

    <!-- SEARCH -->
    <div class="col-lg-3 col-md-3 col-sm-3 mb-4">
      <button class="form-control" type="submit" [disabled]="form.invalid === true"
        [ngClass]="{'btn.disabled': form.invalid === true, 'bg-primary': form.valid === true}"
        style="color: white; font-weight: bold; border: none;">
        SUCHEN
      </button>
    </div>
  </div>
</form>