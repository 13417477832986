<nav class="navbar navbar-main navbar-expand-lg navbar-dark bg-primary headroom" id="navbar-main">
  <div class="container">
    <a class="navbar-brand" [routerLink]="['/']">
      <img *ngIf="!dynamicLogo" [hidden]="dynamicLogo" class="volkshandwerker-logo"  [src]="logoUrl ? logoUrl : 'assets/pictures/volkshandwerker-logo-white-sub-v2.png'" />
      <img [hidden]="!dynamicLogo" class="volkshandwerker-logo h-80" id="dynamic-logo" [src]="'assets/pictures/volkshandwerker-logo-white-sub-v2.png'" />
    </a>
    <button aria-controls="navbar-primary" aria-label="Toggle navigation" class="navbar-toggler"
      [attr.aria-expanded]="!isCollapsed" (click)="isCollapsed = !isCollapsed" id="navbar-primary" type="button">
      <span class="navbar-toggler-icon"> </span>
    </button>
    <div class="navbar-collapse" [isAnimated]="true" [collapse]="isCollapsed" id="navbar-primary">
      <div class="navbar-collapse-header">
        <div class="row">
          <div class="col-6 collapse-brand">
            <a href="./index.html">
              <img class="volkshandwerker-logo" src="assets/pictures/volkshandwerker-logo-v2.png" />
            </a>
          </div>
          <div class="col-6 collapse-close">
            <button aria-controls="navbar-primary" aria-label="Toggle navigation" class="navbar-toggler"
              [attr.aria-expanded]="!isCollapsed" (click)="isCollapsed = !isCollapsed" id="navbar-primary"
              type="button">
              <span> </span> <span> </span>
            </button>
          </div>
        </div>
      </div>
      <ul class="navbar-nav ml-lg-auto">
        <!-- <li class="nav-item my-auto mx-auto">
          <a class="nav-link" href="https://forum.volkshandwerker.de">
            Forum
          </a>
        </li> -->
        <li *ngIf="!accountService.isLoggedIn()" class="nav-item my-auto mx-auto login-signup">
          <a class="nav-link signup-link" [routerLink]="['/register']">
            Handwerksbetrieb inserieren
          </a>
          <a class="nav-link signin-link" [routerLink]="['/login']">
            Anmelden
          </a>
        </li>
        <li
          *ngIf="accountService.isLoggedIn() && accountService.userValue?.user?.subscriber?.company?.subscriptionStatus == 'active' ? true : false"
          class="nav-item my-auto mx-auto">
          <a class="nav-link" [routerLink]="['/user-area/branches']">
            Filiale bearbeiten
          </a>
        </li>
        <li
          *ngIf="accountService.isLoggedIn() && !(accountService.userValue?.user?.subscriber?.company?.subscriptionStatus == 'active' ? true : false)"
          class="nav-item my-auto mx-auto">
          <a class="nav-link" [routerLink]="['/user-area/payment']">
            Paket auswählen
          </a>
        </li>
        <li
          *ngIf="accountService.isLoggedIn() && accountService.userValue?.user?.subscriber?.company?.subscriptionStatus == 'active' ? true : false"
          class="nav-item my-auto mx-auto">
          <a class="nav-link" [routerLink]="['/user-area/dashboard']">
            Dashboard
          </a>
        </li>
        <li *ngIf="accountService.isLoggedIn()" class="nav-item my-auto mx-auto nav-item__logout">
          <a class="nav-link" (click)="logout()">
            Logout
          </a>
        </li>
      </ul>
    </div>
  </div>
</nav>