import { Directive, Input } from '@angular/core';
import { __ } from 'src/app/shared/functions/object.functions';

/**
 * This class handles basic component features and provides general functions and properties.
 *
 * @export
 */
@Directive()
export abstract class BaseComponent {
  IsNullOrUndefined = __.IsNullOrUndefined;

  IsNullOrUndefinedOrEmpty = __.IsNullOrUndefinedOrEmpty;

  @Input() isLoading = false;

  @Input() isLoadingSubmit = false;
}
