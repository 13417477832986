export class __ {

    /**
     * Checks whether the passed argument is null or undefined, or not.
     *
     */
    static IsNullOrUndefined(argument: any) {
        return typeof argument === 'undefined' || argument === null;
    }

    /**
     * Checks whether the passed argument is null, undefined or empty, or not.
     *
     */
    static IsNullOrUndefinedOrEmpty(argument: any) {
        return typeof argument === 'undefined' || argument === null || argument === '' || argument.length === 0;
    }
}