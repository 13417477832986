<section class="section-header bg-primary text-white pb-9 pb-lg-12 mb-4 mb-lg-6">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 col-md-8 text-center">
                <h1 class="display-2 mb-3">E-Mail Bestätigung</h1>
            </div>
        </div>
    </div>
    <div class="pattern bottom"></div>
</section>
<section class="section section-lg pt-0">
    <div class="container mt-n7 mt-lg-n12 z-2">
        <div class="row justify-content-center">
            <div class="col">
                <div class="card shadow-soft border-light p-4 p-md-5">
                    <h3>
                        Prüfen Sie Ihr E-Mail Postfach
                    </h3>
                    <p>
                        Wir freuen uns, dass Sie sich für Volkshandwerker entschieden haben.
                        Um mit Volkshandwerker durchzustarten, bestätigen Sie bitte Ihre E-Mail Adresse in Ihrem Postfach bzw. Spam-Ordner.
                    </p>
                    <div>Willkommen bei Volkshandwerker</div>
                    <p>Ihr Volkshandwerker Team</p>
                </div>
            </div>
        </div>
    </div>
</section>