import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { CookieDialogComponent } from './cookie-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule
  ],
  declarations: [CookieDialogComponent],
  entryComponents: [CookieDialogComponent],
  exports: [CookieDialogComponent]
})
export class CookieDialogModule { }
