import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'volkshandwerker-agb',
  templateUrl: './agb.component.html',
  styleUrls: ['./agb.component.scss']
})
export class AgbComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
