<div class="fileinput text-center">
  <input type="file" (change)="handleImageChange($event)" #fileInput />
  <div class="thumbnail" [ngClass]="{ 'img-circle': avatar }">
    <img src="{{ imagePreviewUrl }}" alt="..." />
  </div>
  <div>
    <button *ngIf="file === null && avatar" class="btn btn-round btn-file mt-2" (click)="handleClick()">
      Foto hinzufügen
    </button>
    <button *ngIf="file === null && !avatar" class="btn btn-round btn-file mt-2" (click)="handleClick()">
      Foto wählen
    </button>
    <span *ngIf="file !== null">
      <button class="btn btn-round btn-file mt-2" (click)="handleClick()">
        ändern
      </button>
      <br *ngIf="avatar" />
      <button class="btn btn-round btn-danger mt-2" (click)="handleRemove()">
        <i class="fa fa-times"></i> entfernen
      </button>
    </span>
  </div>
</div>