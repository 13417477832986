<!-- SECTION CAPTION -->
<section class="section-header bg-primary text-white pt-9 pb-7 mb-4 mb-lg-6">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 col-md-8 text-center">
        <h1 class="display-2 mb-3">Filiale bearbeiten</h1>
      </div>
    </div>
  </div>
  <div class="pattern bottom"></div>
</section>

<!-- SECTION FORM -->
<section class="section section-lg pt-0">
  <div class="container mt-n7 mt-lg-n12 z-2">
    <div class="row justify-content-center">
      <div class="col">
        <div class="card shadow-soft border-light p-4 p-md-5">

          <!-- FORM -->
          <form [formGroup]="form" (ngSubmit)="onSubmit()" *ngIf="isLoading === false; else loadingIndicator">

            <!-- IMAGE TILES -->
            <div class="form-group">
              <h4>Bilder hochladen</h4>

              <!-- IMAGE-TILE -->
              <div class="row">
                <div
                  class="col-md-4 d-flex flex-column justify-content-center align-items-start m-3 py-3 px-4 bg-secondary"
                  style="border-radius: 0.5rem;" *ngFor="let strapiPic of strapiPicDataList; let index = index">
                  <!-- NAME -->
                  <div class="flex-fill mb-3">
                    {{strapiPic?.name}}
                  </div>

                  <!-- IMAGE -->
                  <img [src]="strapiPic?.url" height="150" width="200px" style="margin: 3px;">

                  <!-- REMOVE -->
                  <button class="btn btn-danger mt-3" (click)="removeSelectedStrapiFile(index)" type="button">
                    entfernen
                  </button>
                </div>
                <div
                  class="col-md-4 d-flex flex-column justify-content-center align-items-start m-3 py-3 px-4 bg-secondary"
                  style="border-radius: 0.5rem;" *ngFor="let selected of listOfFiles; let index = index">
                  <!-- NAME -->
                  <div class="flex-fill mb-3">
                    {{selected}}
                  </div>

                  <!--IMAGE -->
                  <img [src]="imageDataList[index]" height="150" width="200px" style="margin: 3px;">

                  <!-- REMOVE -->
                  <button class="btn btn-danger mt-3" (click)="removeSelectedFile(index)" type="button">
                    entfernen
                  </button>
                </div>
              </div>

              <!-- UPLOAD -->
              <button class="btn btn-primary mt-3" (click)="fileInput.click()" type="button">Datei auswählen</button>

              <input style="display: none" accept="image/jpeg,image/png" #attachments type="file"
                (change)="onFileChanged($event)" #fileInput multiple="true">
            </div>

            <!-- NAME -->
            <div class="form-group">
              <label for="name">Firmenname</label>
              <input type="text" formControlName="name" class="form-control"
                [ngClass]="{ 'is-invalid': !isLoadingSubmit && f.name.errors }" />
              <div *ngIf="!isLoadingSubmit && f.name.errors" class="invalid-feedback">
                <div *ngIf="form.get('name').hasError('required')">Name ist erforderlich</div>
              </div>
            </div>

            <!-- ROW -->
            <div class="row">

              <!-- POSTAL CODE -->
              <div class="form-group col-md-6">
                <label for="postalCode">Postleitzahl</label>
                <input type="text" formControlName="postalCode" class="form-control"
                  [ngClass]="{ 'is-invalid': !isLoadingSubmit && f.postalCode.errors }" />
                <div *ngIf="!isLoadingSubmit && f.postalCode.errors" class="invalid-feedback">
                  <div *ngIf="form.get('postalCode').hasError('required')">Postleitzahl ist erforderlich</div>
                </div>
              </div>

              <!-- CITY -->
              <div class="form-group col-md-6">
                <label for="city">Stadt</label>
                <input type="text" formControlName="city" class="form-control"
                  [ngClass]="{ 'is-invalid': !isLoadingSubmit && f.city.errors }" />
                <div *ngIf="!isLoadingSubmit && f.city.errors" class="invalid-feedback">
                  <div *ngIf="form.get('city').hasError('required')">Stadt ist erforderlich</div>
                </div>
              </div>
            </div>

            <!-- ROW -->
            <div class="row">

              <!-- STREET -->
              <div class="form-group col-md-6">
                <label for="street">Straße</label>
                <input type="text" formControlName="street" class="form-control"
                  [ngClass]="{ 'is-invalid': !isLoadingSubmit && f.street.errors }" />
                <div *ngIf="!isLoadingSubmit && f.street.errors" class="invalid-feedback">
                  <div *ngIf="form.get('street').hasError('required')">Straße ist erforderlich</div>
                </div>
              </div>

              <!-- STREET NUMBER -->
              <div class="form-group col-md-6">
                <label for="houseNumber">Hausnummer</label>
                <input type="text" formControlName="houseNumber" class="form-control"
                  [ngClass]="{ 'is-invalid': !isLoadingSubmit && f.houseNumber.errors }" />
                <div *ngIf="!isLoadingSubmit && f.houseNumber.errors" class="invalid-feedback">
                  <div *ngIf="form.get('houseNumber').hasError('required')">Hausnummer ist erforderlich</div>
                </div>
              </div>
            </div>

            <!-- OPENING HOURS -->
            <div>
              <label for="openingHours">Öffnungszeiten</label>
              <div class="oph-add" *ngIf="!showDayForm && (getUpperDay() != null)">
                <button (click)="handleDayForm()">+</button>
                <label>Öffnungszeiten hinzufügen</label>
              </div>

              <div class="oph-list" *ngIf="showDayForm">
                <select class="form-control" #value (change)="onSelected(value.value)">
                  <!-- <option *ngFor="let day of workingHoursList" value={{day.day}}>{{day.day}}</option> -->
                  <ng-container *ngFor="let day of workingHoursList">
                    <option *ngIf="!day.active" value={{day.day}}>{{day.day}}</option>
                  </ng-container>
                </select>
                <div class="frow">
                  <div>
                    <label>Startzeit</label>
                    <timepicker [(ngModel)]="startTime" [showMeridian]="isMeridian"
                      [ngModelOptions]="{standalone: true}">
                    </timepicker>
                  </div>
                  <div>
                    <label>Endzeit</label>
                    <timepicker [(ngModel)]="endTime" [showMeridian]="isMeridian" [ngModelOptions]="{standalone: true}">
                    </timepicker>
                  </div>
                </div>
                <button (click)="addWorkingHour()" class="add-time">+</button>
                <button (click)="addWorkingHour()" class="add-time-mobile">Arbeitszeit hinzufügen</button>
              </div>

              <ul class="active-days-list" *ngIf="getActiveWorkingHours().length > 0">
                <li *ngFor="let wh of getActiveWorkingHours()">
                  <div>
                    {{wh.day}} - {{wh.start}} - {{wh.end}}
                    <label (click)="removeWorkingHour(wh.day)">(löschen)</label>
                  </div>
                </li>
              </ul>

            </div>
            <!-- <div class="form-group">
              <label for="openingHours">Öffnungszeiten (z.B. Mo.-Fr.: 08:00 - 18:00 Uhr)</label>
              <input type="text" formControlName="openingHours" class="form-control"
                [ngClass]="{ 'is-invalid': !isLoadingSubmit && f.openingHours.errors }" />
              <div *ngIf="!isLoadingSubmit && f.openingHours.errors" class="invalid-feedback">
                <div *ngIf="form.get('openingHours').hasError('required')">Öffnungszeiten sind erforderlich</div>
              </div>
            </div> -->

            <!-- ROW -->
            <div class="row">

              <!-- WHATSAPP NUMBER -->
              <div class="form-group col-md-6">
                <label for="street">Whatsapp Nummer inklusive Ländervorwahl (z.B. +49)</label>
                <input type="text" formControlName="whatsappNumber" class="form-control" />
              </div>

              <!-- TELEGRAM USER -->
              <div class="form-group col-md-6">
                <label for="houseNumber">Telegram Nutzername</label>
                <input type="text" formControlName="telegramUser" class="form-control" />
              </div>
            </div>

            <!-- ACTIONS -->
            <div class="form-group d-flex justify-content-between">

              <!-- CANCEL -->
              <button [disabled]="isLoadingSubmit === true" [routerLink]="['/user-area/branches']"
                class="btn btn-light">
                zurück
              </button>

              <!-- SUBMIT -->
              <button class="btn btn-primary text-white d-flex justify-content-center align-items-center"
                [disabled]="form.invalid === true || isLoadingSubmit === true" type="submit"
                [ngClass]="{'btn.disabled submit--disabled': form.invalid === true || isLoadingSubmit === true, 'bg-primary': form.valid === true}">
                speichern

                <!-- LOADING INDICATOR -->
                <span *ngIf="isLoadingSubmit === true" class="spinner-border spinner-border-sm ml-2"></span>
              </button>
            </div>
          </form>

          <!-- LOADING INDICATOR -->
          <ng-template #loadingIndicator>
            <div class="d-flex justify-content-center align-items-center p-5">
              <span *ngIf="isLoading === true" class="spinner-border spinner-border"></span>
            </div>
          </ng-template>

        </div>
      </div>
    </div>
  </div>
</section>