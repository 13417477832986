import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Data } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first, switchMap } from 'rxjs/operators';
import { AccountService, AlertService } from 'src/app/_services';
import { Toaster } from 'ngx-toast-notifications';

@Component({
  selector: 'volkshandwerker-registerpage',
  templateUrl: 'register.component.html',
  styleUrls: ['register.component.css']
})
export class RegisterComponent implements OnInit, OnDestroy {

  public registerForm: FormGroup;
  public loginForm: FormGroup;

  public loginFocus = {
    usernameFocus: false,
    passwordFocus: false
  };

  usernamefocus;
  emailfocus;
  passwordfocus;
  useremailfocus;
  userpasswordfocus;
  namefocus;


  loading = false;
  submitted = false;
  returnUrl: string;
  errorMessage: string;

  headerHeight: number = 84;

  initialView: string;

  redirectAfterLogin: string;

  @HostListener('window:resize', ['$event'])
  onresize(event) {
    this.headerHeight = document.getElementById('navbar-main').offsetHeight;
  }

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private accountService: AccountService,
    private alertService: AlertService,
    private toaster: Toaster
  ) {
    this.initialView = this.route.snapshot.data.view;

    this.redirectAfterLogin = this.route.snapshot.queryParams['redirectTo'];
  }

  ngOnInit(): void {
    this.registerForm = this.formBuilder.group({
      registerUsername: ['', Validators.required],
      registerEmail: ['', Validators.compose([Validators.required, Validators.email])],
      registerPassword: ['', Validators.required],
      conditionCheck: [false, Validators.requiredTrue],
      //withdrawalPeriodCheck: [false, Validators.requiredTrue],
      rightOfWithdrawalCheck: [false, Validators.requiredTrue]
    });

    this.loginForm = this.formBuilder.group({
      loginUsername: ['', Validators.required],
      loginPassword: ['', Validators.required],
    });

    const body = document.getElementsByTagName('body')[0];
    body.classList.add('register-page');


    this.route.data.subscribe((data: Data) => {
      const container: HTMLElement = document.getElementById('container');

      if (this.route.snapshot.routeConfig.path === 'register') {
        if (!container.classList.contains('right-panel-active')) {
          container.classList.add('right-panel-active');
        }
      }
      if (this.route.snapshot.routeConfig.path === 'login') {
        if (container.classList.contains('right-panel-active')) {
          container.classList.remove('right-panel-active');
        }
      }
    });

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  ngOnDestroy() {
    const body = document.getElementsByTagName('body')[0];
    body.classList.remove('register-page');
  }

  get regf() { return this.registerForm.controls; }
  get logf() { return this.loginForm.controls; }

  switchFormPanel(): void {
    const container: HTMLElement = document.getElementById('container');
    if (container.classList.contains('right-panel-active')) {
      container.classList.remove('right-panel-active');
    } else {
      container.classList.add('right-panel-active');
    }
  }

  onSubmitRegister() {
    this.submitted = true;
    this.alertService.clear();
    if (!this.registerForm.invalid) {
      this.loading = true;

      const req = {
        username: this.registerForm.value.registerUsername,
        email: this.registerForm.value.registerEmail,
        password: this.registerForm.value.registerPassword
      };

      this.accountService.register(req)
        .pipe(first())
        .subscribe(
          data => {
            this.alertService.success('Registration erfolgreich', { keepAfterRouteChange: true });
            this.router.navigate(['/check-email'], { relativeTo: this.route });
          },
          error => {
            const errorMessage = error.error.error.message;
            if (errorMessage === 'Email is already taken') {
              this.regf['registerEmail'].setErrors({ taken: true });
              this.alertService.error('Email-Adresse oder Nutzername existiert bereits');
              this.loading = false;
            }
          });
    } else {
      this.alertService.error('Bitte korrigieren Sie ihre Angaben!');
    }
  }

  onSubmitLogin() {
    this.submitted = true;
    this.alertService.clear();
    if (this.logf.loginUsername.value !== '' && this.logf.loginPassword.value !== '') {
      this.loading = true;
      this.errorMessage = '';
      this.accountService.login(this.logf.loginUsername.value, this.logf.loginPassword.value)
        .pipe(first())
        .subscribe(
          (data) => {
            console.log(data)
            this.accountService.updateUserInfoAsync().then(a => {
              if (a.user.subscriber == null || this.accountService.userValue.user.subscriber?.company?.subscriptionStatus != 'active') {
                return this.router.navigate(['/user-area/payment']);
              }

              switch (this.redirectAfterLogin) {
                case 'packs':
                  this.router.navigate(['/user-area/payment']);
                  break;
                default:
                  this.router.navigate([this.returnUrl]);
                  break;
              }
            })
            //
          },
          error => {
            this.errorMessage = 'Nutzername oder Passwort ist nicht korrekt!';
            this.logf['loginUsername'].setErrors({ invalid: true });
            this.logf['loginPassword'].setErrors({ invalid: true });
            this.alertService.error(this.errorMessage);
            this.toaster.open({
              caption: 'Fehler!',
              type: 'danger',
              text: 'Benutzername oder Passwort ist falsch!',
              position: 'bottom-left'
            });
            this.loading = false;
          });
    }
  }
}

