import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Branch } from '../shared/models/Branch';
import { Company } from '../shared/models/Company';
import { AccountService } from '../_services/account.service';

@Component({
  selector: 'volkshandwerker-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  isCreatedBranch = true;

  constructor(public accountService: AccountService, private router: Router) { }

  ngOnInit() {
    if (this.accountService.isLoggedIn() && this.accountService.isSubscriber())
      this.accountService
        .makeGetRequest(`/companies?filters[subscriber][users_permissions_user][id][$eq]=${this.accountService.userValue.user.id}`)
        .then((companies: Company[]) => {
          if (companies[0]) {

            this.accountService.makeGetRequest(`/branches?filters[company][id][$eq]=${companies[0].id}`)
              .then((branches: Branch[]) => {
                if (branches?.length == 0) {
                  this.isCreatedBranch = false
                }

              });
          }
        });
  }

  navigateToBranches() {
    this.router.navigate(['/user-area/branches']);
  }

  isSubscriber() {
    return this.accountService.isSubscriber();
  }

  isLoggedIn() {
    return this.accountService.isLoggedIn();
  }

  logout() {
    this.accountService.logout();
    this.router.navigate(['/login']);
  }


}
