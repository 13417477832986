<section class="section-header bg-primary text-white pb-9 pb-lg-12 mb-4 mb-lg-6">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 col-md-8 text-center">
                <h1 class="display-2 mb-3">E-Mail Bestätigung</h1>
            </div>
        </div>
    </div>
    <div class="pattern bottom"></div>
</section>
<section class="section section-lg pt-0">
    <div class="container mt-n7 mt-lg-n12 z-2">
        <div class="row justify-content-center">
            <div class="col">
                <div class="card shadow-soft border-light p-4 p-md-5">
                    <p>Bitte überprüfen Sie ihr E-Mail Postfach, Sie werden in Kürze eine E-Mail bekommen um ihr Passwort zurückzusetzen.
                    </p>
                    <div>Mit freundlichen Grüßen</div>
                    <p>Ihr Volkshandwerker Team</p>
                </div>
            </div>
        </div>
    </div>
</section>
