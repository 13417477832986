import { Component, Inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DialogRef } from '@ngneat/dialog';

@Component({
  selector: 'volkshandwerker-cookie-dialog',
  templateUrl: './cookie-dialog.component.html',
  styleUrls: ['./cookie-dialog.component.scss']
})
export class CookieDialogComponent {

  static cookieKey = 'cookies_zg274g38hf9o4f';

  mode: 'Intro' | 'Manage' = 'Intro';

  necessaryFormControl: FormControl;

  analyticsFormControl: FormControl;

  personalizedAdsFormControl: FormControl;

  constructor(
    public dialogRef: DialogRef<CookieDialogComponent>,
  ) {
    this.necessaryFormControl = new FormControl({ disabled: true, value: true });
    this.analyticsFormControl = new FormControl(false);
    this.personalizedAdsFormControl = new FormControl(false);
  }

  accept(): void {
    localStorage.setItem(
      CookieDialogComponent.cookieKey,
      JSON.stringify(
        'All'
      )
    );

    this.dialogRef.close('All');
  }

  save(): void {
    localStorage.setItem(
      CookieDialogComponent.cookieKey,
      JSON.stringify(
        this.analyticsFormControl.value === true && this.personalizedAdsFormControl.value === true ? 'All' : 'Necessary'
      )
    );

    this.dialogRef.close(this.analyticsFormControl.value === true ? 'All' : 'Necessary');
  }
}
