<section class="text-white pt-9 mb-4 mb-lg-6">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 col-md-8 text-center">
        <h1 class="display-2 mb-3">AGB</h1>
      </div>
    </div>
  </div>
</section>
<section class="container overflow-hidden">
  <h2>1. Datenschutz auf einen Blick</h2>
  <h3>Allgemeine Hinweise</h3>
  <p>
    Die folgenden Hinweise geben einen einfachen Überblick darüber, was
    mit Ihren personenbezogenen Daten passiert, wenn Sie diese Website
    besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie
    persönlich identifiziert werden können. Ausführliche Informationen
    zum Thema Datenschutz entnehmen Sie unserer unter diesem Text
    aufgeführten Datenschutzerklärung.
  </p>
  <h3>Datenerfassung auf dieser Website</h3>
  <p>
    <strong>Wer ist verantwortlich für die Datenerfassung auf dieser
      Website?</strong>
  </p>
  <p>
    Die Datenverarbeitung auf dieser Website erfolgt durch den
    Websitebetreiber. Dessen Kontaktdaten können Sie dem Impressum
    dieser Website entnehmen.
  </p>
  <p><strong>Wie erfassen wir Ihre Daten?</strong></p>
  <p>
    Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese
    mitteilen. Hierbei kann es sich z.&nbsp;B. um Daten handeln, die Sie
    in ein Kontaktformular eingeben.
  </p>
  <p>
    Andere Daten werden automatisch oder nach Ihrer Einwilligung beim
    Besuch der Website durch unsere IT-Systeme erfasst. Das sind vor
    allem technische Daten (z.&nbsp;B. Internetbrowser, Betriebssystem
    oder Uhrzeit des Seitenaufrufs). Die Erfassung dieser Daten erfolgt
    automatisch, sobald Sie diese Website betreten.
  </p>
  <p><strong>Wofür nutzen wir Ihre Daten?</strong></p>
  <p>
    Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung
    der Website zu gewährleisten. Andere Daten können zur Analyse Ihres
    Nutzerverhaltens verwendet werden.
  </p>
  <p><strong>Welche Rechte haben Sie bezüglich Ihrer Daten?</strong></p>
  <p>
    Sie haben jederzeit das Recht, unentgeltlich Auskunft über Herkunft,
    Empfänger und Zweck Ihrer gespeicherten personenbezogenen Daten zu
    erhalten. Sie haben außerdem ein Recht, die Berichtigung oder
    Löschung dieser Daten zu verlangen. Wenn Sie eine Einwilligung zur
    Datenverarbeitung erteilt haben, können Sie diese Einwilligung
    jederzeit für die Zukunft widerrufen. Außerdem haben Sie das Recht,
    unter bestimmten Umständen die Einschränkung der Verarbeitung Ihrer
    personenbezogenen Daten zu verlangen. Des Weiteren steht Ihnen ein
    Beschwerderecht bei der zuständigen Aufsichtsbehörde zu.
  </p>
  <p>
    Hierzu sowie zu weiteren Fragen zum Thema Datenschutz können Sie
    sich jederzeit unter der im Impressum angegebenen Adresse an uns
    wenden.
  </p>
  <h3>Analyse-Tools und Tools von Drittanbietern</h3>
  <p>
    Beim Besuch dieser Website kann Ihr Surf-Verhalten statistisch
    ausgewertet werden. Das geschieht vor allem mit Cookies und mit
    sogenannten Analyseprogrammen.
  </p>
  <p>
    Detaillierte Informationen zu diesen Analyseprogrammen finden Sie in
    der folgenden Datenschutzerklärung.
  </p>
  <h2>2. Hosting und Content Delivery Networks (CDN)</h2>
  <h3>Externes Hosting</h3>
  <p>
    Diese Website wird bei einem externen Dienstleister gehostet
    (Hoster). Die personenbezogenen Daten, die auf dieser Website
    erfasst werden, werden auf den Servern des Hosters gespeichert.
    Hierbei kann es sich v. a. um IP-Adressen, Kontaktanfragen, Meta-
    und Kommunikationsdaten, Vertragsdaten, Kontaktdaten, Namen,
    Webseitenzugriffe und sonstige Daten, die über eine Website
    generiert werden, handeln.
  </p>
  <p>
    Der Einsatz des Hosters erfolgt zum Zwecke der Vertragserfüllung
    gegenüber unseren potenziellen und bestehenden Kunden (Art. 6 Abs. 1
    lit. b DSGVO) und im Interesse einer sicheren, schnellen und
    effizienten Bereitstellung unseres Online-Angebots durch einen
    professionellen Anbieter (Art. 6 Abs. 1 lit. f DSGVO).
  </p>
  <p>
    Unser Hoster wird Ihre Daten nur insoweit verarbeiten, wie dies zur
    Erfüllung seiner Leistungspflichten erforderlich ist und unsere
    Weisungen in Bezug auf diese Daten befolgen.
  </p>
  <p>
    <strong>Abschluss eines Vertrages über Auftragsverarbeitung</strong>
  </p>
  <p>
    Um die datenschutzkonforme Verarbeitung zu gewährleisten, haben wir
    einen Vertrag über Auftragsverarbeitung mit unserem Hoster
    geschlossen.
  </p>
  <h2>3. Allgemeine Hinweise und Pflichtinformationen</h2>
  <h3>Datenschutz</h3>
  <p>
    Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen
    Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten
    vertraulich und entsprechend der gesetzlichen
    Datenschutzvorschriften sowie dieser Datenschutzerklärung.
  </p>
  <p>
    Wenn Sie diese Website benutzen, werden verschiedene
    personenbezogene Daten erhoben. Personenbezogene Daten sind Daten,
    mit denen Sie persönlich identifiziert werden können. Die
    vorliegende Datenschutzerklärung erläutert, welche Daten wir erheben
    und wofür wir sie nutzen. Sie erläutert auch, wie und zu welchem
    Zweck das geschieht.
  </p>
  <p>
    Wir weisen darauf hin, dass die Datenübertragung im Internet
    (z.&nbsp;B. bei der Kommunikation per E-Mail) Sicherheitslücken
    aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff
    durch Dritte ist nicht möglich.
  </p>
  <h3>Hinweis zur verantwortlichen Stelle</h3>
  <p>
    Die verantwortliche Stelle für die Datenverarbeitung auf dieser
    Website ist:
  </p>
  <p>
    VHW UG ( haftungsbeschränkt )<br />
    Kelterstraße 2<br />
    75179 Pforzheim
  </p>
  <p>
    Telefon: 07202 7090592<br />
    E-Mail: info@volkshandwerker.de
  <p>
    Name und Sitz des Versicherers:<br>
    Allianz Versicherung<br>
    Königinstraße 28<br>
    80802 München
  </p>
  <p>
    Verantwortliche Stelle ist die natürliche oder juristische Person,
    die allein oder gemeinsam mit anderen über die Zwecke und Mittel der
    Verarbeitung von personenbezogenen Daten (z.&nbsp;B. Namen,
    E-Mail-Adressen o. Ä.) entscheidet.
  </p>
  <h3>Widerruf Ihrer Einwilligung zur Datenverarbeitung</h3>
  <p>
    Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen
    Einwilligung möglich. Sie können eine bereits erteilte Einwilligung
    jederzeit widerrufen. Dazu reicht eine formlose Mitteilung per
    E-Mail an uns. Die Rechtmäßigkeit der bis zum Widerruf erfolgten
    Datenverarbeitung bleibt vom Widerruf unberührt.
  </p>
  <h3>
    Widerspruchsrecht gegen die Datenerhebung in besonderen Fällen sowie
    gegen Direktwerbung (Art. 21 DSGVO)
  </h3>
  <p>
    WENN DIE DATENVERARBEITUNG AUF GRUNDLAGE VON ART. 6 ABS. 1 LIT. E
    ODER F DSGVO ERFOLGT, HABEN SIE JEDERZEIT DAS RECHT, AUS GRÜNDEN,
    DIE SICH AUS IHRER BESONDEREN SITUATION ERGEBEN, GEGEN DIE
    VERARBEITUNG IHRER PERSONENBEZOGENEN DATEN WIDERSPRUCH EINZULEGEN;
    DIES GILT AUCH FÜR EIN AUF DIESE BESTIMMUNGEN GESTÜTZTES PROFILING.
    DIE JEWEILIGE RECHTSGRUNDLAGE, AUF DENEN EINE VERARBEITUNG BERUHT,
    ENTNEHMEN SIE DIESER DATENSCHUTZERKLÄRUNG. WENN SIE WIDERSPRUCH
    EINLEGEN, WERDEN WIR IHRE BETROFFENEN PERSONENBEZOGENEN DATEN NICHT
    MEHR VERARBEITEN, ES SEI DENN, WIR KÖNNEN ZWINGENDE SCHUTZWÜRDIGE
    GRÜNDE FÜR DIE VERARBEITUNG NACHWEISEN, DIE IHRE INTERESSEN, RECHTE
    UND FREIHEITEN ÜBERWIEGEN ODER DIE VERARBEITUNG DIENT DER
    GELTENDMACHUNG, AUSÜBUNG ODER VERTEIDIGUNG VON RECHTSANSPRÜCHEN
    (WIDERSPRUCH NACH ART. 21 ABS. 1 DSGVO).
  </p>
  <p>
    WERDEN IHRE PERSONENBEZOGENEN DATEN VERARBEITET, UM DIREKTWERBUNG ZU
    BETREIBEN, SO HABEN SIE DAS RECHT, JEDERZEIT WIDERSPRUCH GEGEN DIE
    VERARBEITUNG SIE BETREFFENDER PERSONENBEZOGENER DATEN ZUM ZWECKE
    DERARTIGER WERBUNG EINZULEGEN; DIES GILT AUCH FÜR DAS PROFILING,
    SOWEIT ES MIT SOLCHER DIREKTWERBUNG IN VERBINDUNG STEHT. WENN SIE
    WIDERSPRECHEN, WERDEN IHRE PERSONENBEZOGENEN DATEN ANSCHLIESSEND
    NICHT MEHR ZUM ZWECKE DER DIREKTWERBUNG VERWENDET (WIDERSPRUCH NACH
    ART. 21 ABS. 2 DSGVO).
  </p>
  <h3>Beschwerderecht bei der zuständigen Aufsichtsbehörde</h3>
  <p>
    Im Falle von Verstößen gegen die DSGVO steht den Betroffenen ein
    Beschwerderecht bei einer Aufsichtsbehörde, insbesondere in dem
    Mitgliedstaat ihres gewöhnlichen Aufenthalts, ihres Arbeitsplatzes
    oder des Orts des mutmaßlichen Verstoßes zu. Das Beschwerderecht
    besteht unbeschadet anderweitiger verwaltungsrechtlicher oder
    gerichtlicher Rechtsbehelfe.
  </p>
  <h3>Recht auf Datenübertragbarkeit</h3>
  <p>
    Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung
    oder in Erfüllung eines Vertrags automatisiert verarbeiten, an sich
    oder an einen Dritten in einem gängigen, maschinenlesbaren Format
    aushändigen zu lassen. Sofern Sie die direkte Übertragung der Daten
    an einen anderen Verantwortlichen verlangen, erfolgt dies nur,
    soweit es technisch machbar ist.
  </p>
  <h3>SSL- bzw. TLS-Verschlüsselung</h3>
  <p>
    Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der
    Übertragung vertraulicher Inhalte, wie zum Beispiel Bestellungen
    oder Anfragen, die Sie an uns als Seitenbetreiber senden, eine SSL-
    bzw. TLS-Verschlüsselung. Eine verschlüsselte Verbindung erkennen
    Sie daran, dass die Adresszeile des Browsers von „http://“ auf
    „https://“ wechselt und an dem Schloss-Symbol in Ihrer Browserzeile.
  </p>
  <p>
    Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die
    Daten, die Sie an uns übermitteln, nicht von Dritten mitgelesen
    werden.
  </p>
  <h3>Auskunft, Löschung und Berichtigung</h3>
  <p>
    Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen
    jederzeit das Recht auf unentgeltliche Auskunft über Ihre
    gespeicherten personenbezogenen Daten, deren Herkunft und Empfänger
    und den Zweck der Datenverarbeitung und ggf. ein Recht auf
    Berichtigung oder Löschung dieser Daten. Hierzu sowie zu weiteren
    Fragen zum Thema personenbezogene Daten können Sie sich jederzeit
    unter der im Impressum angegebenen Adresse an uns wenden.
  </p>
  <h3>Recht auf Einschränkung der Verarbeitung</h3>
  <p>
    Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer
    personenbezogenen Daten zu verlangen. Hierzu können Sie sich
    jederzeit unter der im Impressum angegebenen Adresse an uns wenden.
    Das Recht auf Einschränkung der Verarbeitung besteht in folgenden
    Fällen:
  </p>
  <ul>
    <li>
      Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten
      personenbezogenen Daten bestreiten, benötigen wir in der Regel
      Zeit, um dies zu überprüfen. Für die Dauer der Prüfung haben Sie
      das Recht, die Einschränkung der Verarbeitung Ihrer
      personenbezogenen Daten zu verlangen.
    </li>
    <li>
      Wenn die Verarbeitung Ihrer personenbezogenen Daten unrechtmäßig
      geschah/geschieht, können Sie statt der Löschung die Einschränkung
      der Datenverarbeitung verlangen.
    </li>
    <li>
      Wenn wir Ihre personenbezogenen Daten nicht mehr benötigen, Sie
      sie jedoch zur Ausübung, Verteidigung oder Geltendmachung von
      Rechtsansprüchen benötigen, haben Sie das Recht, statt der
      Löschung die Einschränkung der Verarbeitung Ihrer
      personenbezogenen Daten zu verlangen.
    </li>
    <li>
      Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt
      haben, muss eine Abwägung zwischen Ihren und unseren Interessen
      vorgenommen werden. Solange noch nicht feststeht, wessen
      Interessen überwiegen, haben Sie das Recht, die Einschränkung der
      Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
    </li>
  </ul>
  <p>
    Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten
    eingeschränkt haben, dürfen diese Daten – von ihrer Speicherung
    abgesehen – nur mit Ihrer Einwilligung oder zur Geltendmachung,
    Ausübung oder Verteidigung von Rechtsansprüchen oder zum Schutz der
    Rechte einer anderen natürlichen oder juristischen Person oder aus
    Gründen eines wichtigen öffentlichen Interesses der Europäischen
    Union oder eines Mitgliedstaats verarbeitet werden.
  </p>
  <h3>Widerspruch gegen Werbe-E-Mails</h3>
  <p>
    Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten
    Kontaktdaten zur Übersendung von nicht ausdrücklich angeforderter
    Werbung und Informationsmaterialien wird hiermit widersprochen. Die
    Betreiber der Seiten behalten sich ausdrücklich rechtliche Schritte
    im Falle der unverlangten Zusendung von Werbeinformationen, etwa
    durch Spam-E-Mails, vor.
  </p>
  <h2>4. Datenerfassung auf dieser Website</h2>
  <h3>Cookies</h3>
  <p>
    Unsere Internetseiten verwenden so genannte „Cookies“. Cookies sind
    kleine Textdateien und richten auf Ihrem Endgerät keinen Schaden an.
    Sie werden entweder vorübergehend für die Dauer einer Sitzung
    (Session-Cookies) oder dauerhaft (permanente Cookies) auf Ihrem
    Endgerät gespeichert. Session-Cookies werden nach Ende Ihres Besuchs
    automatisch gelöscht. Permanente Cookies bleiben auf Ihrem Endgerät
    gespeichert, bis Sie diese selbst löschen&nbsp;oder eine
    automatische Löschung durch Ihren Webbrowser erfolgt.
  </p>
  <p>
    Teilweise können auch Cookies von Drittunternehmen auf Ihrem
    Endgerät gespeichert werden, wenn Sie unsere Seite betreten
    (Third-Party-Cookies). Diese ermöglichen uns oder Ihnen die Nutzung
    bestimmter Dienstleistungen des Drittunternehmens (z.B. Cookies zur
    Abwicklung von Zahlungsdienstleistungen).
  </p>
  <p>
    Cookies haben verschiedene Funktionen. Zahlreiche Cookies sind
    technisch notwendig, da bestimmte Webseitenfunktionen ohne diese
    nicht funktionieren würden (z.B. die Warenkorbfunktion oder die
    Anzeige von Videos). Andere Cookies dienen dazu, das Nutzerverhalten
    auszuwerten&nbsp;oder Werbung anzuzeigen.
  </p>
  <p>
    Cookies, die zur Durchführung des elektronischen
    Kommunikationsvorgangs (notwendige Cookies) oder zur Bereitstellung
    bestimmter, von Ihnen erwünschter Funktionen (funktionale Cookies,
    z. B. für die Warenkorbfunktion) oder zur Optimierung der Webseite
    (z.B. Cookies zur Messung des Webpublikums) erforderlich sind,
    werden auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO gespeichert,
    sofern keine andere Rechtsgrundlage angegeben wird. Der
    Websitebetreiber hat ein berechtigtes Interesse an der Speicherung
    von Cookies zur technisch fehlerfreien und optimierten
    Bereitstellung seiner Dienste. Sofern eine Einwilligung zur
    Speicherung von Cookies abgefragt wurde, erfolgt die Speicherung der
    betreffenden Cookies ausschließlich auf Grundlage dieser
    Einwilligung (Art. 6 Abs. 1 lit. a DSGVO); die Einwilligung ist
    jederzeit widerrufbar.
  </p>
  <p>
    Sie können Ihren Browser so einstellen, dass Sie über das Setzen von
    Cookies informiert werden und Cookies nur im Einzelfall erlauben,
    die Annahme von Cookies für bestimmte Fälle oder generell
    ausschließen sowie das automatische Löschen der Cookies beim
    Schließen des Browsers aktivieren. Bei der Deaktivierung von Cookies
    kann die Funktionalität dieser Website eingeschränkt sein.
  </p>
  <p>
    Soweit Cookies von Drittunternehmen oder zu Analysezwecken
    eingesetzt werden, werden wir Sie hierüber im Rahmen dieser
    Datenschutzerklärung gesondert informieren und ggf. eine
    Einwilligung abfragen.
  </p>
  <h3>Cookie-Einwilligung mit Borlabs Cookie</h3>
  <p>
    Unsere Website nutzt die Cookie-Consent-Technologie von Borlabs
    Cookie, um Ihre Einwilligung zur Speicherung bestimmter Cookies in
    Ihrem Browser einzuholen und diese datenschutzkonform zu
    dokumentieren. Anbieter dieser Technologie ist Borlabs &#8211;
    Benjamin A. Bornschein, Georg-Wilhelm-Str. 17, 21107 Hamburg (im
    Folgenden Borlabs).
  </p>
  <p>
    Wenn Sie unsere Website betreten, wird ein Borlabs-Cookie in Ihrem
    Browser gespeichert, in dem die von Ihnen erteilten Einwilligungen
    oder der Widerruf dieser Einwilligungen gespeichert werden. Diese
    Daten werden nicht an den Anbieter von Borlabs Cookie weitergegeben.
  </p>
  <p>
    Die erfassten Daten werden gespeichert, bis Sie uns zur Löschung
    auffordern bzw. das Borlabs-Cookie selbst löschen oder der Zweck für
    die Datenspeicherung entfällt. Zwingende gesetzliche
    Aufbewahrungsfristen bleiben unberührt. Details zur
    Datenverarbeitung von Borlabs Cookie finden Sie unter
    <a href="https://de.borlabs.io/kb/welche-daten-speichert-borlabs-cookie/" target="_blank"
      rel="noopener noreferrer">https://de.borlabs.io/kb/welche-daten-speichert-borlabs-cookie/</a>
  </p>
  <p>
    Der Einsatz der Borlabs-Cookie-Consent-Technologie erfolgt, um die
    gesetzlich vorgeschriebenen Einwilligungen für den Einsatz von
    Cookies einzuholen. Rechtsgrundlage hierfür ist Art. 6 Abs. 1 S. 1
    lit. c DSGVO.
  </p>
  <h3>Server-Log-Dateien</h3>
  <p>
    Der Provider der Seiten erhebt und speichert automatisch
    Informationen in so genannten Server-Log-Dateien, die Ihr Browser
    automatisch an uns übermittelt. Dies sind:
  </p>
  <ul>
    <li>Browsertyp und Browserversion</li>
    <li>verwendetes Betriebssystem</li>
    <li>Referrer URL</li>
    <li>Hostname des zugreifenden Rechners</li>
    <li>Uhrzeit der Serveranfrage</li>
    <li>IP-Adresse</li>
  </ul>
  <p>
    Eine Zusammenführung dieser Daten mit anderen Datenquellen wird
    nicht vorgenommen.
  </p>
  <p>
    Die Erfassung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1
    lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse an
    der technisch fehlerfreien Darstellung und der Optimierung seiner
    Website – hierzu müssen die Server-Log-Files erfasst werden.
  </p>
  <h3>Kontaktformular</h3>
  <p>
    Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden
    Ihre Angaben aus dem Anfrageformular inklusive der von Ihnen dort
    angegebenen Kontaktdaten zwecks Bearbeitung der Anfrage und für den
    Fall von Anschlussfragen bei uns gespeichert. Diese Daten geben wir
    nicht ohne Ihre Einwilligung weiter.
  </p>
  <p>
    Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs.
    1 lit. b DSGVO, sofern Ihre Anfrage mit der Erfüllung eines Vertrags
    zusammenhängt oder zur Durchführung vorvertraglicher Maßnahmen
    erforderlich ist. In allen übrigen Fällen beruht die Verarbeitung
    auf unserem berechtigten Interesse an der effektiven Bearbeitung der
    an uns gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf
    Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern diese
    abgefragt wurde.
  </p>
  <p>
    Die von Ihnen im Kontaktformular eingegebenen Daten verbleiben bei
    uns, bis Sie uns zur Löschung auffordern, Ihre Einwilligung zur
    Speicherung widerrufen oder der Zweck für die Datenspeicherung
    entfällt (z.&nbsp;B. nach abgeschlossener Bearbeitung Ihrer
    Anfrage). Zwingende gesetzliche Bestimmungen – insbesondere
    Aufbewahrungsfristen – bleiben unberührt.
  </p>
  <h3>Anfrage per E-Mail, Telefon oder Telefax</h3>
  <p>
    Wenn Sie uns per E-Mail, Telefon oder Telefax kontaktieren, wird
    Ihre Anfrage inklusive aller daraus hervorgehenden personenbezogenen
    Daten (Name, Anfrage) zum Zwecke der Bearbeitung Ihres Anliegens bei
    uns gespeichert und verarbeitet. Diese Daten geben wir nicht ohne
    Ihre Einwilligung weiter.
  </p>
  <p>
    Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs.
    1 lit. b DSGVO, sofern Ihre Anfrage mit der Erfüllung eines Vertrags
    zusammenhängt oder zur Durchführung vorvertraglicher Maßnahmen
    erforderlich ist. In allen übrigen Fällen beruht die Verarbeitung
    auf unserem berechtigten Interesse an der effektiven Bearbeitung der
    an uns gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf
    Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern diese
    abgefragt wurde.
  </p>
  <p>
    Die von Ihnen an uns per Kontaktanfragen übersandten Daten
    verbleiben bei uns, bis Sie uns zur Löschung auffordern, Ihre
    Einwilligung zur Speicherung widerrufen oder der Zweck für die
    Datenspeicherung entfällt (z.&nbsp;B. nach abgeschlossener
    Bearbeitung Ihres Anliegens). Zwingende gesetzliche Bestimmungen –
    insbesondere gesetzliche Aufbewahrungsfristen – bleiben unberührt.
  </p>
  <h3>Registrierung auf dieser Website</h3>
  <p>
    Sie können sich auf dieser Website registrieren, um zusätzliche
    Funktionen auf der Seite zu nutzen. Die dazu eingegebenen Daten
    verwenden wir nur zum Zwecke der Nutzung des jeweiligen Angebotes
    oder Dienstes, für den Sie sich registriert haben. Die bei der
    Registrierung abgefragten Pflichtangaben müssen vollständig
    angegeben werden. Anderenfalls werden wir die Registrierung
    ablehnen.
  </p>
  <p>
    Für wichtige Änderungen etwa beim Angebotsumfang oder bei technisch
    notwendigen Änderungen nutzen wir die bei der Registrierung
    angegebene E-Mail-Adresse, um Sie auf diesem Wege zu informieren.
  </p>
  <p>
    Die Verarbeitung der bei der Registrierung eingegebenen Daten
    erfolgt zum Zwecke der Durchführung des durch die Registrierung
    begründeten Nutzungsverhältnisses und ggf. zur Anbahnung weiterer
    Verträge (Art. 6 Abs. 1 lit. b DSGVO).
  </p>
  <p>
    Die bei der Registrierung erfassten Daten werden von uns
    gespeichert, solange Sie auf dieser Website registriert sind und
    werden anschließend gelöscht. Gesetzliche Aufbewahrungsfristen
    bleiben unberührt.
  </p>
  <h3>Registrierung mit Facebook Connect</h3>
  <p>
    Statt einer direkten Registrierung auf dieser Website können Sie
    sich mit Facebook Connect registrieren. Anbieter dieses Dienstes ist
    die Facebook Ireland Limited, 4 Grand Canal Square, Dublin 2,
    Irland. Die erfassten Daten werden nach Aussage von Facebook jedoch
    auch in die USA und in andere Drittländer übertragen.
  </p>
  <p>
    Wenn Sie sich für die Registrierung mit Facebook Connect entscheiden
    und auf den „Login with Facebook”-/„Connect with Facebook”-Button
    klicken, werden Sie automatisch auf die Plattform von Facebook
    weitergeleitet. Dort können Sie sich mit Ihren Nutzungsdaten
    anmelden. Dadurch wird Ihr Facebook-Profil mit dieser Website bzw.
    unseren Diensten verknüpft. Durch diese Verknüpfung erhalten wir
    Zugriff auf Ihre bei Facebook hinterlegten Daten. Dies sind vor
    allem:
  </p>
  <ul>
    <li>Facebook-Name</li>
    <li>Facebook-Profil- und Titelbild</li>
    <li>Facebook-Titelbild</li>
    <li>bei Facebook hinterlegte E-Mail-Adresse</li>
    <li>Facebook-ID</li>
    <li>Facebook-Freundeslisten</li>
    <li>Facebook Likes („Gefällt-mir“-Angaben)</li>
    <li>Geburtstag</li>
    <li>Geschlecht</li>
    <li>Land</li>
    <li>Sprache</li>
  </ul>
  <p>
    Diese Daten werden zur Einrichtung, Bereitstellung und
    Personalisierung Ihres Accounts genutzt.
  </p>
  <p>
    Die Registrierung mit Facebook-Connect und die damit verbundenen
    Datenverarbeitungsvorgänge erfolgen auf Grundlage Ihrer Einwilligung
    (Art. 6 Abs. 1 lit. a DSGVO). Diese Einwilligung können Sie
    jederzeit mit Wirkung für die Zukunft widerrufen.
  </p>
  <p>
    Weitere Informationen finden Sie in den Facebook-Nutzungsbedingungen
    und den Facebook-Datenschutzbestimmungen. Diese finden Sie unter:
    <a href="https://de-de.facebook.com/about/privacy/" target="_blank"
      rel="noopener noreferrer">https://de-de.facebook.com/about/privacy/</a>
    und
    <a href="https://de-de.facebook.com/legal/terms/" target="_blank"
      rel="noopener noreferrer">https://de-de.facebook.com/legal/terms/</a>.
  </p>
  <h2>5. Soziale Medien</h2>
  <h3>Facebook Plugins (Like &amp; Share-Button)</h3>
  <p>
    Auf dieser Website sind Plugins des sozialen Netzwerks Facebook
    integriert. Anbieter dieses Dienstes ist die Facebook Ireland
    Limited, 4 Grand Canal Square, Dublin 2, Irland. Die erfassten Daten
    werden nach Aussage von Facebook jedoch auch in die USA und in
    andere Drittländer übertragen.
  </p>
  <p>
    Die Facebook Plugins erkennen Sie an dem Facebook-Logo oder dem
    „Like-Button“ („Gefällt mir“) auf dieser Website. Eine Übersicht
    über die Facebook Plugins finden Sie hier:
    <a href="https://developers.facebook.com/docs/plugins/?locale=de_DE" target="_blank"
      rel="noopener noreferrer">https://developers.facebook.com/docs/plugins/?locale=de_DE</a>.
  </p>
  <p>
    Wenn Sie diese Website besuchen, wird über das Plugin eine direkte
    Verbindung zwischen Ihrem Browser und dem Facebook-Server
    hergestellt. Facebook erhält dadurch die Information, dass Sie mit
    Ihrer IP-Adresse diese Website besucht haben. Wenn Sie den Facebook
    „Like-Button“ anklicken während Sie in Ihrem Facebook-Account
    eingeloggt sind, können Sie die Inhalte dieser Website auf Ihrem
    Facebook-Profil verlinken. Dadurch kann Facebook den Besuch dieser
    Website Ihrem Benutzerkonto zuordnen. Wir weisen darauf hin, dass
    wir als Anbieter der Seiten keine Kenntnis vom Inhalt der
    übermittelten Daten sowie deren Nutzung durch Facebook erhalten.
    Weitere Informationen hierzu finden Sie in der Datenschutzerklärung
    von Facebook unter:
    <a href="https://de-de.facebook.com/privacy/explanation" target="_blank"
      rel="noopener noreferrer">https://de-de.facebook.com/privacy/explanation</a>.
  </p>
  <p>
    Wenn Sie nicht wünschen, dass Facebook den Besuch dieser Website
    Ihrem Facebook-Nutzerkonto zuordnen kann, loggen Sie sich bitte aus
    Ihrem Facebook-Benutzerkonto aus.
  </p>
  <p>
    Die Verwendung der Facebook Plugins erfolgt auf Grundlage von Art. 6
    Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes
    Interesse an einer möglichst umfangreichen Sichtbarkeit in den
    Sozialen Medien. Sofern eine entsprechende Einwilligung abgefragt
    wurde, erfolgt die Verarbeitung ausschließlich auf Grundlage von
    Art. 6 Abs. 1 lit. a DSGVO; die Einwilligung ist jederzeit
    widerrufbar.
  </p>
  <h3>Instagram Plugin</h3>
  <p>
    Auf dieser Website sind Funktionen des Dienstes Instagram
    eingebunden. Diese Funktionen werden angeboten durch die Instagram
    Inc., 1601 Willow Road, Menlo Park, CA 94025, USA integriert.
  </p>
  <p>
    Wenn Sie in Ihrem Instagram-Account eingeloggt sind, können Sie
    durch Anklicken des Instagram-Buttons die Inhalte dieser Website mit
    Ihrem Instagram-Profil verlinken. Dadurch kann Instagram den Besuch
    dieser Website Ihrem Benutzerkonto zuordnen. Wir weisen darauf hin,
    dass wir als Anbieter der Seiten keine Kenntnis vom Inhalt der
    übermittelten Daten sowie deren Nutzung durch Instagram erhalten.
  </p>
  <p>
    Die Speicherung und Analyse der Daten erfolgt auf Grundlage von Art.
    6 Abs. 1 lit. f DSGVO. Der Webseitenbetreiber hat ein berechtigtes
    Interesse an einer möglichst umfangreichen Sichtbarkeit in den
    Sozialen Medien. Sofern eine entsprechende Einwilligung abgefragt
    wurde, erfolgt die Verarbeitung ausschließlich auf Grundlage von
    Art. 6 Abs. 1 lit. a DSGVO; die Einwilligung ist jederzeit
    widerrufbar.
  </p>
  <p>
    Weitere Informationen hierzu finden Sie in der Datenschutzerklärung
    von Instagram:
    <a href="https://instagram.com/about/legal/privacy/" target="_blank"
      rel="noopener noreferrer">https://instagram.com/about/legal/privacy/</a>.
  </p>
  <h2>6. Analyse-Tools und Werbung</h2>
  <h3>Google Analytics</h3>
  <p>
    Diese Website nutzt Funktionen des Webanalysedienstes Google
    Analytics. Anbieter ist die Google Ireland Limited („Google“),
    Gordon House, Barrow Street, Dublin 4, Irland.
  </p>
  <p>
    Google Analytics verwendet so genannte „Cookies“. Das sind
    Textdateien, die auf Ihrem Computer gespeichert werden und die eine
    Analyse der Benutzung der Website durch Sie ermöglichen. Die durch
    den Cookie erzeugten Informationen über Ihre Benutzung dieser
    Website werden in der Regel an einen Server von Google in den USA
    übertragen und dort gespeichert.
  </p>
  <p>
    Die Speicherung von Google-Analytics-Cookies und die Nutzung dieses
    Analyse-Tools erfolgen auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO.
    Der Websitebetreiber hat ein berechtigtes Interesse an der Analyse
    des Nutzerverhaltens, um sowohl sein Webangebot als auch seine
    Werbung zu optimieren. Sofern eine entsprechende Einwilligung
    abgefragt wurde (z. B. eine Einwilligung zur Speicherung von
    Cookies), erfolgt die Verarbeitung ausschließlich auf Grundlage von
    Art. 6 Abs. 1 lit. a DSGVO; die Einwilligung ist jederzeit
    widerrufbar.
  </p>
  <p><strong>IP Anonymisierung</strong></p>
  <p>
    Wir haben auf dieser Website die Funktion IP-Anonymisierung
    aktiviert. Dadurch wird Ihre IP-Adresse von Google innerhalb von
    Mitgliedstaaten der Europäischen Union oder in anderen
    Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum
    vor der Übermittlung in die USA gekürzt. Nur in Ausnahmefällen wird
    die volle IP-Adresse an einen Server von Google in den USA
    übertragen und dort gekürzt. Im Auftrag des Betreibers dieser
    Website wird Google diese Informationen benutzen, um Ihre Nutzung
    der Website auszuwerten, um Reports über die Websiteaktivitäten
    zusammenzustellen und um weitere mit der Websitenutzung und der
    Internetnutzung verbundene Dienstleistungen gegenüber dem
    Websitebetreiber zu erbringen. Die im Rahmen von Google Analytics
    von Ihrem Browser übermittelte IP-Adresse wird nicht mit anderen
    Daten von Google zusammengeführt.
  </p>
  <p><strong>Browser Plugin</strong></p>
  <p>
    Sie können die Speicherung der Cookies durch eine entsprechende
    Einstellung Ihrer Browser-Software verhindern; wir weisen Sie jedoch
    darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche
    Funktionen dieser Website vollumfänglich werden nutzen können. Sie
    können darüber hinaus die Erfassung der durch den Cookie erzeugten
    und auf Ihre Nutzung der Website bezogenen Daten (inkl. Ihrer
    IP-Adresse) an Google sowie die Verarbeitung dieser Daten durch
    Google verhindern, indem Sie das unter dem folgenden Link verfügbare
    Browser-Plugin herunterladen und installieren:
    <a href="https://tools.google.com/dlpage/gaoptout?hl=de" target="_blank"
      rel="noopener noreferrer">https://tools.google.com/dlpage/gaoptout?hl=de</a>.
  </p>
  <p><strong>Widerspruch gegen Datenerfassung</strong></p>
  <p>
    Sie können die Erfassung Ihrer Daten durch Google Analytics
    verhindern, indem Sie auf folgenden Link klicken. Es wird ein
    Opt-Out-Cookie gesetzt, der die Erfassung Ihrer Daten bei
    zukünftigen Besuchen dieser Website verhindert:
    <a href="javascript:gaOptout();">Google Analytics deaktivieren</a>.
  </p>
  <p>
    Mehr Informationen zum Umgang mit Nutzerdaten bei Google Analytics
    finden Sie in der Datenschutzerklärung von Google:
    <a href="https://support.google.com/analytics/answer/6004245?hl=de" target="_blank"
      rel="noopener noreferrer">https://support.google.com/analytics/answer/6004245?hl=de</a>.
  </p>
  <p><strong>Auftragsverarbeitung</strong></p>
  <p>
    Wir haben mit Google einen Vertrag zur Auftragsverarbeitung
    abgeschlossen und setzen die strengen Vorgaben der deutschen
    Datenschutzbehörden bei der Nutzung von Google Analytics vollständig
    um.
  </p>
  <p><strong>Speicherdauer</strong></p>
  <p>
    Bei Google gespeicherte Daten auf Nutzer- und Ereignisebene, die mit
    Cookies, Nutzerkennungen (z.&nbsp;B. User ID) oder Werbe-IDs
    (z.&nbsp;B. DoubleClick-Cookies, Android-Werbe-ID) verknüpft sind,
    werden nach 14 Monaten anonymisiert bzw. gelöscht. Details hierzu
    ersehen Sie unter folgendem Link:
    <a href="https://support.google.com/analytics/answer/7667196?hl=de" target="_blank"
      rel="noopener noreferrer">https://support.google.com/analytics/answer/7667196?hl=de</a>
  </p>
  <h2>7. Plugins und Tools</h2>
  <h3>Google Web Fonts</h3>
  <p>
    Diese Seite nutzt zur einheitlichen Darstellung von Schriftarten so
    genannte Web Fonts, die von Google bereitgestellt werden. Beim
    Aufruf einer Seite lädt Ihr Browser die benötigten Web Fonts in
    ihren Browsercache, um Texte und Schriftarten korrekt anzuzeigen.
  </p>
  <p>
    Zu diesem Zweck muss der von Ihnen verwendete Browser Verbindung zu
    den Servern von Google aufnehmen. Hierdurch erlangt Google Kenntnis
    darüber, dass über Ihre IP-Adresse diese Website aufgerufen wurde.
    Die Nutzung von Google WebFonts erfolgt auf Grundlage von Art. 6
    Abs. 1 lit. f DSGVO. Der Webseitenbetreiber hat ein berechtigtes
    Interesse an der einheitlichen Darstellung des Schriftbildes auf
    seiner Webseite. Sofern eine entsprechende Einwilligung abgefragt
    wurde (z. B. eine Einwilligung zur Speicherung von Cookies), erfolgt
    die Verarbeitung ausschließlich auf Grundlage von Art. 6 Abs. 1 lit.
    a DSGVO; die Einwilligung ist jederzeit widerrufbar.
  </p>
  <p>
    Wenn Ihr Browser Web Fonts nicht unterstützt, wird eine
    Standardschrift von Ihrem Computer genutzt.
  </p>
  <p>
    Weitere Informationen zu Google Web Fonts finden Sie unter
    <a href="https://developers.google.com/fonts/faq" target="_blank"
      rel="noopener noreferrer">https://developers.google.com/fonts/faq</a>
    und in der Datenschutzerklärung von Google:
    <a href="https://policies.google.com/privacy?hl=de" target="_blank"
      rel="noopener noreferrer">https://policies.google.com/privacy?hl=de</a>.
  </p>
  <h2>8. eCommerce und Zahlungsanbieter</h2>
  <h3>Verarbeiten von Daten (Kunden- und Vertragsdaten)</h3>
  <p>
    Wir erheben, verarbeiten und nutzen personenbezogene Daten nur,
    soweit sie für die Begründung, inhaltliche Ausgestaltung oder
    Änderung des Rechtsverhältnisses erforderlich sind (Bestandsdaten).
    Dies erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO, der die
    Verarbeitung von Daten zur Erfüllung eines Vertrags oder
    vorvertraglicher Maßnahmen gestattet. Personenbezogene Daten über
    die Inanspruchnahme dieser Website (Nutzungsdaten) erheben,
    verarbeiten und nutzen wir nur, soweit dies erforderlich ist, um dem
    Nutzer die Inanspruchnahme des Dienstes zu ermöglichen oder
    abzurechnen.
  </p>
  <p>
    Die erhobenen Kundendaten werden nach Abschluss des Auftrags oder
    Beendigung der Geschäftsbeziehung gelöscht. Gesetzliche
    Aufbewahrungsfristen bleiben unberührt.
  </p>
</section>