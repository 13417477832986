import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountService } from '../_services';

@Component({
  selector: 'volkshandwerker-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  passwordFocus;
  passwordConfirmFocus;

  public code = '';

  public resetPasswordForm: FormGroup;

  loading = false;
  submitted = false;

  constructor(private formBuilder: FormBuilder, private router: Router, private accountService: AccountService, private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.resetPasswordForm = this.formBuilder.group({
      password: ['', Validators.compose([Validators.required, Validators.min(6)])],
      passwordConfirm: ['', Validators.compose([Validators.required, Validators.min(6)])]
    });
    
    this.activatedRoute.queryParams.subscribe(params => {
      this.code = params['code'];
  });
  }

  onSubmit(password, passwordConfirm) {
    this.loading = true;
    this.submitted = true;

    if (password === passwordConfirm) {
      this.accountService.makePostRequest('/auth/reset-password', {
        code: this.code,
        password: password,
        passwordConfirmation: passwordConfirm,
      }).then(data => {
        this.router.navigate(['/register']);
      }, error => {
      });
    }
  }
}
