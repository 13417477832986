<!-- HERO -->

<section class="section-header bg-primary text-white pt-8 pb-9 pb-lg-11">
    <div class="container">

        <!-- CAPTION -->
        <div class="row justify-content-center mb-4">
            <div class="col-12 col-md-10 text-center">
                <h1 class="display-2 mb-3 text-white">Unser Preisplan einfach gemacht</h1>
            </div>
        </div>

        <!-- PACKS -->
        <div class="row text-darker">

            <!-- Card -->
            <div class="card shadow-soft mb-5 mb-lg-6 px-2 outer-form">
                <div class="row justify-content-center">

                    <div class="col-12 col-lg-{{12 / 1}}">

                        <div class="stepper-wrapper">
                            <div class="stepper-item" [ngClass]="step==1 ? 'active' : step>=2 && 'completed'">
                                <div class="step-counter">1</div>
                                <div class="step-name">Handwerksbetrieb<br>inserieren</div>
                            </div>
                            <div class="stepper-item" [ngClass]="step==2 ? 'active' : step>=2 && 'completed'">
                                <div class="step-counter">2</div>
                                <div class="step-name">Firmen<br>Information</div>
                            </div>
                            <div class="stepper-item" [ngClass]="step==3 ? 'active completed' : step>=3 && 'completed'">
                                <div class="step-counter">3</div>
                                <div class="step-name">Bestellung<br>überprüfen</div>
                            </div>
                        </div>
                        <hr style="width: 100%;">

                        <div class="inner-form">
                            <div *ngIf="step == 1">
                                <div class="cerceve">
                                    <b>Willkommen bei Volkshandwerker !</b>
                                    <br><br />
                                    <b>Wir freuen uns dass Sie sich für Volkshandwerker entschieden haben.</b>
                                    <br><br />
                                    <b>Um jetzt durchzustarten, wählen Sie bitte ein Paket aus.</b>

                                    <br><br />


                                    <div class="paket-text">
                                        <h3><b>Standard Paket</b></h3>
                                        <p>( Mındestlaufzeit 12 Monate, danach Monatlich kündbar )</p>
                                    </div>
                                    <ul type="disc">
                                        <li> 1 Logo als Profilbild</li>
                                        <li> Kontaktdaten (Anschrift + Telefonnummer)</li>
                                        <li> Beschreibung des Betriebes</li>
                                        <li> 7 Bilder</li>
                                        <li> 1 Filliale</li>
                                        <li> 12 monatige Laufzeit</li>
                                        <li> 1 monatige Kündigungsfrist</li>
                                    </ul>
                                </div>

                                <div class="kutu_fiyat_bilgisi"> 7,99 € <br>
                                    <span style="font-size:12px"> Pro Monat zzgl.
                                        MwSt.
                                    </span>
                                </div>
                                <div class="button-frame">
                                    <button class="kutu_paket_bilgisi_onay" (click)="next()">Auswählen</button>
                                </div>
                            </div>

                            <form [formGroup]="firmenForm" (ngSubmit)="onSubmit()" class="firmen-form" *ngIf="step==2">
                                <p class="required-text">*Pflichtfelder</p>
                                <div class="group-input">

                                    <div class="material-input">
                                        <div class="input-container material-input">
                                            <input type="text" formControlName="companyName"
                                                [ngClass]="{'is-invalid': submitted && firmenForm.controls.companyName.errors}"
                                                class="form-control" aria-labelledby="label-fname" />
                                            <label class="label">
                                                <div class="text">Firmenname*</div>
                                            </label>
                                        </div>
                                    </div>
                                    <div class="material-input">
                                        <div class="input-container material-input">
                                            <input type="text" formControlName="vatNumber"
                                                [ngClass]="{'is-invalid': submitted && firmenForm.controls.vatNumber.errors}"
                                                aria-labelledby="label-fname" />
                                            <label class="label">
                                                <div class="text">Steuer-ID*</div>
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div class="group-input">
                                    <div class="material-input">
                                        <div class="input-container material-input">
                                            <input type="text" formControlName="firstName"
                                                [ngClass]="{'is-invalid': submitted && firmenForm.controls.firstName.errors}"
                                                aria-labelledby="label-fname" />
                                            <label class="label">
                                                <div class="text">Vorname*</div>
                                            </label>
                                        </div>
                                    </div>
                                    <div class="material-input">
                                        <div class="input-container material-input">
                                            <input type="text" formControlName="lastName"
                                                [ngClass]="{'is-invalid': submitted && firmenForm.controls.lastName.errors}"
                                                aria-labelledby="label-fname" />
                                            <label class="label">
                                                <div class="text">Nachname*</div>
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div class="group-input">
                                    <div class="material-input">
                                        <div class="input-container material-input">
                                            <input type="text" formControlName="street"
                                                [ngClass]="{'is-invalid': submitted && firmenForm.controls.street.errors}"
                                                aria-labelledby="label-fname" />
                                            <label class="label">
                                                <div class="text">Straße*</div>
                                            </label>
                                        </div>
                                    </div>
                                    <div class="material-input">
                                        <div class="input-container material-input">
                                            <input type="text" formControlName="houseNumber"
                                                [ngClass]="{'is-invalid': submitted && firmenForm.controls.houseNumber.errors}"
                                                aria-labelledby="label-fname" />
                                            <label class="label">
                                                <div class="text">Hausnummer*</div>
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div class="group-input">
                                    <div class="material-input">
                                        <div class="input-container material-input">
                                            <input type="text" formControlName="postalCode"
                                                [ngClass]="{'is-invalid': submitted && firmenForm.controls.postalCode.errors}"
                                                aria-labelledby="label-fname" />
                                            <label class="label">
                                                <div class="text">PLZ*</div>
                                            </label>
                                        </div>
                                    </div>
                                    <div class="material-input">
                                        <div class="input-container material-input">
                                            <input type="text" formControlName="city"
                                                [ngClass]="{'is-invalid': submitted && firmenForm.controls.city.errors}"
                                                aria-labelledby="label-fname" />
                                            <label class="label">
                                                <div class="text">Stadt*</div>
                                            </label>
                                        </div>
                                    </div>

                                </div>

                                <div class="group-input">
                                    <div class="material-input">
                                        <div class="input-container material-input">
                                            <input type="text" formControlName="telephone"
                                                [ngClass]="{'is-invalid': submitted && firmenForm.controls.telephone.errors}"
                                                aria-labelledby="label-fname" />
                                            <label class="label">
                                                <div class="text">Telefonnummer*</div>
                                            </label>
                                        </div>
                                    </div>

                                    <div class="btn-group" dropdown>
                                        <button id="button-animated" dropdownToggle type="button"
                                            class="btn btn-primary dropdown-toggle"
                                            aria-controls="dropdown-animated">Geschlecht:
                                            {{firmenForm.get('genderText').value}}<span class="caret"></span>
                                        </button>
                                        <ul id="dropdown-animated" *dropdownMenu class="dropdown-menu" role="menu"
                                            aria-labelledby="button-animated">
                                            <li role="menuitem" (click)="selectGender('female')"><a
                                                    class="dropdown-item">Frau</a></li>
                                            <li role="menuitem" (click)="selectGender('male')"><a
                                                    class="dropdown-item">Herr</a>
                                            </li>
                                            <li role="menuitem" (click)="selectGender('divers')"><a
                                                    class="dropdown-item">Anrede</a>
                                            </li>
                                            <li role="menuitem" (click)="selectGender('notspecified')"><a
                                                    class="dropdown-item">Keine Angaben</a>
                                            </li>
                                        </ul>
                                    </div>

                                </div>

                                <div class="btn-group" dropdown>
                                    <button id="button-animated" dropdownToggle type="button"
                                        class="btn btn-primary dropdown-toggle" aria-controls="dropdown-animated">Land:
                                        {{firmenForm.get('country').value}}<span class="caret"></span>
                                    </button>
                                    <ul id="dropdown-animated" *dropdownMenu class="dropdown-menu" role="menu"
                                        aria-labelledby="button-animated">
                                        <li role="menuitem" (click)="selectCountry('de')"><a
                                                class="dropdown-item">Deutschland</a></li>
                                        <li role="menuitem" (click)="selectCountry('at')"><a
                                                class="dropdown-item">Österreich</a>
                                        </li>
                                        <li role="menuitem" (click)="selectCountry('ch')"><a
                                                class="dropdown-item">Schweiz</a>
                                        </li>
                                    </ul>
                                </div>





                                <div class="button-frame">
                                    <button class="button-9" [disabled]="isLoadingCompany">Weiter</button>
                                </div>
                            </form>

                            <div *ngIf="step == 3">
                                <div class="cerceve bestellung">

                                    <p style="text-decoration:underline;"> Meine Untemehmensdaten </p>
                                    <p style=""> {{firmenForm.get('companyName').value}}</p>
                                    <p style=""> {{firmenForm.get('firstName').value}}
                                        {{firmenForm.get('lastName').value}}</p>
                                    <p style=""> {{firmenForm.get('street').value}}
                                        {{firmenForm.get('houseNumber').value}}</p>
                                    <p style=""> {{firmenForm.get('postalCode').value}}
                                        {{firmenForm.get('city').value}} {{firmenForm.get('countryIso2').value}}</p>
                                    <p style="text-decoration:underline;"> E-Mail </p>
                                    <p style=""> {{email}} </p>
                                    <br>
                                    <p> Ich ermächtige die VHW UG haftungsbeschränkt wiederkehrende Zahlungen von meinem
                                        Konto mittels (
                                        Lastschrift oder Kreditkarte ) einzuziehen.</p>
                                    <p>
                                        Zugleich weise ich mein Kreditinstitut an, die von VHW UG haftungsbeschränkt auf
                                        mein Konto
                                        gezogenen Lastschriften einzulösen.
                                    </p>

                                    <br>
                                    <label class="container cflex">Newsletter & Tipps zur
                                        Nutzung von Volkshandwerker
                                        erhalten.
                                        <input type="checkbox" [(ngModel)]="cb1_checked">
                                        <span class="checkmark"></span>
                                    </label>
                                    <label class="container cflex"
                                        [ngClass]="{'is-invalid': submitted && !cb2_checked}">Ich akzeptiere den Vertrag
                                        zur Auftragsverarbeitung.
                                        <input type="checkbox" [(ngModel)]="cb2_checked">
                                        <span class="checkmark"></span>
                                    </label>
                                    <label class="container cflex"
                                        [ngClass]="{'is-invalid': submitted && !cb3_checked}">
                                        <p>Ich stimme den <a href="https://www.volkshandwerker.de/privacy/"
                                                target="_blank"> <u>
                                                    Datenschutzbestimmungen </u> </a> und den <a
                                                href="https://www.volkshandwerker.de/agb/" target="_blank"> <u> AGB </u>
                                            </a> zu.</p>
                                        <input type="checkbox" [(ngModel)]="cb3_checked">
                                        <span class="checkmark"></span>
                                    </label>

                                </div>
                                <div class="button-frame">
                                    <button class="button-9 color-gray" (click)="back()">Zurück</button>
                                    <button class="button-9" [disabled]="paymentLoading"
                                        (click)="subscribe()">Kostenpflichtig <br>
                                        bestellen</button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- LOADING INDICATOR -->
        <ng-template #loadingIndicator>
            <div class="d-flex justify-content-center align-items-center p-5">
                <span *ngIf="isLoading === true" class="spinner-border spinner-border"></span>
            </div>
        </ng-template>

    </div>
    <div class="pattern bottom"></div>
</section>

<!-- INFO  -->
<section class="section section-lg pt-6 line-bottom-light">
    <div class="container">
        <div class="row justify-content-center mb-6">
            <div class="col-12 col-md-8 text-center">
                <h1 class="display-3 mb-4">Alle Abonnementpläne enthalten diese leistungsstarken Funktionen</h1>
                <p class="lead">Wir fügen regelmäßig neue Tools und Funktionen hinzu.</p>
            </div>
        </div>
        <!-- END OF ICON BOX -->
        <div class="row mt-6">
            <div class="col-lg-6 col-md-6 col-xs-6">
                <div id="info-horizontal-component" class="tab-pane tab-example-result fade show active" role="tabpanel"
                    aria-labelledby="info-horizontal-component-tab">
                    <div class="info info-horizontal bg-white">
                        <div class="icon icon-shape icon-shape-info rounded-circle text-white">
                            <i class="ni ni-settings-gear-65"></i>
                        </div>
                        <div class="description pl-4">
                            <h5 class="title text-info">8/5 Kundenbetreuung</h5>
                            <p>Haben Sie eine Frage, ein Anliegen oder ein Feedback für uns? Unser Support-Team ist
                                nur eine E-Mail entfernt - Montag bis Freitag</p>
                        </div>
                    </div>
                </div>
            </div>
            <!-- ICON BOX -->
            <div class="col-lg-6 col-md-6 col-xs-6">
                <div id="info-horizontal-component" class="tab-pane tab-example-result fade show active" role="tabpanel"
                    aria-labelledby="info-horizontal-component-tab">
                    <div class="info info-horizontal bg-white">
                        <div class="icon icon-shape icon-shape-info rounded-circle text-white">
                            <i class="ni ni-hat-3"></i>
                        </div>
                        <div class="description pl-4">
                            <h5 class="title text-info">Neue Funktionen werden regelmäßig veröffentlicht</h5>
                            <p>Unser Entwicklungszyklus ist schnell. Wir aktualisieren häufig bestehende Tools und
                                veröffentlichen Updates stark beeinflusst durch Anfragen unserer Kunden.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>