<div class="alert-window alert-buttons"
  *ngIf="accountService.isLoggedIn() && accountService.userValue?.user?.subscriber?.company?.subscriptionStatus == 'active' ? true : false">
  <button (click)="navigateToBranches()">Filiale bearbeiten</button>
  <button [routerLink]="['/user-area/dashboard']">Dashboard</button>
  <button (click)="logout()" class="logout">Logout</button>
</div>


<div class="page-header"
  [ngClass]="{'extra-padding': accountService.userValue?.user?.subscriber?.company?.subscriptionStatus != 'active'}">

  <!-- ALERT -->
  <!--  <div class="alert-window" *ngIf="!isCreatedBranch && isLoggedIn()" (click)="navigateToBranches()">
    <h4>Notice</h4>
    <p>You have an active subscription, but you did not create a branch</p>
    <p>You can create a branch via the "filiale bearbeiten" button on the top right</p>
    <p>If you press here, you will be directed to the relevant page.</p>
  </div> -->


  <div class="align-up">
    <!-- <div class="alert-window alert-buttons"
      *ngIf="accountService.isLoggedIn() && accountService.userValue?.user?.subscriber?.company?.subscriptionStatus == 'active' ? true : false">
      <button (click)="navigateToBranches()">Filiale bearbeiten</button>
      <button [routerLink]="['/user-area/dashboard']">Dashboard</button>
      <button (click)="logout()" class="logout">Logout</button>
    </div> -->
    <!-- <div class="alert-window" *ngIf="!isCreatedBranch && isLoggedIn()" (click)="navigateToBranches()">
      <h4>Notice</h4>
      <p>You have an active subscription, but you did not create a branch</p>
      <p>You can create a branch via the "filiale bearbeiten" button on the top right</p>
      <p>If you press here, you will be directed to the relevant page.</p>
    </div> -->

    <!-- SEARCH -->
    <div class="search-out">
      <div class="container flex-middle">
        <div class="row mt-md-0 mt-5">
          <div class="col-12 col-lg-8">
            <h1 class="display-4" class="titlecolor">
              Suchen Sie einen Handwerker?
            </h1>
            <h1 class="display-4">
              Schluss mit dem Stress!
            </h1>
            <p class="lead pb-4">
              Die große Handwerker Suchmaschine für <br>
              Deutschland, Österreich und die
              Schweiz.
            </p>

            <!-- SEARCH FORM -->
            <volkshandwerker-search-form></volkshandwerker-search-form>
          </div>
          <div class="col-12 col-lg-4 d-none d-lg-block">
            <img src="assets/pictures/picturenexttosearch.svg" width="110%" />
          </div>
        </div>
      </div>
    </div>

  </div>
</div>


<!-- FORUM CALL TO ACTION -->
<div class="bg-primary">
  <div class="container py-md-8 py-5">
    <div class="row">
      <div class="col-12 col-lg-8 mt-3 mb-4 d-none d-lg-block">
        <img src="./assets/pictures/forum1.svg" width="100%">
      </div>
      <div class="col-12 col-lg-4 mt-3 mb-3 justify-content-center align-self-center">
        <h4>Kennen Sie schon unser <div class="text-secondary" style="display:inline;">Volkshandwerker Forum?</div>
        </h4>
        <p>
          Registrieren Sie sich im Volkshandwerker Forum und werden ein wichtiges Mitglied der großen Community. </p>
        Ergreifen Sie die Möglichkeit sich mit anderen Handwerkern <br> und Bauherren auszutauschen. <br>
        <a class="button" button class="btn btn-secondary mt-3" href="https://forum.volkshandwerker.de/forum/">Jetzt dem
          Forum beitreten</a>
      </div>
    </div>
  </div>
</div>

<!-- REGISTER CALL TO ACTION -->
<div class="container">
  <div class="row">
    <div class="col-12 col-lg-8 my-md-8 my-5">
      <h1 class="display-4">
        Werden Sie ein Teil von <div class="text-primary" style="display:inline;">Volkshandwerker</div>!
      </h1>
      <p class="lead pb-4">
        Registrieren Sie sich auf unserer Webseite und profitieren Sie so von vielen Vorteilen und Leistungen. Nutzen
        Sie die Möglichkeit Ihre eigene Webseite zu verlinken und leichter gefunden zu werden. Zusätzlich können Sie das
        öffentliche kostenlose Forum nutzen, und sich rund um die Uhr mit Gleichgesinnten austauschen. <br>
        <a button class="btn btn-danger mt-4" [routerLink]="['/register']">Handwerksbetrieb inserieren</a>
      </p>
    </div>
    <div class="col-12 col-lg-4 mt-8 mb-5 d-none d-lg-block">
      <img src="assets/pictures/werkzeugkasten.svg" width="%" />
    </div>
  </div>
</div>

<!-- TESTIMONIALS -->
<div class="testimonials-1 bg-secondary py-md-8 py-5">
  <div class="container bg-secondary">
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 text-center">
        <h3 class="display-3">Unsere Philosophie<br>
          <div class="text-primary" style="display:inline;">Lokal, schnell, gut.</div>
        </h3>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-8 col-md-12 col-sm-12">
        <h4 class="lead justify-content-center">
          Wir wissen aus eigener Erfahrung, wie schwierig es ist, einen kompetenten Handwerker zu finden. Deswegen gibt
          es jetzt Volkshandwerker. <br>
          Nie mehr Zeit aufwenden um in Branchenbüchern Handwerker zu suchen, oder mühsam im Bekanntenkreis
          herumzufragen. <br>
          Konzentrieren Sie sich auf das Wichtige.
        </h4>
      </div>
      <div class="col-12 col-lg-4 d-none d-lg-block">
        <div class="p-3">
          <img alt="First slide" class="img-fluid rounded shadow transform-perspective-right"
            src="assets/pictures/computerabovefooter.svg" />
        </div>
      </div>
    </div>
  </div>
</div>