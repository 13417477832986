import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as mapboxgl from 'mapbox-gl';
import { BaseComponent } from 'src/app/shared/base/components/base/base-component';
import { Branch } from 'src/app/shared/models/Branch';
import { Company } from 'src/app/shared/models/Company';
import { VerificationStatus } from 'src/app/shared/models/VerificationStatus';
import { environment } from '../../../environments/environment';
import { AccountService } from '../../_services';

@Component({
  selector: 'volkshandwerker-branch-view',
  templateUrl: './branch-view.component.html',
  styleUrls: ['./branch-view.component.scss']
})
export class BranchViewComponent extends BaseComponent implements OnInit {

  branch: Branch;

  company: Company;

  moreVisible: boolean = false;

  carouselImages: any[] = [];

  VerificationStatus = VerificationStatus;

  // MapBox
  map: mapboxgl.Map;
  style = 'mapbox://styles/mapbox/light-v10?optimize=true';
  latitude = 52.0195449;
  longitude = 8.5240056;

  constructor(
    private route: ActivatedRoute,
    public accountService: AccountService,
    private cdr: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit(): void {
    // load Data
    this.route.params.subscribe(params => {

      this.isLoading = true;

      this.accountService.makeGetRequest(`/branches/${params['id']}`).then((branch: Branch) => {

        this.branch = branch;

        if (!this.IsNullOrUndefinedOrEmpty(this.branch?.images)) {
          for (const image of this.branch?.images) {
            this.carouselImages.push({path: image.url});
          }
        }

        if (this.carouselImages.length === 0) {
          this.carouselImages.push({path: '../../../assets/img/placeholders/bilder.svg'});
        }

        this.latitude = +this.branch.latitude;

        this.longitude = +this.branch.longitude;

        this.accountService.makeGetRequest(`/companies/${this.branch.company.id}`).then((company: Company) => {

          this.isLoading = false;

          this.company = company;

          // find element with id "dynamic-logo" and set src attribute to company logo
          const dynamicLogo = document.getElementById('dynamic-logo') as HTMLImageElement;
          if (dynamicLogo) dynamicLogo.src = this.company?.logo?.url;

          // update twitter svg
          setTimeout(() => {
            const svgEl = document.querySelector("body > app-root > div > div.app-container__content > volkshandwerker-branch-view > section.section.bg-white.text-white.pb-5.pt-0 > div > div > div.col-lg-8 > div > div.pull-right.mt-5.mb-3.px-sm-5.px-3 > share-buttons > div > share-button:nth-child(2) > button > div > div > fa-icon")
            if (svgEl) {
              // set background color
              svgEl.parentElement.style.backgroundColor = 'white';
              svgEl.outerHTML = '<svg width="16px" height="16px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"/></svg>';
            }
          }, 100);

          setTimeout(() => {
            this.setMapBoxSettings();
          }, 0);
        });
      });
    });
  }

  // Configures and sets marker for mapbox
  setMapBoxSettings(): void {
    // MapBox
    (mapboxgl as any).accessToken = environment.mapbox.accessToken;
    const map = new mapboxgl.Map({
      container: 'map',
      style: this.style,
      zoom: 13,
      center: [this.latitude, this.longitude]
    });

    // Create a default Marker and add it to the map.
    const marker = new mapboxgl.Marker({ color: '#f5b759'})
      .setLngLat([this.latitude, this.longitude])
      .addTo(map);

    // Create marker
    // const marker = new mapboxgl.Marker({
    //   color: '#f5b759'
    // }).setLngLat([this.latitude, this.longitude]).addTo(map);

    // Add map controls
    // this.map.addControl(new mapboxgl.NavigationControl());
  }
}
