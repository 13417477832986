<div class="wrapper">

  <!-- HEADER -->
  <div class="section-shaped my-0 skew-separator skew-mini">
    <div class="page-header page-header-small header-filter">
      <div class="page-header-image" style="background-image: url('/assets/img/pages/georgie.jpg');"></div>
      <div class="container">
        <div class="header-body text-center mb-7">
          <div class="row justify-content-center">
            <div class="col-xl-5 col-lg-6 col-md-8 px-5">
              <h1 class="text-white">Dein Account</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- CONTENT -->
  <div class="bg-secondary">
    <div class="container bg-white card mb-0">
      <div class="row" *ngIf="isLoading === false; else loadingIndicator">
        <div class="col-md-3">
          <div class="section">
            <section class="text-center">
              <div class="form-group col-xl-12">
                <h4>Logo Upload</h4>

                <div *ngIf="company && company?.logo">
                  <img [src]="company?.logo.url" style="margin: 3px; max-height: 150px; width: auto; max-width: 100%;">
                </div>

                <div class="custom-file">
                  <input type="file" class="custom-file-input" id="iconFile"
                    (change)="handleIconFileInput($event.target.files)">
                  <label class="shadow p-3 mb-5 bg-white rounded" for="iconFile">
                    {{ iconFile ? iconFile.name : 'Datei Auswählen' }}
                  </label>
                </div>
              </div>
            </section>

            <!-- SIDE BAR -->
            <section>
              <ul class="nav flex-column" role="tablist">
                <li class="nav-item">
                  <a class="nav-link active" data-toggle="tab" data-panel="general-information"
                    [routerLink]="['/user-area/dashboard']" role="tablist"
                    (click)="switchPanel(DashboardPanel.GeneralInformation)">
                    Allgemein
                  </a>
                </li>
                <hr class="line-primary" />

                <li class="nav-item">
                  <a class="nav-link" style="cursor: pointer;" data-toggle="tab" data-panel="packs"
                    [routerLink]="['/user-area/dashboard']" role="tablist" (click)="switchPanel(DashboardPanel.Packs)">
                    Gebuchte Pakete
                  </a>
                </li>
                <hr class="line-primary" />

                <li clasNs="nav-item">
                  <a class="nav-link" style="cursor: pointer;" data-toggle="tab" data-panel="profile"
                    [routerLink]="['/user-area/dashboard']" role="tablist"
                    (click)="switchPanel(DashboardPanel.Profile)">
                    Profildaten
                  </a>
                </li>
                <hr class="line-primary" />

                <!-- <li class="nav-item">
                  <a class="nav-link text-muted" data-toggle="tab" role="tablist">
                    <i class="tim-icons icon-credit-card"> </i> Bezahlung
                  </a>
                </li>
                <hr class="line-primary" />

                <li class="nav-item">
                  <a class="nav-link text-muted" data-toggle="tab" role="tablist">
                    <i class="tim-icons icon-volume-98"> </i> Benachrichtungen
                  </a>
                </li> -->
              </ul>
            </section>

          </div>
        </div>

        <!-- FORM -->
        <div class="col-md-8 ml-auto">
          <div class="section">
            <div class="tab-content">

              <!-- GENERAL INFORMATION -->
              <div class="tab-pane active" id="general-information" data-panel="general-information">
                <form [formGroup]="form" (ngSubmit)="onSubmit(categories.options)">

                  <!-- CAPTION -->
                  <header class="position-relative">
                    <h2 class="text-uppercase">Allgemeine Informationen zum Unternehmen</h2>

                    <!-- NOTICE -->
                    <div class="d-flex justify-content-end position-absolute w-100 bottom-0 form-notice">
                      <p class="text-primary m-0">
                        <small>
                          * Pflichtfelder
                        </small>
                      </p>
                    </div>
                  </header>


                  <hr class="line-primary" />

                  <!-- EMAIL -->
                  <div class="row mb-3">
                    <div class="col-md-3 align-self-center">

                      <!-- LABEL -->
                      <label class="labels" for="email">
                        Email*
                      </label>

                    </div>
                    <div class="col-md-9 align-self-center">
                      <div class="form-group">

                        <!-- VALUE -->
                        <input class="form-control" required="required" type="email" formControlName="email"
                          (blur)="emailfocus = false" (emailfocus)="emailfocus = true"
                          [ngClass]="{ 'is-invalid': !isLoadingSubmit && f.email.errors }" />

                        <!-- VALIDATION -->
                        <div *ngIf="!isLoadingSubmit && f.email.errors" class="invalid-feedback">
                          <div *ngIf="form.get('email').hasError('required')">
                            Sie müssen Ihre E-Mail angeben
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>

                  <!-- PHONE -->
                  <div class="row mb-3">
                    <div class="col-md-3 align-self-center">

                      <!-- LABEL -->
                      <label class="labels" for="phone">
                        Telefonnummer*
                      </label>

                    </div>
                    <div class="col-md-6 align-self-center">
                      <div class="form-group" [ngClass]="{ 'focused': phonefocus === true }">

                        <!-- VALUE -->
                        <input class="form-control" id="phone" (blur)="phonefocus = false"
                          [ngClass]="{ 'is-invalid': !isLoadingSubmit && f.phone.errors }"
                          (phonefocus)="phonefocus = true" formControlName="phone" required="required" type="tel" />

                        <!-- VALIDATION-->
                        <div *ngIf="!isLoadingSubmit && f.phone.errors" class="invalid-feedback">
                          <div *ngIf="f.phone.errors.required">
                            Sie müssen Ihre Telefonnummer
                            angeben
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- FACEBOOK -->
                  <div class="row">
                    <div class="col-md-3 align-self-center">
                      <label class="labels" for="facebook">
                        Facebook Link
                      </label>
                    </div>
                    <div class="col-md-9 align-self-center">
                      <div class="form-group">
                        <input type="text" formControlName="facebook" class="form-control"
                          [ngClass]="{ 'is-invalid': !isLoadingSubmit && f.facebook.errors }" />
                        <div *ngIf="!isLoadingSubmit && f.facebook.errors" class="invalid-feedback">
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- INSTAGRAM -->
                  <div class="row">
                    <div class="col-md-3 align-self-center">
                      <label class="labels" for="instagram">
                        Instagram Link
                      </label>
                    </div>
                    <div class="col-md-9 align-self-center">
                      <div class="form-group">
                        <input class="form-control" #instagram id="instagram" formControlName="instagram"
                          (blur)="instagramfocus = false" (instagramfocus)="instagramfocus = true" type="text"
                          [ngClass]="{ 'is-invalid': !isLoadingSubmit && f.instagram.errors }" />
                      </div>
                      <div *ngIf="!isLoadingSubmit && f.instagram.errors" class="invalid-feedback">
                      </div>
                    </div>
                  </div>

                  <!-- TWITTER -->
                  <div class="row">
                    <div class="col-md-3 align-self-center">
                      <label class="labels" for="twitter">
                        Twitter Link
                      </label>
                    </div>
                    <div class="col-md-9 align-self-center">
                      <div class="form-group">
                        <input type="text" formControlName="twitter" class="form-control"
                          [ngClass]="{ 'is-invalid': !isLoadingSubmit && f.twitter.errors }" />
                        <div *ngIf="!isLoadingSubmit && f.twitter.errors" class="invalid-feedback">
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- YOUTUBE -->
                  <div class="row">
                    <div class="col-md-3 align-self-center">
                      <label class="labels" for="youtube">
                        Youtube Link
                      </label>
                    </div>
                    <div class="col-md-9 align-self-center">
                      <div class="form-group">
                        <input type="text" formControlName="youtube" class="form-control"
                          [ngClass]="{ 'is-invalid': !isLoadingSubmit && f.youtube.errors }" />
                        <div *ngIf="!isLoadingSubmit && f.youtube.errors" class="invalid-feedback">
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- XING -->
                  <div class="row">
                    <div class="col-md-3 align-self-center">
                      <label class="labels" for="xing">
                        Xing Link
                      </label>
                    </div>
                    <div class="col-md-9 align-self-center">
                      <div class="form-group">
                        <input type="text" formControlName="xing" class="form-control"
                          [ngClass]="{ 'is-invalid': !isLoadingSubmit && f.xing.errors }" />
                        <div *ngIf="!isLoadingSubmit && f.xing.errors" class="invalid-feedback">
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- LINKEDIN -->
                  <div class="row">
                    <div class="col-md-3 align-self-center">
                      <label class="labels" for="linkedin">
                        LinkedIn Link
                      </label>
                    </div>
                    <div class="col-md-9 align-self-center">
                      <div class="form-group" [ngClass]="{ 'focused': linkedinfocus === true }">
                        <input type="text" #linkedIn formControlName="linkedIn" class="form-control"
                          [ngClass]="{ 'is-invalid': !isLoadingSubmit && f.linkedIn.errors }" />
                        <div *ngIf="!isLoadingSubmit && f.linkedIn.errors" class="invalid-feedback">
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- WEBSITE -->
                  <div class="row">
                    <div class="col-md-3 align-self-center">
                      <label class="labels" for="website">
                        Webseite
                      </label>
                    </div>
                    <div class="col-md-9 align-self-center">
                      <div class="form-group">
                        <input type="text" formControlName="website" class="form-control"
                          [ngClass]="{ 'is-invalid': !isLoadingSubmit && f.website.errors }" />
                        <div *ngIf="!isLoadingSubmit && f.website.errors" class="invalid-feedback">
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- CATEGORIES -->
                  <div class="row">
                    <div class="col-md-3 align-self-center">
                      <label class="labels" for="categories"> Kategorien </label>
                    </div>
                    <div class="col-md-9 align-self-center">
                      <form>
                        <select class="form-control" data-trigger="" #categories id="categories" multiple="multiple"
                          name="categories">
                        </select>
                      </form>
                    </div>
                  </div>

                  <!-- DESCRIPTION -->
                  <div class="row mt-2 mb-3">
                    <div class="col-md-12 align-self-center">

                      <!-- LABEL -->
                      <label for="description">Beschreibung des Unternehmens*</label>

                      <!-- VALUE -->
                      <textarea type="text" formControlName="description" class="form-control"
                        [ngClass]="{ 'is-invalid': !isLoadingSubmit && f.description.errors }"></textarea>

                      <!-- VALIDATION -->
                      <div *ngIf="!isLoadingSubmit && f.description.errors" class="invalid-feedback">
                        <div *ngIf="f.description.errors.required">
                          Sie müssen eine Beschreibung
                          angeben
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- HANDWERKERSCHEIN UPLOAD -->
                  <div class="row">
                    <div class="form-group mt-3 col-md-12 align-self-center">
                      <h4>Handwerkerschein hochladen</h4>

                      <div *ngIf="company && company?.verificationDocuments">
                        <img [src]="company?.verificationDocuments[0].url" height="150" width="200px"
                          style="margin: 2px;">
                      </div>

                      <div class="custom-file">
                        <input type="file" class="custom-file-input" id="docFile"
                          (change)="handleDocFileInput($event.target.files)">
                        <label class="custom-file-label" for="docFile">
                          {{ docFile ? docFile.name : 'Datei Auswählen' }}
                        </label>
                      </div>
                    </div>
                  </div>

                  <!-- ACTIONS -->
                  <div class="form-group d-flex justify-content-end">

                    <!-- SUBMIT -->
                    <button class="btn btn-primary text-white d-flex justify-content-center align-items-center"
                      [disabled]="form.invalid === true" type="submit"
                      [ngClass]="{'btn.disabled submit--disabled': form.invalid === true, 'bg-primary': form.valid === true}">
                      speichern

                      <!-- LOADING INDICATOR -->
                      <span *ngIf="isLoadingSubmit === true" class="spinner-border spinner-border-sm ml-2"></span>
                    </button>
                  </div>

                </form>
              </div>

              <!-- PACK INFORMATION -->
              <div class="tab-pane" id="packs" data-panel="packs">

                <!-- CAPTION -->
                <header>
                  <h2 class="text-uppercase">Gebuchte Pakete</h2>
                </header>

                <hr class="line-primary" />

                <!-- NAME -->
                <div class="row mb-2">
                  <div class="col-md-8 align-self-center text-muted">
                    Paketname:
                  </div>
                  <div class="col-md-4 align-self-center">
                    {{ pack?.name }}
                  </div>
                </div>

                <!-- PRICE -->
                <div class="row mb-2">
                  <div class="col-md-8 align-self-center text-muted">
                    Preis:
                  </div>
                  <div class="col-md-4 align-self-center">
                    {{ pack?.price }} €
                  </div>
                </div>

                <!-- PICTURES -->
                <div class="row mb-2">
                  <div class="col-md-8 align-self-center text-muted">
                    Kontingent an Bildern in Bildergalerien:
                  </div>
                  <div class="col-md-4 align-self-center">
                    {{ pack?.pictures }}
                  </div>
                </div>

                <!-- BRANCHES -->
                <div class="row mb-2">
                  <div class="col-md-8 align-self-center text-muted">
                    Kontingent an Filialen:
                  </div>
                  <div class="col-md-4 align-self-center">
                    {{ pack?.branches }}
                  </div>
                </div>

                <!-- SOCIAL MEDIA ALLOWED -->
                <div class="row mb-2">
                  <div class="col-md-8 align-self-center text-muted">
                    Teilen von Filialen über Soziale Medien möglich:
                  </div>
                  <div class="col-md-4 align-self-center">
                    {{ subscriber?.pack?.allowSocialmedia === true ? 'Ja' : 'Nein' }}
                  </div>
                </div>

                <!-- BOOKED -->
                <div class="row mb-2">
                  <div class="col-md-8 align-self-center text-muted">
                    Gebucht am:
                  </div>
                  <div class="col-md-4 align-self-center">
                    {{ subscriber?.createdAt | date: 'mediumDate' }}
                  </div>
                </div>

                <!-- RUNTIME -->
                <div class="row mb-2">
                  <div class="col-md-8 align-self-center text-muted">
                    Laufzeit:
                  </div>
                  <div class="col-md-4 align-self-center">
                    12 Monate
                  </div>
                </div>

                <!-- PERIOD OF NOTICE -->
                <div class="row mb-2">
                  <div class="col-md-8 align-self-center text-muted">
                    Kündigungsfrist:
                  </div>
                  <div class="col-md-4 align-self-center">
                    1 Monat
                  </div>
                </div>

                <!-- SUBSCRIPTION STATUS -->
                <div class="row mb-2">
                  <div class="col-md-8 align-self-center text-muted">
                    Status:
                  </div>
                  <div class="col-md-4 align-self-center"
                    [ngStyle]="{'color': subscriber?.company?.subscriptionStatus === SubscriptionStatus.Active ? 'green' : 'red'}">
                    {{ subscriber?.company?.subscriptionStatus === SubscriptionStatus.Active ? 'aktiv' : 'inaktiv'}}
                  </div>
                </div>

                <!-- SUBSCRIPTION STATUS -->
                <div class="row mb-2">
                  <div class="col-md-8 align-self-center text-muted">
                    Status:
                  </div>
                  <div class="col-md-4 align-self-center">
                    <a
                      href="mailto:info@volkshandwerker.de?subject=K&uuml;ndigung Paket {{ pack?.name }} {{ subscriber?.firstName }} {{ subscriber?.lastName }}&amp;body=Bitte verfassen Sie Ihren Text hier">
                      <button class="btn btn-primary" style="text-align: left; line-height: 17px; font-size: 14px;">
                        Paket kündigen<br>(E-Mail)
                      </button>
                    </a>
                  </div>
                </div>

                <!-- INVOICE PAGE -->

                <div class="row mb-2">
                  <div class="col-md-8 align-self-center text-muted">
                    Rechnungsseite:
                  </div>
                  <div class="col-md-4 align-self-center">
                    <a href="/user-area/invoices">
                      <button class="btn btn-primary" style="text-align: left; line-height: 17px; font-size: 14px;">
                        Rechnungen
                      </button>
                    </a>
                  </div>
                </div>


              </div>



              <!-- PROFILE INFORMATION -->
              <div class="tab-pane" id="profile" data-panel="profile">

                <!-- CAPTION -->
                <header>
                  <h2 class="text-uppercase">Angaben zum Profil</h2>
                </header>


                <hr class="line-primary" />

                <!-- FIRST NAME -->
                <div class="row mb-2">
                  <div class="col-md-3 align-self-center text-muted">
                    Vorname:
                  </div>
                  <div class="col-md-9 align-self-center">
                    {{ subscriber?.firstName }}
                  </div>
                </div>

                <!-- LAST NAME -->
                <div class="row mb-2">
                  <div class="col-md-3 align-self-center text-muted">
                    Nachname:
                  </div>
                  <div class="col-md-9 align-self-center">
                    {{ subscriber?.lastName }}
                  </div>
                </div>

                <!-- STREET -->
                <div class="row mb-2">
                  <div class="col-md-3 align-self-center text-muted">
                    Straßenname:
                  </div>
                  <div class="col-md-9 align-self-center">
                    {{ subscriber?.street }}
                  </div>
                </div>

                <!-- HOUSE NUMBER -->
                <div class="row mb-2">
                  <div class="col-md-3 align-self-center text-muted">
                    Hausnummer:
                  </div>
                  <div class="col-md-9 align-self-center">
                    {{ subscriber?.houseNumber }}
                  </div>
                </div>

                <!-- CITY -->
                <div class="row mb-2">
                  <div class="col-md-3 align-self-center text-muted">
                    Stadt:
                  </div>
                  <div class="col-md-9 align-self-center">
                    {{ subscriber?.city }}
                  </div>
                </div>

                <!-- POSTAL CODE -->
                <div class="row mb-2">
                  <div class="col-md-3 align-self-center text-muted">
                    Postleitzahl:
                  </div>
                  <div class="col-md-9 align-self-center">
                    {{ subscriber?.postalCode }}
                  </div>
                </div>

                <!-- COUNTRY -->
                <div class="row mb-2">
                  <div class="col-md-3 align-self-center text-muted">
                    Land:
                  </div>
                  <div class="col-md-9 align-self-center">
                    {{ getCountryName(subscriber?.countryIso2) }}
                  </div>
                </div>

                <!-- GENDER -->
                <div class="row mb-2">
                  <div class="col-md-3 align-self-center text-muted">
                    Geschlecht:
                  </div>
                  <div class="col-md-9 align-self-center">
                    {{ accountService.getGender() }}
                  </div>
                </div>

                <!-- VAT NUMBER -->
                <div class="row mb-2">
                  <div class="col-md-3 align-self-center text-muted">
                    VAT-Nummer:
                  </div>
                  <div class="col-md-9 align-self-center">
                    {{ subscriber?.vatNumber }}
                  </div>
                </div>

                <!-- VERIFICATION STATUS -->
                <div class="row mb-2">
                  <div class="col-md-3 align-self-center text-muted">
                    Status:
                  </div>
                  <div class="col-md-8 align-self-center"
                    [ngStyle]="{'color': subscriber?.company?.verificationStatus=== VerificationStatus.Verified ? 'green' : 'red'}">
                    {{ subscriber?.company?.verificationStatus === VerificationStatus.Verified ? 'verifiziert' :
                    'unbestätigt'}}
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>

      </div>

      <!-- LOADING INDICATOR -->
      <ng-template #loadingIndicator>
        <div class="d-flex justify-content-center align-items-center p-5">
          <span *ngIf="isLoading === true" class="spinner-border spinner-border"></span>
        </div>
      </ng-template>

    </div>
  </div>
</div>