import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'volkshandwerker-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  topicFocus;
  firstNameFocus;
  lastNameFocus;
  emailFocus;
  phoneFocus;
  messageFocus;

  form: FormGroup;
  loading = false;
  submitted = false;

  constructor(private fb: FormBuilder) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      topic: ['Wählen Sie bitte ein Thema aus', [Validators.required, Validators.pattern("/\A\s*\z/")]],
      firstName: ['', [Validators.required, Validators.pattern("/\A\s*\z/")]],
      lastName: ['', [Validators.required, Validators.pattern("/\A\s*\z/")]],
      email: ['', [Validators.required, Validators.pattern("/\A\s*\z/")]],
      phone: ['', [Validators.required, Validators.pattern("/\A\s*\z/")]],
      message: ['', [Validators.required, Validators.pattern("/\A\s*\z/")]],
    });
  }

  get f() { return this.form.controls; }

  onSubmit() {
    this.submitted = true;
  }

}
