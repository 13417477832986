<section class="section-header text-white py-7 pb-lg-12 mb-lg-6">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 col-md-8 mt-7 text-center">
        <h1 class="display-2">Filialen</h1>
      </div>
    </div>
  </div>
  <div class="pattern bottom"></div>
</section>
<section class="section section-lg pt-0">
  <div class="container mt-n7 mt-lg-n12 z-2">
    <div class="row justify-content-center">
      <div class="col">
        <div class="card shadow-soft border-light p-4 p-md-5 overflow-auto">
          <table class="table table-striped">
            <thead>
              <tr>
                <th style="width: 20%">Name</th>
                <th style="width: 20%">Postleitzahl</th>
                <th style="width: 20%">Stadt</th>
                <th style="width: 20%">Straße</th>
                <th style="width: 20%">Hausnummer</th>
                <th style="width: 20%">Aktionen</th>
              </tr>
            </thead>
            <tbody>

              <!-- BRANCHES -->
              <tr *ngFor="let branch of branches">
                <td>
                  <a [routerLink]="['/branch/' + branch.id]" style="color: black;">
                    {{branch.name}}
                  </a>
                </td>
                <td>{{branch.postalCode}}</td>
                <td>{{branch.city}}</td>
                <td>{{branch.street}}</td>
                <td>{{branch.houseNumber}}</td>

                <!-- ACTIONS -->
                <td>
                  <button class="btn btn-light"
                    [routerLink]="['/user-area/edit-branch/' + branch.id]">bearbeiten</button>
                  <!-- <button class="btn btn-light" (click)="deleteBranch(branch.id)">löschen</button> -->
                </td>
              </tr>

              <!-- LOADING INDICATOR -->
              <tr *ngIf="isLoading === true">
                <td colspan="6" class="text-center">
                  <span class="spinner-border spinner-border-lg align-center"></span>
                </td>
              </tr>
            </tbody>
          </table>

          <!-- ACTION CREATE -->
          <div class="d-flex justify-content-end align-center"
            *ngIf="allowCreateNewBranch === true && accountService.userValue.user.subscriber?.company?.subscriptionStatus === SubscriptionStatus.Active">
            <button class="btn btn-primary" href="/user-area/add-branch"
              [routerLink]="['/user-area/add-branch']">Filiale hinzufügen</button>
          </div>

          <!-- INFO TEXT -->
          <div class="text-center"
            *ngIf="accountService.userValue.user.subscriber?.company?.subscriptionStatus !== SubscriptionStatus.Active && isLoading === false">
            <p>
              Ihr aktuell gebuchtes Paket ist noch nicht verifiziert. Bitte haben Sie etwas Geduld, wir werden Ihr
              Anliegen in Kürze bearbeiten. Bitte füllen Sie alle Felder im Profil aus.
            </p>
          </div>

        </div>
      </div>
    </div>
  </div>
</section>