import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { first } from 'rxjs/operators';


import { AccountService, AlertService } from "src/app/_services";

@Component({
  selector: 'volkshandwerker-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.scss']
})
export class ForgetPasswordComponent implements OnInit {
  forgetPasswordFocus;

  public forgetPasswordForm: FormGroup;

  loading = false;
  submitted = false;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private accountService: AccountService,
    private alertService: AlertService
  ) { }

  ngOnInit(): void {
    this.forgetPasswordForm = this.formBuilder.group({
      email: ['', Validators.compose([Validators.required, Validators.email])]
    });
  }

  get f() { return this.forgetPasswordForm.controls; }

  onSubmit(email) {
    this.loading = true;
    this.submitted = true;

    this.accountService.makePostRequest('/auth/forgot-password', {email: email}).then(data => {
      this.router.navigate(['/check-reset']);
    }, error => {
    });
  }
}
