import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AccountService } from 'src/app/_services';

@Component({
  selector: 'volkshandwerker-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  logoUrl: string;
  isCollapsed = true;
  dynamicLogo = false;
  placeholderUrl = "assets/pictures/volkshandwerker-logo-white-sub-v2.png";
  //isSubscriber = this.accountService.userValue?.user?.subscriber?.company?.subscriptionStatus == 'active' ? true : false //this.accountService.isSubscriber();

  constructor(
    private router: Router,
    public accountService: AccountService
  ) {
    router.events.subscribe(val => {
      this.isCollapsed = true;
      
      // if url includes /branch/ then we want to show the dynamic logo
      if (this.router.url.includes('/branch/')) {
        this.dynamicLogo = true;
      } else {
        this.dynamicLogo = false;
        const dynamicLogo = document.getElementById('dynamic-logo') as HTMLImageElement;
        if (dynamicLogo) dynamicLogo.src = this.placeholderUrl;
      }
    });
    this.logoUrl = null;
  }

  mobileView() {
    if (window.innerWidth < 992) {
      return true;
    }
    return false;
  }

  ngOnInit() { 
    //this.logoUrl = this.accountService.userValue?.user?.subscriber?.company?.logo?.url;
  }

  logout(): void {
    this.accountService.logout();
    this.router.navigate(['']);
  }
}
