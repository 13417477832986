<div class="wrapper" [ngStyle]="{'margin-top': headerHeight + 'px'}">
  <div class="page-header bg-default">
    <div class="page-header-image" style="background-image: url('assets/img/ill/register_bg.png');"></div>
    <div class="container" id="container">
      <div class="form-container sign-up-container">
        <form (ngSubmit)="onSubmitRegister()" role="form" [formGroup]="registerForm">
          <h2>Registrierung</h2>
          <!-- Name Input -->
          <div class="form-group" [ngClass]="{ 'focused': namefocus === true }">
            <div class="input-group input-group-alternative">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="ni ni-circle-08"></i>
                </span>
              </div>
              <input class="form-control" placeholder="Name" formControlName="registerUsername" type="text"
                (blur)="namefocus = false" (namefocus)="namefocus = true"
                [ngClass]="{ 'is-invalid': submitted && regf.registerUsername.errors }" />
              <div *ngIf="submitted && regf.registerUsername.errors" class="invalid-feedback">
                <div *ngIf="regf.registerUsername.errors.required">Der Name ist erforderlich</div>
              </div>
            </div>
          </div>

          <!-- Email Input  -->
          <div class="form-group" [ngClass]="{ 'focused': emailfocus === true }">
            <div class="input-group input-group-alternative">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="ni ni-email-83"> </i>
                </span>
              </div>
              <input class="form-control" placeholder="E-Mail Adresse" formControlName="registerEmail" type="text"
                (blur)="emailfocus = false" (focus)="emailfocus = true"
                [ngClass]="{ 'is-invalid': submitted && regf.registerEmail.errors }">
              <div *ngIf="submitted && regf.registerEmail.errors" class="invalid-feedback">
                <div *ngIf="regf.registerEmail.errors.required">E-Mail ist erforderlich</div>
                <div *ngIf="regf.registerEmail.errors.taken">Die E-Mail Adresse existiert bereits</div>
                <div *ngIf="regf.registerEmail.errors.pattern">E-Mail enspricht nicht dem Muster</div>
              </div>
            </div>
          </div>
          <!-- Passwort Input-->
          <div class="form-group" [ngClass]="{ 'focused': passwordfocus === true }">
            <div class="input-group input-group-alternative">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="ni ni-lock-circle-open"> </i>
                </span>
              </div>
              <input class="form-control" placeholder="Passwort" formControlName="registerPassword" type="password"
                (blur)="passwordfocus = false" (focus)="passwordfocus = true"
                [ngClass]="{ 'is-invalid': submitted && regf.registerPassword.errors }" autocomplete="on" />
              <div *ngIf="submitted && regf.registerPassword.errors" class="invalid-feedback">
                <div *ngIf="regf.registerPassword.errors.required">Passwort ist erforderlich</div>
                <div *ngIf="regf.registerPassword.errors.pattern">
                  Passwort muss mindestens 8 Zeichen haben und mindestens aus
                  einem großen, kleinen Buchstaben, einer Zahl bestehen
                </div>
              </div>
            </div>
          </div>
          <div class="d-block align-items-center mt-2">
            <div class="form-group form-check mt-3">
              <input formControlName="conditionCheck" type="checkbox" class="form-check-input"
                [ngClass]="{ 'is-invalid': submitted && regf.conditionCheck.errors }" id="conditionCheck">
              <label class="form-check-label form-check-sign-white" for="conditionCheck">
                Ich habe die <a href="/agb">Allgemeinen Geschäftsbedingungen</a> und
                <a href="/privacy">Datenschutzrichtlinien</a> gelesen und stimme diesen zu.
              </label>
            </div>
            <div class="form-group form-check mt-3">
              <input formControlName="rightOfWithdrawalCheck" type="checkbox" class="form-check-input"
                [ngClass]="{ 'is-invalid': submitted && regf.rightOfWithdrawalCheck.errors }"
                id="rightOfWithdrawalCheck">
              <label class="form-check-label form-check-sign-white" for="rightOfWithdrawalCheck">
                Ich habe die Widerrufsbelehrung gelesen <a href="/agb">Widerrufsbelehrung</a>
              </label>
            </div>
          </div>
          <button class="btn btn-primary" type="submit">Registrieren</button>

          <div class="show-mobile mt-4">
            <button class="btn btn-neutral btn-sm" id="signInMobile" type="button" (click)="switchFormPanel()">
              Oder einloggen
            </button>
          </div>
        </form>
      </div>

      <!-- Login -->
      <div class="form-container sign-in-container">
        <form (ngSubmit)="onSubmitLogin()" role="form" [formGroup]="loginForm">
          <h2>Einloggen</h2>

          <!-- EMAIL -->
          <div class="form-group" [ngClass]="{ 'focused': loginFocus.usernameFocus === true }">
            <div class="input-group input-group-alternative">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="ni ni-email-83"> </i>
                </span>
              </div>
              <input type="text" placeholder="Benutzername / E-Mail" formControlName="loginUsername"
                class="form-control" [ngClass]="{ 'is-invalid': submitted && logf.loginUsername.errors }" />
              <div *ngIf="submitted && logf.loginUsername.errors" class="invalid-feedback">
                <div *ngIf="logf.loginUsername.errors.required">Name ist erforderlich</div>
              </div>
            </div>
          </div>

          <!-- PASSWORD -->
          <div class="form-group" [ngClass]="{ 'focused': loginFocus.passwordFocus === true }">
            <div class="input-group input-group-alternative">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="ni ni-lock-circle-open"> </i>
                </span>
              </div>
              <input type="password" placeholder="Passwort" formControlName="loginPassword" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && logf.loginPassword.errors }" autocomplete="on" />
              <div *ngIf="submitted && logf.loginPassword.errors" class="invalid-feedback">
                <div *ngIf="logf.loginPassword.errors.required">Passwort ist erforderlich!</div>
              </div>
            </div>
          </div>
          <div>
            <a routerLink="/forget-password">Passwort vergessen?</a>
          </div>
          <button class="btn btn-primary mt-3" type="submit">Einloggen</button>


          <div class="show-mobile mt-4">
            <button class="btn btn-neutral btn-sm" id="signUpMobile" type="button" (click)="switchFormPanel()">
              Oder registrieren
            </button>
          </div>
        </form>
      </div>
      <!-- Slider -->
      <div class="overlay-container">
        <div class="overlay">
          <div class="overlay-panel overlay-right">
            <h1 class="text-white">Willkommen zurück!</h1>
            <p>
              Loggen Sie sich mit Ihren persönlichen Daten ein
            </p>
            <button class="btn btn-neutral btn-sm" id="signUp" (click)="switchFormPanel()">
              Zur Registrierung
            </button>
          </div>
          <div class="overlay-panel overlay-left">
            <h1 class="text-white">Herzlich willkommen!</h1>
            <p>Daten angeben und losgehts!</p>
            <button class="btn btn-neutral btn-sm" id="signIn" (click)="switchFormPanel()">
              Zum Login
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>