import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DialogService } from '@ngneat/dialog';
import { Subscription } from 'rxjs';

import { CookieDialogComponent } from './shared/components/cookie-dialog/cookie-dialog.component';
import { __ } from './shared/functions/object.functions';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  private popupOpenSubscription: Subscription;
  private popupCloseSubscription: Subscription;
  private initializeSubscription: Subscription;
  private statusChangeSubscription: Subscription;
  private revokeChoiceSubscription: Subscription;
  private noCookieLawSubscription: Subscription;

  constructor(public router: Router,

    public dialog: DialogService
  ) { }

  ngOnInit() {
    this.checkCookies();
  }

  private checkCookies(): void {
    const gaProperty = 'G-aaaaaaa';
    const disableStr = 'ga-disable-' + gaProperty;

    if (__.IsNullOrUndefined(localStorage.getItem(CookieDialogComponent.cookieKey))) {
      this.dialog.open(CookieDialogComponent, { enableClose: false })
        .afterClosed$
        .subscribe((cookieType: string) => {
          if (cookieType === 'Necessary') {
            document.cookie = disableStr + '=true; expires=Thu, 31 Dec 2099 23:59:59 UTC; path=/';
            window[disableStr] = true;
          }
        })
    } else {
      const cookieType = JSON.parse(localStorage.getItem(CookieDialogComponent.cookieKey));
      if (cookieType === 'Necessary') {
        document.cookie = disableStr + '=true; expires=Thu, 31 Dec 2099 23:59:59 UTC; path=/';
        window[disableStr] = true;
      }
    }
  }

  ngOnDestroy() {
    // unsubscribe to cookieconsent observables to prevent memory leaks
    this.popupOpenSubscription.unsubscribe();
    this.popupCloseSubscription.unsubscribe();
    this.initializeSubscription.unsubscribe();
    this.statusChangeSubscription.unsubscribe();
    this.revokeChoiceSubscription.unsubscribe();
    this.noCookieLawSubscription.unsubscribe();
  }
}
