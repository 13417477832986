import { ViewportScroller } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { fromEvent, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, shareReplay, takeUntil } from 'rxjs/operators';
import { BaseComponent } from 'src/app/shared/base/components/base/base-component';
import { Branch } from 'src/app/shared/models/Branch';
import { Category } from 'src/app/shared/models/Category';
import { VerificationStatus } from 'src/app/shared/models/VerificationStatus';
import { AccountService } from '../../_services';

@Component({
  selector: 'volkshandwerker-branch-list',
  templateUrl: './branch-list.component.html',
  styleUrls: ['./branch-list.component.scss']
})
export class BranchListComponent extends BaseComponent implements OnInit, OnDestroy {

  categories: Category[] = [];

  branches: Branch[] = [];

  VerificationStatus = VerificationStatus;

  emptyMessage: string;

  isShownScrollToTopButton: boolean = false;

  destroy = new Subject();

  destroy$ = this.destroy.asObservable();

  constructor(
    private route: ActivatedRoute,
    private accountService: AccountService,
    private viewportScroller: ViewportScroller,
  ) {
    super();
  }

  ngOnInit(): void {
    // load data
    this.route.params.subscribe(params => {

      this.isLoading = true;
      this.branches = [];

      // Generate request with queries
      let request: string = '/branches?location=' + params['location']
      if (params['category'] >= 0) {
        request += '&company.categories_in=' + params['category'];
      }

      // load Branches
      this.accountService.makeGetRequest(request).then((data: any) => {
        this.branches = data;
        this.isLoading = false;

        this.viewportScroller.scrollToAnchor('results');

        if (this.branches.length === 0) {
          this.emptyMessage = 'Es konnten keine Suchergebnisse gefunden werden.'
        }
      }).catch((error: any) => {
        this.branches = [];
        if (!this.IsNullOrUndefined(error.error.error.details) ? !this.IsNullOrUndefined(error.error.error.details.code) : false) {
          this.emptyMessage = error.error.error.message;
        }
        this.isLoading = false;
      });
    });

    this.listenForScrollPosition();
  }

  ngOnDestroy(): void {
    this.destroy.next();
  }

  scrollToResults(): void {
    this.viewportScroller.scrollToAnchor('results');
  }

  listenForScrollPosition(): void {
    fromEvent(window, 'scroll')
      .pipe(
        map(x => window.scrollY),
        debounceTime(100),
        distinctUntilChanged(),
        shareReplay(1),
        takeUntil(this.destroy$)
      )
      .subscribe((scrollY: number) => {
        if ((document.body.scrollHeight - (scrollY + 800)) > 500
          && scrollY > 500
          && this.isLoading === false) {
          this.isShownScrollToTopButton = true;
        } else {
          this.isShownScrollToTopButton = false;
        }
      });
  }

}
