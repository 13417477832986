import { Component, OnInit } from '@angular/core';
import { AccountService } from '../_services/account.service';

@Component({
  selector: 'volkshandwerker-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.scss']
})
export class InvoicesComponent implements OnInit {
  invoices: any[] = [];
  isLoading: boolean;

  constructor(public accountService: AccountService) { }

  ngOnInit() {
    this.isLoading = true;

    this.accountService
      .makeGetRequest(`/invoices`)
      .then((invoice) => {
        invoice.forEach((inv) => { inv.invoice_date = new Date(inv.invoice_date).toLocaleString() })
        this.isLoading = false;
        this.invoices = invoice;
      });
  }

}
