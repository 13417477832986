<!-- HERO -->
<section class="section-header bg-primary text-white pt-8 pb-9 pb-lg-11">
    <div class="container">

        <!-- CAPTION -->
        <div class="row justify-content-center mb-4">
            <div class="col-12 col-md-10 text-center">
                <h1 class="display-2 mb-3 text-white">Unser Preisplan einfach gemacht</h1>
            </div>
        </div>

        <!-- PACKS -->
        <div class="row text-darker" *ngIf="isLoading === false; else loadingIndicator">
            <div class="col-12 col-lg-{{12 / this.packs.length}}" *ngFor="let pack of packs">
                <!-- Card -->
                <div class="card shadow-soft mb-5 mb-lg-6 px-2">
                    <div class="card-header py-5 px-4">
                        <!-- Price -->
                        <div class="d-flex mb-3 text-{{pack.color}}">
                            <span class="h5 mb-0">€</span>
                            <span class="price display-2 text-{{pack.color}} mb-0" data-annual="0"
                                data-monthly="0">{{pack.price}}</span>
                            <span class="h6 font-weight-normal align-self-end">/Monat</span>
                        </div>
                        <p class="ml-2 text-black text-muted">zzgl. Mehrwertsteuer</p>
                        <h4 class="mb-3 text-black">{{pack.name}}</h4>
                        <p class="font-weight-normal mb-0">{{pack.description}}</p>
                    </div>
                    <div class="card-body pt-5">
                        <ul class="list-group simple-list">
                            <li class="font-weight-normal">
                                <span class="icon-{{pack.color}}">
                                    <i class="fas fa-check"></i></span> 1 Logo als Profilbild
                            </li>
                            <br>
                            <li class="font-weight-normal"><span class="icon-{{pack.color}}">
                                    <i class="fas fa-check"></i></span> Kontaktdaten (Anschrift + Telefonnummer)
                            </li>
                            <br>
                            <li class="font-weight-normal"><span class="icon-{{pack.color}}">
                                    <i class="fas fa-check"></i></span> Beschreibung des Betriebes
                            </li>
                            <br>
                            <li class="font-weight-normal"><span class="icon-{{pack.color}}">
                                    <i class="fas fa-check"></i></span> {{pack.pictures}} {{ pack.pictures == 1 ?
                                "Bild" : "Bilder" }}
                            </li>
                            <br>
                            <!-- <li class="font-weight-normal"><span class="icon-{{pack.color}}"><i class="fas fa-check"></i></span>{{pack.sections}} Kategorien</li> -->
                            <li class="font-weight-normal"><span class="icon-{{pack.color}}">
                                    <i class="fas fa-check"></i></span> {{pack.branches}} {{ pack.branches == 1 ?
                                "Filliale" : "Fillialen" }}
                            </li>
                            <br>
                            <li class="font-weight-normal">
                                <span class="icon-{{pack.color}}"><i class="fas fa-check"></i></span>
                                1 monatige Kündigungsfrist
                            </li>
                            <br>
                            <li class="font-weight-normal">
                                <span class="icon-{{pack.color}}"><i class="fas fa-check"></i></span>
                                12 monatige Laufzeit
                            </li>
                            <br>
                            <li *ngIf="pack.allow_socialmedia" class="font-weight-normal">
                                <span class="icon-{{pack.color}}"><i class="fas fa-check"></i></span>
                                Social Media und Webseite Verlinkung
                            </li>
                            <br>
                            <li *ngFor="let feature of pack.features" class="font-weight-normal">
                                <span class="icon-{{pack.color}}"><i class="fas fa-check"></i></span> {{feature}}
                            </li>
                        </ul>
                    </div>
                    <div class="card-footer px-4 pb-4">
                        <!-- Button -->
                        <a routerLink="/user-area/company/{{pack.id}}"
                            class="btn btn-block btn-outline-{{pack.color}} animate-up-2">
                            auswählen <span class="icon icon-xs ml-3"><i class="fas fa-arrow-right"></i></span>
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <!-- LOADING INDICATOR -->
        <ng-template #loadingIndicator>
            <div class="d-flex justify-content-center align-items-center p-5">
                <span *ngIf="isLoading === true" class="spinner-border spinner-border"></span>
            </div>
        </ng-template>

    </div>
    <div class="pattern bottom"></div>
</section>

<!-- INFO  -->
<section class="section section-lg pt-6 line-bottom-light">
    <div class="container">
        <div class="row justify-content-center mb-6">
            <div class="col-12 col-md-8 text-center">
                <h1 class="display-3 mb-4">Alle Abonnementpläne enthalten diese leistungsstarken Funktionen</h1>
                <p class="lead">Wir fügen regelmäßig neue Tools und Funktionen hinzu.</p>
            </div>
        </div>
        <!-- END OF ICON BOX -->
        <div class="row mt-6">
            <div class="col-lg-6 col-md-6 col-xs-6">
                <div id="info-horizontal-component" class="tab-pane tab-example-result fade show active" role="tabpanel"
                    aria-labelledby="info-horizontal-component-tab">
                    <div class="info info-horizontal bg-white">
                        <div class="icon icon-shape icon-shape-info rounded-circle text-white">
                            <i class="ni ni-settings-gear-65"></i>
                        </div>
                        <div class="description pl-4">
                            <h5 class="title text-info">8/5 Kundenbetreuung</h5>
                            <p>Haben Sie eine Frage, ein Anliegen oder ein Feedback für uns? Unser Support-Team ist
                                nur eine E-Mail entfernt - Montag bis Freitag</p>
                        </div>
                    </div>
                </div>
            </div>
            <!-- ICON BOX -->
            <div class="col-lg-6 col-md-6 col-xs-6">
                <div id="info-horizontal-component" class="tab-pane tab-example-result fade show active" role="tabpanel"
                    aria-labelledby="info-horizontal-component-tab">
                    <div class="info info-horizontal bg-white">
                        <div class="icon icon-shape icon-shape-info rounded-circle text-white">
                            <i class="ni ni-hat-3"></i>
                        </div>
                        <div class="description pl-4">
                            <h5 class="title text-info">Neue Funktionen werden regelmäßig veröffentlicht</h5>
                            <p>Unser Entwicklungszyklus ist schnell. Wir aktualisieren häufig bestehende Tools und
                                veröffentlichen Updates stark beeinflusst durch Anfragen unserer Kunden.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>