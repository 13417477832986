import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountService } from '../_services';

@Component({
  selector: 'volkshandwerker-check-payment',
  templateUrl: './check-payment.component.html',
  styleUrls: ['./check-payment.component.scss']
})
export class CheckPaymentComponent implements OnInit {

  constructor(private router: Router, private accountService: AccountService, private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
  }

  onSubmit() {

    this.accountService.makePayment('/stripe').then(data => {
      document.location.href = data.url
    }, error => {
    });
  }
}
