<!-- CONTACT FORM -->
<form [formGroup]="form" (ngSubmit)="onSubmit()">

  <!-- HEADER -->
  <div>
    <h5 class="text-left mb-3">
      Kontakt Formular
    </h5>
  </div>

  <!-- NAME -->
  <div class="form-group mb-4 position-relative text-left">

    <!-- LABEL -->
    <label class="labels" for="name">
      Name
    </label>

    <!-- VALUE -->
    <input id="name" type="text" placeholder="Name" formControlName="name" class="form-control"
      [ngClass]="{ 'is-invalid': form.get('name').hasError('required') && form.get('name').touched }" />

    <!-- ERROR MESSAGE -->
    <ng-container *ngIf="form.get('name').hasError('required') && form.get('name').touched">
      <span class="form-field-error-message">
        Bitte geben Sie einen Namen an
      </span>
    </ng-container>

  </div>

  <!-- EMAIL -->
  <div class="form-group mb-4 position-relative text-left">

    <!-- LABEL -->
    <label class="labels" for="email">
      Email
    </label>

    <!-- VALUE -->
    <input id="email" class="form-control" required="required" type="email" formControlName="email"
      placeholder="E-Mail-Addresse"
      [ngClass]="{ 'is-invalid': form.get('email').hasError('required') && form.get('email').touched }" />

    <!-- ERROR MESSAGE -->
    <ng-container *ngIf="form.get('email').hasError('required') && form.get('email').touched">
      <span class="form-field-error-message">
        Bitte geben Sie eine E-Mail-Addresse an
      </span>
    </ng-container>

    <!-- ERROR MESSAGE -->
    <ng-container *ngIf="form.get('email').hasError('email') && form.get('email').touched">
      <span class="form-field-error-message">
        Die E-Mail-Addresse ist nicht valide
      </span>
    </ng-container>

  </div>

  <!-- DESCRIPTION -->
  <div class="form-group position-relative text-left">

    <!-- LABEL -->
    <label class="labels" for="message">
      Nachricht
    </label>

    <!-- VALUE -->
    <textarea id="message" type="text" formControlName="message" class="form-control" placeholder="Nachricht"
      [ngClass]="{ 'is-invalid': form.get('message').hasError('required') && form.get('message').touched }"></textarea>

    <!-- ERROR MESSAGE -->
    <ng-container *ngIf="form.get('message').hasError('required') && form.get('message').touched">
      <span class="form-field-error-message">
        Bitte geben Sie eine Nachricht an
      </span>
    </ng-container>

  </div>

  <re-captcha #captchaRef (resolved)="resolve($event)" (error)="unresolve($event)" errorMode="handled"
    siteKey="6Lcgh-8dAAAAAJrMXMB8mbL14D_KrjqG47K2O_2L" size="invisible"></re-captcha>

  <!-- SUBMIT -->
  <div class="mt-4">
    <button (click)="captchaRef.execute()"
      class="form-control btn btn-primary text-white d-flex justify-content-center align-items-center"
      [disabled]="form.invalid === true" type="button"
      [ngClass]="{'btn.disabled submit--disabled': form.invalid === true, 'bg-primary': form.valid === true}">
      Senden

      <!-- LOADING INDICATOR -->
      <span *ngIf="isLoadingSubmit === true" class="spinner-border spinner-border-sm ml-2"></span>
    </button>
  </div>

  <!-- INFO -->
  <div *ngIf="!IsNullOrUndefinedOrEmpty(companyName)" class="mt-4 text-left font-size-small">
    <p style="margin-bottom: 0;">
      <small>
        Ihre Nachricht wird an den Handwerksbetrieb <span class="font-weight-bold">{{ companyName }}</span> gesendet. Bitte hinterlassen Sie Ihre Kontaktdaten für die Rückmeldung.
      </small>
    </p>
  </div>
</form>