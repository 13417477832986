import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { User } from '../shared/models/User';
import { timeout } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AccountService {
  private userSubject: BehaviorSubject<User>;
  public user: Observable<User>;
  loginIsSubscriber: boolean = false;

  constructor(private http: HttpClient) {
    const usr = localStorage.getItem('user')
    this.userSubject = new BehaviorSubject<User>(JSON.parse(usr));
    this.user = this.userSubject.asObservable();
    if (usr) this.updateUserInfo();
  }

  public get userValue(): User {
    return this.userSubject.value;
  }

  login(username: string, password: string) {
    const req = {
      identifier: username,
      password
    };

    return this.http.post<any>(environment.apiUrl + `/auth/local`, req)
      .pipe(map(user => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('user', JSON.stringify(user));
        this.userSubject.next(user);
        return this.userValue;

        /*if (user && user.token) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('user', JSON.stringify(user));
          this.userSubject.next(user);
        }*/
      }));
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
    this.userSubject.next(null);
  }

  register(user: any) {
    var req = {
      "username": user.username,
      "email": user.email,
      "password": user.password//,
      //"created_by": "webui"
    };

    return this.http.post(environment.apiUrl + `/auth/local/register`, req);
    //return this.http.post(`${environment.apiUrl}/users/register`, user);
  }

  updateUserInfo() {
    this.makeGetRequest('/users/me').then((data) => {
      let info = JSON.parse(localStorage.getItem('user'))
      info.user = data
      localStorage.setItem('user', JSON.stringify(info));
      this.userSubject.next(info);
      return this.userValue
    })
    /* return this.makeGetRequest('/users/me').then((user: any) => {
      this.makeGetRequest('/my-subscription').then(data => {
        let info = JSON.parse(localStorage.getItem('user'))
        info.user = user
        info.user.subscriber = data
        localStorage.setItem('user', JSON.stringify(info));
        return this.userSubject.next(info);
      })
    }); */
  }

  async updateUserInfoAsync() {
    await this.makeGetRequest('/users/me').then(async (data) => {
      let info = JSON.parse(localStorage.getItem('user'))
      info.user = data
      localStorage.setItem('user', JSON.stringify(info));
      this.userSubject.next(info);
      return this.userValue
    })
    return this.userValue

  };

  isLoggedIn() {
    return this.userValue !== null;
  }

  isSubscriber() {
    if (!this.userValue) return false
    return this.userValue?.user?.subscriber?.company?.subscriptionStatus == 'active' ? true : false
  }

  getURL() {
    return environment.apiUrl;
  }

  getHttpOptions() {
    var httpOptions: any;

    // check if user is authenticated
    if (this.userValue != null) {
      // request header with token

      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + this.userSubject.value["jwt"],
          'Cache-Control': 'no-cache'
        })
      };
    } else {
      // request header without token
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache'
        })
      };
    }

    return httpOptions;
  }

  async makeGetRequest(path: string): Promise<any> {
    try {
      const req = await this.http.get(environment.apiUrl + path, this.getHttpOptions()).toPromise();
      return req
    }
    catch (err) {
      if (err.status == 401)
        this.logout()
      throw err
    }
  }

  makePostRequest(path: string, obj: any) {
    return this.http.post(environment.apiUrl + path, obj, this.getHttpOptions()).toPromise();
  }

  post(path: string, obj: any): Observable<any> {
    return this.http.post(environment.apiUrl + path, obj, this.getHttpOptions());
  }

  makePutRequest(path: string, obj: any) {
    return this.http.put(environment.apiUrl + path, obj, this.getHttpOptions()).toPromise();
  }

  put(path: string, obj: any): Observable<any> {
    return this.http.put(environment.apiUrl + path, obj, this.getHttpOptions());
  }

  makeDeleteRequest(path: string) {
    return this.http.delete(environment.apiUrl + path, this.getHttpOptions()).toPromise();
  }
  async makePayment(path: string): Promise<any> {

    const email = this.userValue.user.email // JSON.parse(localStorage.getItem('user')).user.email;
    const name = this.userValue.user.username // JSON.parse(localStorage.getItem('user')).user.username;
    const subscriber = this.userValue.user.subscriber // JSON.parse(localStorage.getItem('user')).user.subscriber.company.id;
    if (!email) throw new Error('No email found');
    if (!name) throw new Error('No name found');
    return this.http.post(environment.stripeUrl + path, { 'email': email, 'name': name, 'subscriber': subscriber }, this.getHttpOptions()).pipe(timeout(10000)).toPromise();
  }

  makeFileUpload(fileToUpload: File, addWatermark: boolean = false) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.userSubject.value["jwt"]
      })
    };

    const query = addWatermark ? '?addWatermark=true' : '';

    const obj: FormData = new FormData();
    obj.append('files', fileToUpload, fileToUpload.name);
    obj.append('fileInfo', `{"alternativeText":"${fileToUpload.name}","caption":"${fileToUpload.name}","name":"${fileToUpload.name}"}`);
    return this.http.post(environment.apiUrl + `/upload${query}`, obj).toPromise();
  }

  gotoInvoice(id: string) {
    window.open(environment.stripeUrl + `/stripe/invoice/${id}`, '_blank');
  }

  uploadFile(fileToUpload: File, addWatermark: boolean = false): Observable<File> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.userSubject.value["jwt"]
      })
    };

    const query = addWatermark ? '?addWatermark=true' : '';

    const obj: FormData = new FormData();
    obj.append('files', fileToUpload, fileToUpload.name);
    obj.append('fileInfo', `{"alternativeText":"${fileToUpload.name}","caption":"${fileToUpload.name}","name":"${fileToUpload.name}"}`);

    return this.http.post<File>(environment.apiUrl + `/upload${query}`, obj, httpOptions);
  }

  getGender() {
    const gender = this.userValue?.user?.subscriber?.gender
    return gender == 'male' ? 'Herr' : gender == 'female' ? 'Frau' : gender == 'divers' ? 'Anrede' : 'Keine Angaben'
  }
}
