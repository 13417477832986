<section class="section-header bg-primary text-white pb-9 pb-lg-12 mb-4 mb-lg-6">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 col-md-8 text-center">
                <h1 class="display-2 mb-3">Ein letzter Schritt</h1>
            </div>
        </div>
    </div>
    <div class="pattern bottom"></div>
</section>
<section class="container">
    <section class="section section-lg pt-0">
        <div class="container mt-n7 mt-lg-n12 z-2">
            <div class="row justify-content-center">
                <div class="col">
                    <div class="card shadow-soft border-light p-4 p-md-5">

                        <div class="cerceve">
                            <p>Prüfen Sie Ihr E-Mail Postfach</p>
                            <p>Wir freuen uns, dass Sie sich für Volkshandwerker entschieden haben.</p>
                            <p>Um mit Volkshandwerker durchzustarten, bestätigen Sie bitte Ihre E-Mail Adresse in Ihrem
                                Postfach bzw. Spam-Ordner.</p>
                            <p>Willkommen bei Volkshandwerker <br>
                                Ihr Volkshandwerker Team</p>
                            <p> ( Mındestlaufzeit 12 Monate, danach Monatlich kündbar )</p>


                            <ul type="disc">
                                <li> 1 Logo als Profilbild</li>
                                <li> Kontaktdaten (Anschrift + Telefonnummer)</li>
                                <li> Beschreibung des Betriebes</li>
                                <li> 7 Bilder</li>
                                <li> 1 Filliale</li>
                                <li> 1 monatige Kündigungsfrist</li>
                                <li> 12 monatige Laufzeit</li>
                            </ul>


                        </div>
                        <div class="kutu_fiyat_bilgisi"> 7,99 € <br>
                            <span style="font-size:12px"> Pro Monat zzgl.
                                MwSt.
                            </span>
                        </div>

                        <button class="kutu_paket_bilgisi_onay" (click)="onSubmit()">Auswählen</button>

                    </div>
                </div>
            </div>
        </div>
    </section>