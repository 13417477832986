<div class="cookie-dialog">
  <div *ngIf="mode === 'Intro'">
    <h2 class="cookie-title text-center">Wir benutzen Cookies - Einwilligung</h2>
    <p class="cookie-content text-center mt-3">
      Wir verwenden Dienste von Drittanbietern, die uns helfen, unsere Webseite zu optimieren (Analytics) und
      personalisierte Werbung auszuspielen (Werbung). Ihr Einverständnis umfasst auch die Verarbeitung Ihrer Daten durch
      Partner gegebenenfalls auch in Drittländern (Art. 49 Abs. 1 DSGVO), in denen kein vergleichbares Datenschutzniveau
      vorherrscht (z.B. in den USA).
      <br><br>
      Um diese Dienste verwenden zu dürfen und/oder die Daten weiterzugeben auch an Partner in Drittländern benötigen
      wir
      Ihre Einwilligung. Ihre Einwilligung können Sie jederzeit widerrufen. Weitere Informationen finden Sie in unserer
      <a href="https://volkshandwerker.de/privacy">Datenschutzerklärung</a> sowie nach Anklicken der Buttons.
    </p>
    <div class="cookie-actions text-center" style="display: flex; align-items: center; justify-content: center">

      <button type="button" style="width: 50%;" (click)="accept()" mat-raised-button color="primary" class="btn-green">
        Alle akzeptieren
      </button>

      <button style="margin-left: 4px; width: 50%;" type="button" (click)="mode = 'Manage'" mat-flat-button>
        Anpassen
      </button>

    </div>
  </div>
  <div *ngIf="mode === 'Manage'">
    <h2 class="cookie-title text-center anpassen-title">Cookies anpassen</h2>
    <p class="cookie-content text-center mt-3">
      Lerne mehr über unseren Einsatz von Cookies in unserer <a routerLink="/privacy">Datenschutzerklärung</a>.
    </p>
    <div class="cookie-toggles" style="padding-left: 40px;">
      <div fxLayout fxLayoutGap="12px" class="mb-2">
        <input [formControl]="necessaryFormControl" [checked]="true" [disabled]="true" type="checkbox"
          class="form-check-input">
        <div>
          <p style="margin-top: 2px; font-weight: bold;  margin-bottom: 6px;" class="text-xl">Notwendig</p>
          <p>Werden benötigt, damit die Website funktioniert</p>
        </div>
      </div>
      <div style="margin-top: 12px;" fxLayout fxLayoutGap="12px">
        <input [formControl]="analyticsFormControl" type="checkbox" class="form-check-input">
        <div>
          <p style="margin-top: 2px; font-weight: bold; margin-bottom: 6px;" class="text-xl">Analytics</p>
          <p>Um das Einkaufserlebnis zu verbessern</p>
        </div>
      </div>
      <div style="margin-top: 12px;" fxLayout fxLayoutGap="12px">
        <input [formControl]="analyticsFormControl" type="checkbox" class="form-check-input">
        <div>
          <p style="margin-top: 2px; font-weight: bold; margin-bottom: 6px;" class="text-xl">Werbung</p>
          <p>Personalisierte Werbung auszuspielen</p>
        </div>
      </div>
    </div>
    <div class="cookie-actions text-center">
      <button type="button" (click)="accept()" mat-raised-button color="primary" class="btn-green">
        Alle akzeptieren
      </button>
      <div class="mt-2">
        <button type="button" (click)="save()" mat-flat-button>
          Speichern
        </button>
      </div>
    </div>
  </div>
</div>