import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import Choices from 'choices.js';
import { Toaster } from 'ngx-toast-notifications';
import { Subscription } from 'rxjs';
import { BaseComponent } from 'src/app/shared/base/components/base/base-component';
import { Company } from 'src/app/shared/models/Company';
import { Pack } from 'src/app/shared/models/Pack';
import { Subscriber } from 'src/app/shared/models/Subscriber';
import { SubscriptionStatus } from 'src/app/shared/models/SubscriptionStatus';
import { VerificationStatus } from 'src/app/shared/models/VerificationStatus';
import { AccountService } from 'src/app/_services';
import { DashboardPanel } from './shared/dashboard.panel.enum';

@Component({
  selector: 'volkshandwerker-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent extends BaseComponent implements OnInit, OnDestroy {

  descriptionfocus;
  emailfocus;
  phonefocus;
  facebookfocus;
  instagramfocus;
  twitterfocus;
  linkedinfocus;
  categoriesfocus;
  websitefocus;

  public form: FormGroup;

  public categories: any[] = [];
  public categoriesChoosen: HTMLElement;

  company: Company;

  pack: Pack;

  subscriber: Subscriber;

  DashboardPanel = DashboardPanel;

  VerificationStatus = VerificationStatus;

  SubscriptionStatus = SubscriptionStatus;

  iconFile: File = null;
  docFile: File = null;

  constructor(
    private formBuilder: FormBuilder,
    private accountService: AccountService,
    private toaster: Toaster
  ) {
    super();

    this.form = this.formBuilder.group({
      email: ['', Validators.required],
      phone: ['', Validators.required],
      description: ['', Validators.required],
      facebook: [''],
      instagram: [''],
      twitter: [''],
      youtube: [''],
      xing: [''],
      linkedIn: [''],
      website: [''],
      pack: '1'
    });
  }

  ngOnInit(): void {
    this.isLoading = true;

    const body = document.getElementsByTagName('body')[0];
    body.classList.add('account-settings');

    this.pack = this.accountService.userValue.user?.subscriber?.pack;

    this.subscriber = this.accountService.userValue.user?.subscriber;

    /*new Choices('#choices-single-default', {
      searchEnabled: false,
    });*/

    this.accountService
      .makeGetRequest(`/companies/${this.accountService.userValue.user.subscriber.company.id}`)
      .then(company => {
        this.company = company;

        this.form.patchValue({
          email: this.company?.email,
          phone: this.company?.phone,
          facebook: this.company?.facebook,
          linkedIn: this.company?.linkedIn,
          instagram: this.company?.instagram,
          twitter: this.company?.twitter,
          youtube: this.company?.youtube,
          xing: this.company?.xing,
          website: this.company?.website,
          description: this.company?.description
        });

        this.isLoading = false;

        this.accountService.makeGetRequest('/categories').then((fetchedCategories: any[]) => {
          this.categories = [];
          const choices: any[] = [];

          for (const fetchedCategory of fetchedCategories) {
            this.categories.push({
              display: fetchedCategory.name,
              value: fetchedCategory.id
            });
            choices.push({
              label: fetchedCategory.name,
              value: fetchedCategory.id,
              selected: this.company.categories.some(e => e.id === fetchedCategory.id)
            });
          }

          this.categoriesChoosen = document.getElementById('categories');
          if (this.categoriesChoosen) {
            // tslint:disable-next-line:no-unused-expression
            new Choices('#categories', {
              searchEnabled: true,
              choices,
              delimiter: ',',
              editItems: false,
              maxItemCount: this.accountService.userValue?.user?.subscriber?.pack?.sections ?? 2,
              removeItemButton: true,
            });
          };
        });
      });
  }

  ngOnDestroy() {
    const body = document.getElementsByTagName('body')[0];
    body.classList.remove('account-settings');
  }

  switchPanel(panelName: string): void {
    for (const panelElementName of ['.nav-link', '.tab-pane']) {
      document.querySelectorAll(panelElementName).forEach((panelElement: HTMLElement) => {
        if (panelElement.classList.contains('active') && panelElement.getAttribute('data-panel') !== panelName) {
          panelElement.classList.remove('active');
        } else if (!panelElement.classList.contains('active') && panelElement.getAttribute('data-panel') === panelName) {
          panelElement.classList.add('active');
        }
      });
    }
  }

  getCountryName(countryCode: string): string {
    if (countryCode == 'at')
      return 'ÖSTERREICH'
    else if (countryCode == 'ch')
      return 'SCHWEIZ'
    else
      return 'DEUTSCHLAND'
  }

  // convenience getter for easy access to form fields
  get f() { return this.form.controls; }

  handleIconFileInput(files: FileList) {
    this.iconFile = files.item(0);
  }

  handleDocFileInput(files: FileList) {
    this.docFile = files.item(0);
  }

  onSubmit(choosenCategories: any[]): void {

    this.isLoadingSubmit = true;

    let iconPromise: any;

    let docPromise: any;

    if (!this.IsNullOrUndefined(this.iconFile)) {
      iconPromise = this.accountService.makeFileUpload(this.iconFile).then((data: any) => {
        this.company.logo = data;
      }, error => {
        this.toaster.open({
          caption: 'Fehler!',
          type: 'danger',
          text: 'Es ist ein Fehler beim Hochladen des Logos aufgetreten. Bitte kontaktieren Sie uns.',
          position: 'bottom-left'
        });
      });
    }

    if (!this.IsNullOrUndefined(this.docFile)) {
      docPromise = this.accountService.makeFileUpload(this.docFile).then((data: any) => {
        this.company.verificationDocuments = data;
      }, error => {
        this.toaster.open({
          caption: 'Fehler!',
          type: 'danger',
          text: 'Es ist ein Fehler beim Hochladen des Handwerkscheins aufgetreten. Bitte kontaktieren Sie uns.',
          position: 'bottom-left'
        });
      });
    }

    this.company.subscriber = this.accountService.userValue.user.subscriber.id;
    this.company.categories = [];

    for (const choosenCategory of choosenCategories) {
      this.company.categories.push(choosenCategory['value']);
    }

    this.company.email = this.f.email.value;
    this.company.phone = this.f.phone.value;
    this.company.facebook = this.f.facebook.value;
    this.company.instagram = this.f.instagram.value;
    this.company.twitter = this.f.twitter.value;
    this.company.linkedIn = this.f.linkedIn.value;
    this.company.website = this.f.website.value;
    this.company.description = this.f.description.value;
    this.company.youtube = this.f.youtube.value;
    this.company.xing = this.f.xing.value;

    Promise.all([iconPromise, docPromise]).then(() => {
      this.accountService.makePutRequest(`/companies/${this.company.id}`, this.company).then(data => {
        this.isLoadingSubmit = false;
        this.toaster.open({
          caption: 'Erfolgreich!',
          type: 'success',
          text: 'Ihre Unternehmensdaten wurden erfolgreich gespeichert.',
          position: 'bottom-left'
        });
      }, error => {
        this.isLoadingSubmit = false;
        this.toaster.open({
          caption: 'Fehler!',
          type: 'danger',
          text: 'Es ist ein Fehler aufgetreten. Bitte kontaktieren Sie uns.',
          position: 'bottom-left'
        });
      });
    });
  }
}
