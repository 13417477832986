import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Toaster } from 'ngx-toast-notifications';
import { BaseComponent } from 'src/app/shared/base/components/base/base-component';
import { AccountService, AlertService } from 'src/app/_services';

@Component({
  selector: 'volkshandwerker-create-company',
  templateUrl: './create-company.component.html',
  styleUrls: ['./create-company.component.scss']
})
export class CreateCompanyComponent extends BaseComponent implements OnInit {

  form: FormGroup;

  packId: string;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private accountService: AccountService,
    private alertService: AlertService,
    private activatedRoute: ActivatedRoute,
    private toaster: Toaster
  ) {
    super();

    this.packId = this.route.snapshot.paramMap.get('pack');
    this.form = this.formBuilder.group({
      gender: ['', Validators.required],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      postalCode: ['', Validators.required],
      houseNumber: ['', Validators.required],
      street: ['', Validators.required],
      city: ['', Validators.required],
      telephone: ['', Validators.required],
      BIC: ['', Validators.required],
      IBAN: ['', Validators.required],
      NameZahlungsempfaenger: ['', Validators.required],
      packId: Number(this.packId),
      companyName: ['', Validators.required],
      vatNumber: ['', Validators.required],
      withdrawalPeriodCheck: [false, Validators.requiredTrue],
      sepaCheck: [false, Validators.requiredTrue]
    });
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      this.packId = params['pack'];

      this.form.get('packId').setValue(this.packId);
    });
  }

  // Convenience getter for easy access to form fields
  get f() { return this.form.controls; }

  onSubmit() {
    this.isLoadingSubmit = true;

    // Reset alerts on submit
    this.alertService.clear();

    // Stop here if form is invalid
    if (this.form.invalid) {
      return;
    }

    var values = this.form.value;

    values['countryIso2'] = 'de';
    values['language'] = 'de';

    this.accountService.makePostRequest('/subscribers', values).then(company => {
      this.isLoadingSubmit = false;

      this.alertService.success('Created Company successful', { keepAfterRouteChange: true });

      this.toaster.open({
        caption: 'Erfolgreich!',
        type: 'success',
        text: 'Ihre Daten wurden erfolgreich angelegt.',
        position: 'bottom-left'
      });

      // Redirect to payment
      setTimeout(() => {
        this.accountService.logout();
        this.accountService.makePayment('/stripe').then(data => {
          document.location.href = data.url
        }, error => {
        });
        //this.router.navigate(['/check-payment'], { relativeTo: this.route });
      }, 1000);
    },
      error => {
        this.isLoadingSubmit = false;
        this.toaster.open({
          caption: 'Fehler!',
          type: 'danger',
          text: 'Es ist ein Fehler aufgetreten. Bitte kontaktieren Sie uns.',
          position: 'bottom-left'
        });
      });
    /*
        if (this.hasCompany) {
          this.accountService.makePutRequest('/companies/'+this.company['id'], this.form.value).then(data => {
            this.loading = false;
            this.alertService.success('Created Company successful', { keepAfterRouteChange: true });
            this.router.navigate(['../'], { relativeTo: this.route });
          },
            error => {
              this.alertService.error(error);
              this.loading = false;
            });
        } else {

        }*/
  }
}
