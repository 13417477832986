<div class="wrapper">
  <section class="section-header pt-9 pb-10 text-white">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12 col-md-10 text-center">

          <!-- PAGE TITLE -->
          <div class="d-flex justify-content-center mb-5">
            <div class="col-12 col-sm-10 col-md-9 col-lg-8 text-center">
              <h1 class="display-3">
                Handwerker
              </h1>
            </div>
          </div>

          <!-- SEARCH FORM -->
          <volkshandwerker-search-form></volkshandwerker-search-form>
        </div>
      </div>
    </div>
  </section>

  <!-- BRANCH TILES -->
  <section class="section">
    <div class="container">
      <div class="row align-items-center justify-content-around">

        <!-- SPINNER -->
        <span *ngIf="isLoading === true" class="spinner-border mt-6"></span>

        <!-- BRANCH TILE -->
        <div class="col-12" id="results">
          <div *ngFor="let branch of branches" class="card bg-soft border-light p-3 mb-4" style="margin: 9px">
            <div style="margin: 25px">
              <div class="row">

                <!-- IMAGE -->
                <div class="d-flex justify-content-start align-items-center col-md-3 col-sm-6 col-xs-6">
                  <img *ngIf="branch?.company.logo; else elseBlock" [src]="branch?.company.logo.url"
                    class="img-thumbnail" width="200px">
                  <ng-template #elseBlock>
                    <img src="../../../assets/img/placeholders/logo.svg" class="img-thumbnail" width="200px">
                  </ng-template>
                </div>

                <!-- META -->
                <div class="col-md-6 mt-4 col-sm-6 col-xs-6">
                  <h5 style="display: flex;">
                    <div class="text-dark">
                      {{ branch?.name }}
                    </div>
                    <div class="bg-primary" style="margin-left: 12px; display: flex; border-radius: 50px;"
                      *ngIf="company?.verificationStatus === VerificationStatus.Verified">
                      <!-- VERIFIED -->
                      <div
                        style="flex: 0 1 auto; display: flex; align-items: center; justify-items: center; padding-top: 2px;"
                        class="batch--verified bg-primary ml-2">
                        <i class="fa fa-check" aria-hidden="true"></i>
                      </div>
                      <div
                        style="flex: 1 1 auto; display: flex; align-items: center; justify-items: center; padding-right: 12px;">
                        Verifiziert
                      </div>
                    </div>
                  </h5>
                  <div>
                    {{ branch?.postalCode }} {{ branch?.city }}, {{ branch?.street }} {{ branch?.houseNumber }}
                  </div>
                </div>

                <!-- BUTTON -->
                <div class="d-flex align-items-center justify-content-center col-md-3 mt-4">
                  <button class="btn btn-primary" [routerLink]="['/branch/' + branch?.id]">
                    Profil aufrufen
                  </button>
                </div>

              </div>
            </div>
          </div>
        </div>

        <!-- EMPTY LIST -->
        <div *ngIf="branches?.length === 0 && isLoading === false" class="mt-6">
          {{ emptyMessage }}
        </div>
      </div>
    </div>
  </section>
</div>

<!-- SCROLL TO TOP -->
<div id="scrollToTopButton" style="flex: 0 1 auto; display: flex; align-items: center; justify-items: center; padding-top: 2px;"
  class="scrollToTopButton bg-secondary" (click)="scrollToResults()" *ngIf="isShownScrollToTopButton === true">
  <i class="fas fa-angle-up" aria-hidden="true"></i>
</div>