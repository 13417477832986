<!-- SECTION CAPTION -->
<section class="section-header bg-primary text-white pt-9 pb-7 mb-4 mb-lg-6">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 col-md-8 text-center">
        <h1 class="display-2 mb-3">Unternehmen anlegen</h1>
      </div>
    </div>
  </div>
  <div class="pattern bottom"></div>
</section>

<!-- SECTION FORM -->
<section class="section section-lg pt-0">
  <div class="container mt-n7 mt-lg-n12 z-2">
    <div class="row justify-content-center">
      <div class="col">
        <div class="card shadow-soft border-light p-4 p-md-5">
          <div class="card-body">

            <!-- FORM -->
            <form [formGroup]="form" (ngSubmit)="onSubmit()" class="row">

              <!-- COMPANY NAME -->
              <div class="form-group col-xl-12">
                <label for="companyName">Name des Unternehmens</label>
                <input type="text" formControlName="companyName" class="form-control"
                  [ngClass]="{ 'is-invalid': !isLoadingSubmit && f.companyName.errors && f.companyName.touched }" />
                <div *ngIf="!isLoadingSubmit && f.companyName.errors" class="invalid-feedback">
                  <div *ngIf="f.companyName.errors.required">Geben Sie den Namen Ihres Unternehmens ein</div>
                </div>
              </div>

              <!-- VAT NUMBER -->
              <div class="form-group col-xl-12">
                <label for="vatNumber">Umsatzsteuernummer des Unternehmens</label>
                <input type="text" formControlName="vatNumber" class="form-control"
                  [ngClass]="{ 'is-invalid': !isLoadingSubmit && f.vatNumber.errors && f.vatNumber.touched }" />
                <div *ngIf="!isLoadingSubmit && f.vatNumber.errors" class="invalid-feedback">
                  <div *ngIf="f.vatNumber.errors.required">Geben Sie die Umsatzsteuernummer Ihres Unternehmens ein
                  </div>
                </div>
              </div>

              <!-- FIRST NAME -->
              <div class="form-group col-xl-5">
                <label for="firstName">Vorname</label>
                <input type="text" formControlName="firstName" class="form-control"
                  [ngClass]="{ 'is-invalid': !isLoadingSubmit && f.firstName.errors && f.firstName.touched }" />
                <div *ngIf="!isLoadingSubmit && f.firstName.errors" class="invalid-feedback">
                  <div *ngIf="f.firstName.errors.required">Sie müssen Ihren Vornamen angeben</div>
                </div>
              </div>
              <!--<div class="form-group">
                <label for="middleName">Mittelname</label>
                <input type="text" formControlName="middleName" class="form-control"
                  [ngClass]="{ 'is-invalid': !isLoadingSubmit && f.middleName.errors }" />
                <div *ngIf="!isLoadingSubmit && f.middleName.errors" class="invalid-feedback">
                  <div *ngIf="f.middleName.errors.required">Sie müssen Ihren Mittelnamen angeben</div>
                </div>
              </div>-->

              <!-- LAST NAME -->
              <div class="form-group col-xl-5">
                <label for="lastName">Nachname</label>
                <input type="text" formControlName="lastName" class="form-control"
                  [ngClass]="{ 'is-invalid': !isLoadingSubmit && f.lastName.errors && f.lastName.touched }" />
                <div *ngIf="!isLoadingSubmit && f.lastName.errors" class="invalid-feedback">
                  <div *ngIf="f.lastName.errors.required">Sie müssen Ihren Nachnamen angeben</div>
                </div>
              </div>

              <!-- GENDER -->
              <div class="form-group col-xl-2">
                <label class="form-control-label" for="gender">Geschlecht</label>
                <select formControlName="gender" class="form-control" id="gender"
                  [ngClass]="{ 'is-invalid': !isLoadingSubmit && f.gender.errors && f.gender.touched }">
                  <option value="">Geben Sie Ihr Geschlecht an</option>
                  <option value="m">Männlich</option>
                  <option value="f">Weiblich</option>
                </select>
                <div *ngIf="!isLoadingSubmit && f.gender.errors" class="invalid-feedback">
                  <div *ngIf="f.gender.errors.required">Sie müssen Ihr Geschlecht angeben</div>
                </div>
              </div>

              <!-- POSTAL CODE -->
              <div class="form-group col-xl-6">
                <label for="postalCode">Postleitzahl</label>
                <input type="text" formControlName="postalCode" class="form-control"
                  [ngClass]="{ 'is-invalid': !isLoadingSubmit && f.postalCode.errors && f.postalCode.touched }" />
                <div *ngIf="!isLoadingSubmit && f.postalCode.errors" class="invalid-feedback">
                  <div *ngIf="f.postalCode.errors.required">Sie müssen Ihre Postleitzahl angeben</div>
                </div>
              </div>

              <!-- CITY -->
              <div class="form-group col-xl-6">
                <label for="city">Stadt</label>
                <input type="text" formControlName="city" class="form-control"
                  [ngClass]="{ 'is-invalid': !isLoadingSubmit && f.city.errors && f.city.touched }" />
                <div *ngIf="!isLoadingSubmit && f.city.errors" class="invalid-feedback">
                  <div *ngIf="f.city.errors.required">Sie müssen Ihre Stadt angeben</div>
                </div>
              </div>

              <!-- STREET -->
              <div class="form-group col-xl-6">
                <label for="name">Straße</label>
                <input type="text" formControlName="street" class="form-control"
                  [ngClass]="{ 'is-invalid': !isLoadingSubmit && f.street.errors && f.street.touched }" />
                <div *ngIf="!isLoadingSubmit && f.street.errors" class="invalid-feedback">
                  <div *ngIf="f.street.errors.required">Sie müssen Ihre Straße angeben</div>
                </div>
              </div>

              <!-- HOUSE NUMBER -->
              <div class="form-group col-xl-6">
                <label for="houseNumber">Hausnummer</label>
                <input type="text" formControlName="houseNumber" class="form-control"
                  [ngClass]="{ 'is-invalid': !isLoadingSubmit && f.houseNumber.errors && f.houseNumber.touched }" />
                <div *ngIf="!isLoadingSubmit && f.houseNumber.errors" class="invalid-feedback">
                  <div *ngIf="f.houseNumber.errors.required">Sie müssen Ihre Hausnummer angeben</div>
                </div>
              </div>

              <!-- PHONE -->
              <div class="form-group col-xl-12">
                <label for="telephone">Telefonnummer</label>
                <input type="text" formControlName="telephone" class="form-control"
                  [ngClass]="{ 'is-invalid': !isLoadingSubmit && f.telephone.errors && f.telephone.touched }" />
                <div *ngIf="!isLoadingSubmit && f.telephone.errors" class="invalid-feedback">
                  <div *ngIf="f.telephone.errors.required">Sie müssen Ihre Telefonnummer angeben</div>
                </div>
              </div>

              <!-- NAME ZAHLUNGSMEPFÄNGER -->
              <div class="form-group col-xl-12">
                <label for="NameZahlungsempfaenger">Name Zahlungsempfänger</label>
                <input type="text" formControlName="NameZahlungsempfaenger" class="form-control"
                  [ngClass]="{ 'is-invalid': !isLoadingSubmit && f.NameZahlungsempfaenger.errors && f.NameZahlungsempfaenger.touched }" />
                <div *ngIf="!isLoadingSubmit && f.NameZahlungsempfaenger.errors" class="invalid-feedback">
                  <div *ngIf="f.NameZahlungsempfaenger.errors.required">Sie müssen den Namen des Zahlungsempfängers angeben</div>
                </div>
              </div>

              <!-- IBAN -->
              <div class="form-group col-xl-6">
                <label for="IBAN">IBAN</label>
                <input type="text" formControlName="IBAN" class="form-control"
                  [ngClass]="{ 'is-invalid': !isLoadingSubmit && f.IBAN.errors && f.IBAN.touched }" />
                <div *ngIf="!isLoadingSubmit && f.IBAN.errors" class="invalid-feedback">
                  <div *ngIf="f.IBAN.errors.required">Sie müssen Ihre IBAN angeben</div>
                </div>
              </div>

              <!-- BIC -->
              <div class="form-group col-xl-6">
                <label for="BIC">BIC</label>
                <input type="text" formControlName="BIC" class="form-control"
                  [ngClass]="{ 'is-invalid': !isLoadingSubmit && f.BIC.errors && f.BIC.touched }" />
                <div *ngIf="!isLoadingSubmit && f.BIC.errors" class="invalid-feedback">
                  <div *ngIf="f.BIC.errors.required">Sie müssen Ihre BIC angeben</div>
                </div>
              </div>

              <!-- AGB -->
              <div class="form-group form-check mt-3">
                <input formControlName="withdrawalPeriodCheck" type="checkbox" class="form-check-input"
                  [ngClass]="{ 'is-invalid': !isLoadingSubmit && f.withdrawalPeriodCheck.errors && f.withdrawalPeriodCheck.touched }"
                  id="withdrawalPeriodCheck">
                <label class="form-check-label form-check-sign-white" for="withdrawalPeriodCheck">
                  In meiner Bestellung befinden sich digitale Inhalte. Ich stimme ausdrücklich zu, dass Sie vor Ablauf
                  der <a href="/agb">Widerrufsfrist</a> mit der Ausführung des Vertrages beginnen.
                  Mir ist bekannt, dass ich durch diese Zustimmung mit Beginn der Ausführung des Vertrages mein
                  Widerrufsrecht verliere. <a href="/agb">Widerrufsbelehrung</a>
                </label>
              </div>

              <!-- SEPA -->
              <div class="form-group form-check mt-3">
                <input formControlName="sepaCheck" type="checkbox" class="form-check-input"
                  [ngClass]="{ 'is-invalid': !isLoadingSubmit && f.sepaCheck.errors && f.sepaCheck.touched }"
                  id="sepaCheck">
                <label class="form-check-label form-check-sign-white" for="sepaCheck">
                  Ich ermächtige / wir ermächtigen VHW UG (haftungsbeschränkt) Zahlungen von meinem/unserem
                  Konto
                  mittels Lastschrift einzuziehen. Zugleich weise ich meinen/weisen wir unseren Zahlungsdienstleister
                  an, die von der VHW UG (haftungsbeschränkt) auf mein/unser Konto gezogenen Lastschriften
                  einzulösen.
                </label>
              </div>

              <!-- ACTIONS -->
              <div class="form-group d-flex justify-content-between w-100">

                <!-- CANCEL -->
                <button [disabled]="isLoadingSubmit === true" [routerLink]="['/user-area/pricing']"
                  class="btn btn-light">
                  zurück
                </button>

                <!-- SUBMIT -->
                <button class="btn btn-primary text-white d-flex justify-content-center align-items-center"
                  [disabled]="form.invalid === true" type="submit"
                  [ngClass]="{'btn.disabled submit--disabled': form.invalid === true, 'bg-primary': form.valid === true}">
                  kostenpflichtig buchen

                  <!-- LOADING INDICATOR -->
                  <span *ngIf="isLoadingSubmit === true" class="spinner-border spinner-border-sm ml-2"></span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>