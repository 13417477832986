<footer class="footer">
  <div class="container">
    <div class="row row-grid align-items-center mb-5">
      <div class="col-lg-6">
        <h3 class="text-primary font-weight-light mb-2">
          Volkshandwerker!
        </h3>
        <h4 class="mb-0 font-weight-light">
          Die große Handwerker Suchmaschine für Deutschland, Österreich und die Schweiz.
        </h4>
      </div>
      <div class="col-lg-6 text-lg-center btn-wrapper">
        <button
          class="btn btn-icon-only twitter-btn rounded-circle mr-3"
          href="https://twitter.com/VOLKSHANDWERK?s=09"
          target="_blank"
        >
          <span class="btn-inner--icon">
            <svg width="16px" height="16px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"/></svg>
          </span>
        </button>
        <button
          class="btn-icon-only rounded-circle btn btn-facebook mr-3"
          href="https://fb.com/Volkshandwerker-1828282967446673"
          target="_blank"
        >
          <span class="btn-inner--icon">
            <i class="fab fa-facebook"> </i>
          </span>
        </button>
        <button
          class="btn btn-icon-only btn-instagram rounded-circle"
          href="https://instagram.com/volkshandwerker"
          target="_blank"
        >
          <span class="btn-inner--icon"> <i class="fa fa-instagram"> </i> </span>
        </button>
      </div>
    </div>
    <hr />

    <div class="row align-items-center justify-content-md-between">
      <div class="col-md-6">
        <div class="copyright">
          © {{ date | date: "yyyy" }}
          <a href="https://volkshandwerker.de" target="_blank">Volkshandwerker</a>
        </div>
      </div>
      <div class="col-md-6">
        <ul class="nav nav-footer justify-content-end">
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/agb']">AGB</a>
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/privacy']">Datenschutz</a>
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/impressum']">Impressum</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</footer>
