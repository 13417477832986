import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BaseComponent } from 'src/app/shared/base/components/base/base-component';
import { Branch } from 'src/app/shared/models/Branch';
import { Company } from 'src/app/shared/models/Company';
import { SubscriptionStatus } from 'src/app/shared/models/SubscriptionStatus';
import { AccountService } from 'src/app/_services';

@Component({
  selector: 'volkshandwerker-branches',
  templateUrl: './branches.component.html',
  styleUrls: ['./branches.component.scss']
})
export class BranchesComponent extends BaseComponent implements OnInit {
  form: FormGroup;

  company: any;
  branches: Branch[] = [];

  allowCreateNewBranch = false;

  SubscriptionStatus = SubscriptionStatus;

  constructor(
    private formBuilder: FormBuilder,
    public accountService: AccountService
  ) {
    super();

    this.form = this.formBuilder.group({
      name: ['', Validators.required],
      postalCode: ['', Validators.required],
      city: ['', Validators.required],
      street: ['', Validators.required],
      houseNumber: ['', Validators.required]
    });
  }

  ngOnInit() {
    this.isLoading = true;

    this.accountService
      .makeGetRequest(`/companies?filters[subscriber][users_permissions_user][id][$eq]=${this.accountService.userValue.user.id}`)
      .then((companies: Company[]) => {
        if (companies[0]) {
          this.company = companies[0];

          this.accountService.makeGetRequest(`/branches?filters[company][id][$eq]=${this.company.id}`)
            .then((branches: Branch[]) => {
              this.branches = branches;

              // Allow user to create a new branch
              if (this.IsNullOrUndefinedOrEmpty(this.branches)
                || this.branches.length < this.accountService.userValue.user.subscriber?.pack.branches) {
                this.allowCreateNewBranch = true;
              }

              this.isLoading = false;
            });
        }
      });
  }

  // Convenience getter for easy access to form fields
  get f() { return this.form.controls; }

  onSubmit() {
    this.isLoadingSubmit = true;

    // Stop here if form is invalid
    if (this.form.invalid) {
      return;
    }

    this.isLoading = true;

    this.accountService.makePostRequest('/branches', this.form.value).then(data => {
      this.isLoading = false;
    },
      error => {
        this.isLoading = false;
      });
  }

  deleteBranch(id) {
  }
}
