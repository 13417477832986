import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContactFormComponent } from './contact-form.component';
import { ReactiveFormsModule } from '@angular/forms';
import { AccountService } from 'src/app/_services';
import { RecaptchaModule } from 'ng-recaptcha';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RecaptchaModule
  ],
  declarations: [ContactFormComponent],
  exports: [ContactFormComponent],
  providers: [AccountService]
})
export class ContactFormModule { }
