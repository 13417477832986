<div class="wrapper">
  <div class="page-header bg-white">
    <div class="page-header-image" style="background-image: url('assets/img/ill/reset.svg');"></div>
    <div class="container">
      <div class="col-lg-5 col-md-8 mx-auto">
        <div class="card bg-secondary shadow border-0">
          <div class="card-header">
            <img alt="Card image" class="card-img" src="assets/img/ill/bg5-1.svg" />

            <h4 class="card-title text-center">Passwort zurücksetzen</h4>
          </div>
          <div class="card-body px-lg-5 py-lg-5">
            <div class="text-center text-muted mb-4">
              <small> Geben Sie Ihr neues Password ein!</small>
            </div>
            <form role="form" (ngSubmit)="onSubmit(password.value, passwordConfirm.value)" [formGroup]="resetPasswordForm">
              <div class="form-group mb-3" [ngClass]="{ 'focused': passwordFocus === true }">
                <div class="input-group input-group-alternative">
                  <div class="input-group-prepend">
                    <span class="input-group-text">
                      <i class="ni ni-email-83"> </i>
                    </span>
                  </div>
                  <input #password class="form-control" placeholder="Passwort" (blur)="passwordFocus = false"
                    (focus)="passwordFocus = true" type="password" formControlName="password" />
                </div>
              </div>
              <div class="form-group mb-3" [ngClass]="{ 'focused': passwordConfirmFocus === true }">
                <div class="input-group input-group-alternative">
                  <div class="input-group-prepend">
                    <span class="input-group-text">
                      <i class="ni ni-email-83"> </i>
                    </span>
                  </div>
                  <input #passwordConfirm class="form-control" placeholder="Passwort bestätigen" (blur)="passwordConfirmFocus = false"
                    (focus)="passwordConfirmFocus = true" type="password" formControlName="passwordConfirm" />
                </div>
              </div>
              <div class="text-center">
                <button class="btn btn-primary my-4" type="submit">
                  Abschicken
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>