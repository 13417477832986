<section class="section-header text-white py-7 pb-lg-12 mb-lg-6">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 col-md-8 mt-7 text-center">
                <h1 class="display-2">Rechnungen</h1>
            </div>
        </div>
    </div>
    <div class="pattern bottom"></div>
</section>
<section class="section section-lg pt-0">
    <div class="container mt-n7 mt-lg-n12 z-2">
        <div class="row justify-content-center">
            <div class="col">
                <div class="card shadow-soft border-light p-4 p-md-5 overflow-auto">
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th style="width: 20%">ID</th>
                                <th style="width: 20%">Name</th>
                                <th style="width: 20%">Datum</th>
                            </tr>
                        </thead>
                        <tbody>

                            <!-- INVOICES -->
                            <tr *ngFor="let invoice of invoices">
                                <td>{{invoice.id}}</td>
                                <td>Abonnement</td>
                                <td>{{invoice.invoice_date}}</td>

                                <!-- ACTIONS -->
                                <td class="flex">
                                    <button class="btn btn-light"
                                        (click)="this.accountService.gotoInvoice(invoice.invoice_id)">PDF</button>
                                </td>
                            </tr>

                            <!-- LOADING INDICATOR -->
                            <tr *ngIf="isLoading === true">
                                <td colspan="6" class="text-center">
                                    <span class="spinner-border spinner-border-lg align-center"></span>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                </div>
            </div>
        </div>
    </div>
</section>