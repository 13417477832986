import { APP_BASE_HREF, CommonModule, registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import { CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { DialogModule } from '@ngneat/dialog';
import { IvyCarouselModule } from 'angular-responsive-carousel';
import { AlertModule } from 'ngx-bootstrap/alert';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TagInputModule } from 'ngx-chips';
import { ShareModule } from 'ngx-sharebuttons';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';
import { ToastNotificationsModule } from 'ngx-toast-notifications';
import { Nl2BrPipeModule } from 'nl2br-pipe';

import { RegisterComponent } from './account/register/register.component';
import { AgbComponent } from './agb/agb.component';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BranchListComponent } from './branch/list/branch-list.component';
import { BranchViewComponent } from './branch/view/branch-view.component';
import { CheckEmailComponent } from './check-email/check-email.component';
import { CheckPaymentComponent } from './check-payment/check-payment.component';
import { CheckResetComponent } from './check-reset/check-reset.component';
import { ConfirmEmailComponent } from './confirm-email/confirm-email.component';
import { ContactComponent } from './contact/contact.component';
import { ForgetPasswordComponent } from './forget-password/forget-password.component';
import { HomeComponent } from './home/home.component';
import { ImpressumComponent } from './impressum/impressum.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ContactFormModule } from './shared/components/contact-form/contact-form.module';
import { CookieDialogModule } from './shared/components/cookie-dialog/cookie-dialog.module';
import { FooterComponent } from './shared/components/footer/footer.component';
import { NavbarComponent } from './shared/components/navbar/navbar.component';
import { PictureUploadComponent } from './shared/components/picture-upload/picture-upload.component';
import { SearchFormModule } from './shared/components/search-form/search-form.module';
import { SafeModule } from './shared/pipes/safe.module';
import { BranchesComponent } from './user-area/branches/branches.component';
import { CreateBranchesComponent } from './user-area/create-branches/create-branches.component';
import { CreateCompanyComponent } from './user-area/create-company/create-company.component';
import { DashboardComponent } from './user-area/dashboard/dashboard.component';
import { EditBranchesComponent } from './user-area/edit-branches/edit-branches.component';
import { PricingComponent } from './user-area/pricing/pricing.component';
import { PaymentComponent } from './payment/payment.component';
import { InvoicesComponent } from './invoices/invoices.component';

registerLocaleData(localeDe);

@NgModule({
  declarations: [
    HomeComponent,
    AgbComponent,
    ImpressumComponent,
    AppComponent,
    NavbarComponent,
    FooterComponent,
    PictureUploadComponent,
    PrivacyComponent,
    CheckEmailComponent,
    CheckPaymentComponent,
    RegisterComponent,
    DashboardComponent,
    BranchesComponent,
    CreateCompanyComponent,
    CreateBranchesComponent,
    EditBranchesComponent,
    PricingComponent,
    BranchListComponent,
    BranchViewComponent,
    ContactComponent,
    ResetPasswordComponent,
    ForgetPasswordComponent,
    CheckResetComponent,
    ConfirmEmailComponent,
    PaymentComponent,
    InvoicesComponent
  ],
  imports: [
    CommonModule,
    SafeModule,
    Nl2BrPipeModule,
    ShareModule,
    ShareButtonsModule,
    ShareIconsModule,
    IvyCarouselModule,
    ToastNotificationsModule,
    HttpClientModule,
    CommonModule,
    FormsModule,
    DialogModule.forRoot(),
    CookieDialogModule,
    ReactiveFormsModule,
    RouterModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    BsDropdownModule.forRoot(),
    ProgressbarModule.forRoot(),
    TooltipModule.forRoot(),
    TimepickerModule.forRoot(),
    PopoverModule.forRoot(),
    CollapseModule.forRoot(),
    TagInputModule,
    TabsModule.forRoot(),
    PaginationModule.forRoot(),
    AlertModule.forRoot(),
    BsDatepickerModule.forRoot(),
    ModalModule.forRoot(),
    SearchFormModule,
    ContactFormModule
  ],
  providers: [
    { provide: APP_BASE_HREF, useValue: '/' },
    { provide: LOCALE_ID, useValue: 'de-DE' }
    // { provide: HTTP_INTERCEPTORS, useClass: FlattenInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
