<div class="app-container">

    <!-- HEADER -->
    <div class="app-container__header">
        <volkshandwerker-navbar></volkshandwerker-navbar>
    </div>

    <!-- CONTENT -->
    <div class="app-container__content">
        <router-outlet></router-outlet>
    </div>

    <!-- FOOTER -->
    <div class="app-container__footer">
        <volkshandwerker-footer *ngIf="router.url != '/register'"></volkshandwerker-footer>
    </div>
</div>