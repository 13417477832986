<div class="wrapper">
  <div class="contactus-3">
    <div class="page-header">
      <img alt="" class="bg-image" src="assets/img/ill/bg_contactus3.svg" />
    </div>
    <div class="container pt-5">
      <div class="row">
        <div class="col-md-12 text-center mb-5">
          <h1 class="display-1">Haben Sie eine Frage?</h1>
          <h3 class="lead">We'd like to talk more about what you need</h3>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-3 col-md-6 col-6">
          <div class="info info-hover">
            <div class="icon icon-shape icon-shape-primary icon-lg shadow rounded-circle text-primary">
              <i class="ni ni-square-pin"> </i>
            </div>
            <h4 class="info-title">Adresse</h4>
            <p class="description px-0">Kelterstraße 2</p>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-6">
          <div class="info info-hover">
            <div class="icon icon-shape icon-shape-primary icon-lg shadow rounded-circle text-primary">
              <i class="ni ni-email-83"> </i>
            </div>
            <h4 class="info-title">Email</h4>
            <p class="description px-0">info@volkshandwerker.de</p>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-6">
          <div class="info info-hover">
            <div class="icon icon-shape icon-shape-primary icon-lg shadow rounded-circle text-primary">
              <i class="ni ni-mobile-button"> </i>
            </div>
            <h4 class="info-title">Telefon</h4>
            <p class="description px-0">07202 7090592</p>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-6">
          <div class="info info-hover">
            <div class="icon icon-shape icon-shape-primary icon-lg shadow rounded-circle text-primary">
              <i class="ni ni-circle-08"> </i>
            </div>
            <h4 class="info-title">Ansprechpartner</h4>
            <p class="description px-0">Volkan Celikten</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="main">
    <div class="container-fluid">
      <div class="row mt-5 mb-4 pt-5">
        <div class="col-md-8 ml-auto mr-auto text-center mt-5">
          <h1 class="title">Tell us more about <b> yourself </b></h1>
          <h4 class="desc">
            Wenn Sie Fragen haben oder einfach nur mal Hallo sagen möchten,
            kontaktieren Sie uns.
          </h4>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 mx-auto">
          <form class="card bg-secondary p-3" id="contact-form" method="post" role="form" [formGroup]="form"
            (ngSubmit)="onSubmit()">
            <div class="card-body">
              <div class="form-group" [ngClass]="{ 'focused': topicFocus === true }">
                <label>Thema</label>
                <div class="input-group input-group-alternative"
                  [ngClass]="{ 'has-danger': submitted && f.topic.errors }">
                  <div class="input-group-prepend">
                    <span class="input-group-text">
                      <i class="fa fa-question-circle"></i>
                    </span>
                  </div>
                  <select class="form-control" formControlName="topic" (blur)="topicFocus = false"
                    (focus)="topicFocus = true" [ngClass]="{ 'is-invalid': submitted && f.topic.errors }">
                    <option>Wählen Sie bitte ein Thema aus</option>
                    <option>Fehlermeldung</option>
                    <option>Konto löschen</option>
                    <option>Koorperationsanfrage</option>
                    <option>Zahlungsprobleme</option>
                    <option>Sonstige Themen</option>
                  </select>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group" [ngClass]="{ 'focused': firstNameFocus === true }">
                    <label> Vorname </label>
                    <div class="input-group input-group-alternative"
                      [ngClass]="{ 'has-danger': submitted && f.firstName.errors }">
                      <div class="input-group-prepend">
                        <span class="input-group-text">
                          <i class="ni ni-circle-08"> </i>
                        </span>
                      </div>
                      <input aria-label="First Name..." class="form-control" placeholder="Vorname"
                        formControlName="firstName" type="text" (blur)="firstNameFocus = false"
                        (focus)="firstNameFocus = true" [ngClass]="{ 'is-invalid': submitted && f.firstName.errors }" />
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group" [ngClass]="{ 'focused': lastNameFocus === true }">
                    <label> Nachname </label>
                    <div class="input-group input-group-alternative"
                      [ngClass]="{ 'has-danger': submitted && f.lastName.errors }">
                      <div class="input-group-prepend">
                        <span class="input-group-text">
                          <i class="ni ni-tag"> </i>
                        </span>
                      </div>
                      <input aria-label="Last Name..." class="form-control" placeholder="Nachname"
                        formControlName="lastName" type="text" (blur)="lastNameFocus = false"
                        (focus)="lastNameFocus = true" [ngClass]="{ 'is-invalid': submitted && f.lastName.errors }" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group" [ngClass]="{ 'focused': emailFocus === true }">
                    <label> Email address </label>
                    <div class="input-group input-group-alternative"
                      [ngClass]="{ 'has-danger': submitted && f.email.errors }">
                      <div class="input-group-prepend">
                        <span class="input-group-text">
                          <i class="ni ni-email-83"> </i>
                        </span>
                      </div>
                      <input class="form-control" placeholder="E-Mail Adresse" formControlName="email" type="text"
                        (blur)="emailFocus = false" (focus)="emailFocus = true"
                        [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group" [ngClass]="{ 'focused': phoneFocus === true }">
                    <label> Telefonnummer </label>
                    <div class="input-group input-group-alternative"
                      [ngClass]="{ 'has-danger': submitted && f.phone.errors }">
                      <div class="input-group-prepend">
                        <span class="input-group-text">
                          <i class="ni ni-mobile-button"> </i>
                        </span>
                      </div>
                      <input class="form-control" placeholder="Telefonnummer" formControlName="phone" type="text"
                        (blur)="phoneFocus = false" (focus)="phoneFocus = true"
                        [ngClass]="{ 'is-invalid': submitted && f.phone.errors }" />
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-group" [ngClass]="{ 'focused': messageFocus === true }">
                <label> Deine Nachricht </label>
                <div class="input-group input-group-alternative"
                  [ngClass]="{ 'has-danger': submitted && f.message.errors }">
                  <textarea class="form-control form-control-alternative" id="message" name="message"
                    (blur)="messageFocus = false" (focus)="messageFocus = true"
                    [ngClass]="{ 'is-invalid': submitted && f.message.errors }" rows="6"
                    placeholder="Hallo Volkshandwerker..." formControlName="message"></textarea>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 ml-auto">
                  <button class="btn btn-primary pull-right" type="submit">
                    Abschicken
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <div class="map" id="map-contactus-3" style="height: 500px;"></div>
</div>