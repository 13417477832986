<!-- DETAILS HEADER -->
<section class="section-header bg-primary text-white pb-5">

  <!-- Title -->
  <div class="d-flex justify-content-center mb-5">
    <div class="col-12 col-sm-10 col-md-9 col-lg-8 text-center">
      <h1 class="display-2 text-white">
        {{ company?.name }}
      </h1>
    </div>
  </div>

</section>

<!-- DETAILS -->
<section class="section bg-white text-white pb-5 pt-0">
  <div *ngIf="isLoading === false; else loading" class="container">

    <!-- DETAILS -->
    <div class="row">

      <!-- MAIN CARD-->
      <div class="col-lg-8">
        <div class="card shadow-soft border-light text-center mt-n5">

          <!-- IMAGE CAROUSEL -->
          <carousel [cellsToShow]="1" [height]="400" [dots]="carouselImages.length > 1"
            [arrows]="carouselImages.length > 1" [loop]="carouselImages.length > 1"
            [counter]="carouselImages.length > 1" [autoplay]="carouselImages.length > 1" [autoplayInterval]="5000"
            [pauseOnHover]="false" [images]="carouselImages">
            <!-- <div *ngFor="let img of branch?.images" class="carousel-cell">
              <img class="d-block w-100" [src]="img.url" style="min-height: 300px; max-height: 500px;">
            </div>
            <div class="carousel-cell" *ngIf="branch?.images?.length == 0">
              <img class="d-block w-100" src="../../../assets/img/placeholders/bilder.svg"
                style="min-height: 300px; max-height: 500px;">
            </div> -->
          </carousel>

          <!-- SHARE SOCIAL MEDIA -->
          <div class="pull-right mt-5 mb-3 px-sm-5 px-3">
            <p class="text-dark">
              Teilen Sie dieses <span class="text-primary">Volkshandwerker Profil</span> auf Ihren Social Media Kanälen
            </p>
            <share-buttons [theme]="'material-dark'"
              [include]="['facebook','twitter','linkedin','pinterest','reddit','telegram','messenger','whatsapp','xing','youtube','sms','copy']"
              [exclude]="['tumblr','mix','viber','vk','line','email','print', 'reddit']" [show]="4" [size]="-1"
              [title]="''" [description]="'Sieh dir mein Volkshandwerker Profil an'"></share-buttons>
          </div>

          <div class="card-body p-sm-5 p-3 py-5 bg-secondary mt-3" style="border-radius: 0 0 0.25rem 0.25rem;">
            <div class="d-flex flex-wrap justify-content-start align-items-center">

              <div>

                <!-- ROW-->
                <div class="d-flex align-items-center branch-detail-row">

                  <!-- NAME -->
                  <h4 class="card-title text-primary text-left display-3 branch-detail-row__name">
                    {{ branch?.name }}
                  </h4>

                  <div class="bg-primary branch-detail-row__batch"
                    style="margin-left: 12px; display: flex; border-radius: 50px;"
                    *ngIf="company?.verificationStatus === VerificationStatus.Verified">
                    <!-- VERIFIED -->
                    <div
                      style="flex: 0 1 auto; display: flex; align-items: center; justify-items: center; padding-top: 2px;"
                      class="batch--verified bg-primary ml-2">
                      <i class="fa fa-check" aria-hidden="true"></i>
                    </div>
                    <div class="batch-text"
                      style="flex: 1 1 auto; display: flex; align-items: center; justify-items: center; padding-right: 12px;">
                      Verifiziert
                    </div>
                  </div>

                </div>

                <!-- CATEGORIES-->
                <div class="card-subtitle text-dark d-flex flex-wrap">
                  <ng-container *ngFor="let category of company?.categories; last as last">
                    <h5 class="pr-2 position-relative text-left">
                      {{ category.name }}
                    </h5>
                  </ng-container>
                </div>
              </div>

            </div>

            <!-- DESCRIPTION-->
            <div class="text-dark text-left mt-5">
              <h6>
                Beschreibung
              </h6>
              <p [innerHTML]="company?.description | safe : 'html'"></p>
            </div>

            <!-- OPENING HOURS-->
            <div class="text-dark text-left mt-5">
              <h6>
                Öffnungszeiten
              </h6>
              <p>
                <ng-container *ngIf="!IsNullOrUndefinedOrEmpty(branch?.WorkingTimes); else noOpeningHours">
                  <div class="oh-list">
                    <div class="oh-item" *ngFor="let wh of branch?.WorkingTimes">
                      <div class="oh-day">
                        {{ wh.day }}
                      </div>
                      <div class="oh-time">
                        {{ wh.start }} - {{ wh.end }}
                      </div>
                    </div>
                  </div>
                </ng-container>
                <ng-template #noOpeningHours>derzeit nicht verfügbar</ng-template>
              </p>
            </div>

            <!-- LEGAL -->
            <div *ngIf="!IsNullOrUndefined(company?.subscriber)" class="mt-5">
              <div class="text-dark text-left">
                <h6>
                  Rechtliche Angaben
                </h6>
                <ng-container *ngIf="moreVisible === true">
                  <p class="m-0">
                    Vertreten durch:
                    {{ company?.subscriber?.firstName }} {{ company?.subscriber?.lastName }}
                  </p>
                  <p class="m-0">
                    Umsatzsteuer ID:
                    {{ company?.subscriber?.vatNumber }}
                  </p>
                </ng-container>
              </div>
              <button (click)="moreVisible = !moreVisible" style="margin-top: 12px;"
                class="btn btn-primary text-white d-flex justify-content-center align-items-center bg-primary"
                type="button">
                {{ moreVisible === true ? 'Weniger anzeigen' : 'Mehr anzeigen' }}
              </button>
            </div>

          </div>
        </div>
      </div>

      <!-- SIDE CARDS -->
      <div class="col-lg-4 text-dark">

        <!-- LOCATION -->
        <div class="profile-card mt-lg-n5">
          <div class="card shadow-soft border-light text-center">

            <!-- MAP -->
            <div class="d-flex justify-content-center align-items-center overflow-hidden"
              style="max-height: 300px; height: 300px; border-radius: .25rem .25rem 0 0;">
              <div id="map"></div>
            </div>


            <!-- COMPANY INFO -->
            <div class="card-body p-sm-5 p-3 py-5">

              <!-- COMPANY LOGO -->
              <div class="profile-image mb-4 d-flex justify-content-start align-items-center"
                *ngIf="!IsNullOrUndefined(company?.logo)">
                <img class="d-block" [src]="company?.logo.url" style="max-height: 80px; width: auto; max-width: 100%;">
              </div>

              <!-- CONTACT -->
              <div class="card-text my-3 text-dark">

                <div>
                  <h6 class="text-left">
                    Kontakt
                  </h6>
                </div>

                <!-- ADDRESS -->
                <div class="d-flex justify-content-start align-items-start">
                  <div class="fas fa-map-marker-alt text-primary mr-2"
                    style="width: 20px; line-height: 1.5; font-size: 1.25rem;"></div>
                  <div>
                    <p class="m-0 text-left">
                      {{ branch?.street }} {{ branch?.houseNumber }}
                    </p>
                    <p class="m-0 text-left">
                      {{ branch?.postalCode }} {{ branch?.city }}
                    </p>
                  </div>
                </div>

                <!-- PHONE -->
                <div class="mt-2" *ngIf="!IsNullOrUndefined(company?.phone)">
                  <a class="d-flex justify-content-start align-items-center" [href]="'tel:' + company?.phone">
                    <div class="fa fa-phone text-primary mr-2" style="width: 20px; font-size: 1.25rem;"></div>
                    <div>
                      {{ company?.phone }}
                    </div>
                  </a>
                </div>

              </div>

              <!-- SOCIAL MEDIA -->
              <div class="d-flex justify-content-start mt-5 flex-wrap" *ngIf="!IsNullOrUndefinedOrEmpty(company?.facebook)
              || !IsNullOrUndefinedOrEmpty(company?.instagram)
              || !IsNullOrUndefinedOrEmpty(company?.twitter)
              || !IsNullOrUndefinedOrEmpty(company?.xing)
              || !IsNullOrUndefinedOrEmpty(company?.youtube)
              || !IsNullOrUndefinedOrEmpty(company?.linkedIn)
              || !IsNullOrUndefinedOrEmpty(company?.website)
              || !IsNullOrUndefinedOrEmpty(company?.whatsappNumber)
              || !IsNullOrUndefinedOrEmpty(company?.telegramUser)">

                <!-- WHATSAPP -->
                <div *ngIf="!IsNullOrUndefinedOrEmpty(branch?.whatsappNumber)">
                  <a [href]="'https://wa.me/' + branch?.whatsappNumber" target="_blank"
                    [title]="branch?.whatsappNumber">
                    <button type="button" class="btn-icon-only btn btn-whatsapp mr-1 mt-1">
                      <span class="btn-inner--icon"><i class="fa fa-whatsapp"></i></span>
                    </button>
                    <!-- <div class="fa fa-whatsapp mr-2" style="width: 20px; font-size: 1.25rem; color: #25D366;"></div> -->
                  </a>
                </div>

                <!-- TELEGRAM -->
                <div *ngIf="!IsNullOrUndefinedOrEmpty(branch?.telegramUser)">
                  <a [href]="'https://telegram.me/' + branch?.telegramUser" target="_blank"
                    [title]="branch?.telegramUser">
                    <button type="button" class="btn-icon-only btn btn-telegram mr-1 mt-1">
                      <span class="btn-inner--icon"><i class="fa fa-telegram"></i></span>
                    </button>
                    <!-- <div class="fa fa-telegram mr-2" style="width: 20px; font-size: 1.25rem; color: #0088CC;"></div> -->
                  </a>
                </div>

                <!-- FACEBOOK -->
                <div *ngIf="!IsNullOrUndefinedOrEmpty(company?.facebook)">
                  <a [href]="company?.facebook" target="_blank">
                    <button type="button" class="btn-icon-only btn btn-facebook mr-1 mt-1">
                      <span class="btn-inner--icon"><i class="fa fa-facebook"></i></span>
                    </button>
                  </a>
                </div>

                <!-- INSTAGRAM -->
                <div *ngIf="!IsNullOrUndefinedOrEmpty(company?.instagram)">
                  <a [href]="company?.instagram" target="_blank">
                    <button type="button" class="btn-icon-only btn btn-instagram mr-1 mt-1">
                      <span class="btn-inner--icon"><i class="fa fa-instagram"></i></span>
                    </button>
                  </a>
                </div>

                <!-- TWITTER -->
                <div *ngIf="!IsNullOrUndefinedOrEmpty(company?.twitter)">
                  <a [href]="company?.twitter" target="_blank">
                    <button type="button" class="btn-icon-only btn twitter-btn mr-1 mt-1">
                      <span class="btn-inner--icon"><svg width="16px" height="16px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"/></svg>                      </span>
                    </button>
                  </a>
                </div>

                <!-- LINKEDIN -->
                <div *ngIf="!IsNullOrUndefinedOrEmpty(company?.linkedIn)">
                  <a [href]="company?.linkedIn" target="_blank">
                    <button type="button" class="btn-icon-only btn btn-linkedin mr-1 mt-1">
                      <span class="btn-inner--icon"><i class="fa fa-linkedin"></i></span>
                    </button>
                  </a>
                </div>

                <!-- Youtube -->
                <div *ngIf="!IsNullOrUndefinedOrEmpty(company?.youtube)">
                  <a [href]="company?.youtube" target="_blank">
                    <button type="button" class="btn-icon-only btn btn-youtube mr-1 mt-1">
                      <span class="btn-inner--icon"><i class="fa fa-youtube"></i></span>
                    </button>
                  </a>
                </div>

                <!-- Xing -->
                <div *ngIf="!IsNullOrUndefinedOrEmpty(company?.xing)">
                  <a [href]="company?.xing" target="_blank">
                    <button type="button" class="btn-icon-only btn btn-xing mr-1 mt-1">
                      <span class="btn-inner--icon"><i class="fa fa-xing"></i></span>
                    </button>
                  </a>
                </div>

                <!-- WEBSITE -->
                <div *ngIf="!IsNullOrUndefinedOrEmpty(company?.website)">
                  <a [href]="company?.website" target="_blank">
                    <button type="button" class="btn-icon-only btn btn-slack mr-1 mt-1">
                      <span class="btn-inner--icon"><i class="fa fa-slack"></i></span>
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- CONTACT -->
        <div class="profile-card">
          <div class="card shadow-soft border-light text-center">

            <!-- CONTACT FORM -->
            <div class="card-body p-sm-5 p-3 py-5">
              <volkshandwerker-contact-form [sendToEmail]="company?.email" [companyName]="branch?.name">
              </volkshandwerker-contact-form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- LOADING SPINNER -->
  <ng-template #loading>
    <div class="d-flex justify-content-center align-items-center container pt-9">
      <div>
        <div class="spinner-border text-primary"></div>
      </div>
    </div>
  </ng-template>
</section>