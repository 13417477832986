<section class="text-white pt-9 mb-4 mb-lg-6">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 col-md-8 text-center">
                <h1 class="display-2 mb-3">Datenschutzerklärung</h1>
            </div>
        </div>
    </div>
</section>
<section>
    <div class="container">
        <h1>1. Vorwort</h1>
        <p>1.1. Datenschutz hat für die VHW UG (haftungsbeschränkt) einen besonders hohen Stellenwert. </p>
        <p>1.2. Diese Datenschutzerklärung klärt Sie über die Art, den Umfang und Zweck der Verarbeitung von
            personenbezogenen
            Daten innerhalb unseres Onlineangebotes und der mit ihm verbundenen Webseiten, Funktionen und
            Inhalte (nachfolgend
            gemeinsam bezeichnet als „Onlineangebot“ oder „Website“) auf. Die Datenschutzerklärung gilt
            unabhängig von den
            verwendeten Domains, Systemen, Plattformen und Geräten (z.B. Desktop oder Mobile) auf denen das
            Onlineangebot
            ausgeführt wird.</p>
        <h1>2. Verantwortlicher</h1>
        <p>Der Verantwortliche im Sinne der Datenschutz-Grundverordnung, sonstiger in den Mitgliedstaaten
            der Europäischen Union
            geltenden Datenschutzgesetze und anderer Bestimmungen mit datenschutzrechtlichem Charakter ist:
        </p>
        <p>VHW UG (haftungsbeschränkt)</p>
        <p>Kelterstraße 2 </p>
        <p>75179 Pforzheim </p>

        <p>E-Mail: info@volkshandwerker.de </p>
        <p>Telefon: 07202 7090592</p>
        <p>Geschäftsführer: Volkan Celikten </p>
        <p>Registergericht: Amtsgericht Mannheim </p>
        <p>Registernummer: HRB 725936 </p>
        <h1>
            3. Begriffsbestimmung
        </h1>
        <p>3.1. Unsere Datenschutzerklärung beruht auf den Begrifflichkeiten, die durch den Europäischen
            Richtlinien- und
            Verordnungsgeber beim Erlass der Datenschutz-Grundverordnung (DSGVO) verwendet wurden. Unsere
            Datenschutzerklärung
            soll sowohl für die Öffentlichkeit als auch für unsere Kunden und Geschäftspartner einfach
            lesbar und verständlich
            sein.</p>
        <p>3.2. Um dies zu gewährleisten, möchten wir vorab die verwendeten Begrifflichkeiten erläutern. Die
            verwendeten
            Begrifflichkeiten, wie z.B. „personenbezogene Daten“ oder deren „Verarbeitung“ sind in Art. 4
            der
            Datenschutzgrundverordnung (DSGVO) definiert.</p>
        <p>3.3. Wir verwenden in dieser Datenschutzerklärung unter anderem die folgenden Begriffe:</p>
        <p class="blue-title"><i>3.3.1.
                Personenbezogene Daten</i>
        </p>
        <p>Personenbezogene Daten sind alle Informationen, die sich auf eine identifizierte oder
            identifizierbare natürliche
            Person (im Folgenden „betroffene Person“) beziehen. Als identifizierbar wird eine natürliche
            Person angesehen, die
            direkt oder indirekt, insbesondere mittels Zuordnung zu einer Kennung wie einem Namen, zu einer
            Kennnummer, zu
            Standortdaten, zu einer Online-Kennung oder zu einem oder mehreren besonderen Merkmalen, die
            Ausdruck der
            physischen, physiologischen, genetischen, psychischen, wirtschaftlichen, kulturellen oder
            sozialen Identität dieser
            natürlichen Person sind, identifiziert werden kann.</p>
        <p class="blue-title"><i>3.3.2.
                Betroffene Person</i></p>
        <p>Betroffene Person ist jede identifizierte oder identifizierbare natürliche Person, deren
            personenbezogene Daten von
            dem für die Verarbeitung Verantwortlichen verarbeitet werden.</p>
        <p class="blue-title"><i>3.3.3.
                Verarbeitung</i></p>
        <p>Verarbeitung ist jeder mit oder ohne Hilfe automatisierter Verfahren ausgeführte Vorgang oder
            jede solche
            Vorgangsreihe im Zusammenhang mit personenbezogenen Daten wie das Erheben, das Erfassen, die
            Organisation, das
            Ordnen, die Speicherung, die Anpassung oder Veränderung, das Auslesen, das Abfragen, die
            Verwendung, die Offenlegung
            durch Übermittlung, Verbreitung oder eine andere Form der Bereitstellung, den Abgleich oder die
            Verknüpfung, die
            Einschränkung, das Löschen oder die Vernichtung.</p>
        <p class="blue-title"><i>3.3.4.
                Einschränkung der
                Verarbeitung</i></p>
        <p>Einschränkung der Verarbeitung ist die Markierung gespeicherter personenbezogener Daten mit dem
            Ziel, ihre künftige
            Verarbeitung einzuschränken.</p>
        <p class="blue-title"><i>3.3.5.
                Profiling</i></p>
        <p>Profiling ist jede Art der automatisierten Verarbeitung personenbezogener Daten, die darin
            besteht, dass diese
            personenbezogenen Daten verwendet werden, um bestimmte persönliche Aspekte, die sich auf eine
            natürliche Person
            beziehen, zu bewerten, insbesondere, um Aspekte bezüglich Arbeitsleistung, wirtschaftlicher
            Lage, Gesundheit,
            persönlicher Vorlieben, Interessen, Zuverlässigkeit, Verhalten, Aufenthaltsort oder Ortswechsel
            dieser natürlichen
            Person zu analysieren oder vorherzusagen.</p>
        <p class="blue-title"><i>3.3.6.
                Pseudonymisierung</i></p>
        <p>Pseudonymisierung ist die Verarbeitung personenbezogener Daten in einer Weise, auf welche die
            personenbezogenen Daten
            ohne Hinzuziehung zusätzlicher Informationen nicht mehr einer spezifischen betroffenen Person
            zugeordnet werden
            können, sofern diese zusätzlichen Informationen gesondert aufbewahrt werden und technischen und
            organisatorischen
            Maßnahmen unterliegen, die gewährleisten, dass die personenbezogenen Daten nicht einer
            identifizierten oder
            identifizierbaren natürlichen Person zugewiesen werden.</p>
        <p class="blue-title"><i>3.3.7.
                Verantwortlicher oder für
                die Verarbeitung Verantwortlicher</i></p>
        <p>Verantwortlicher oder für die Verarbeitung Verantwortlicher ist die natürliche oder juristische
            Person, Behörde,
            Einrichtung oder andere Stelle, die allein oder gemeinsam mit anderen über die Zwecke und Mittel
            der Verarbeitung
            von personenbezogenen Daten entscheidet. Sind die Zwecke und Mittel dieser Verarbeitung durch
            das Unionsrecht oder
            das Recht der Mitgliedstaaten vorgegeben, so kann der Verantwortliche beziehungsweise können die
            bestimmten
            Kriterien seiner Benennung nach dem Unionsrecht oder dem Recht der Mitgliedstaaten vorgesehen
            werden.</p>
        <p class="blue-title"><i>3.3.8.
                Auftragsverarbeiter</i></p>
        <p>Auftragsverarbeiter ist eine natürliche oder juristische Person, Behörde, Einrichtung oder andere
            Stelle, die
            personenbezogene Daten im Auftrag des Verantwortlichen verarbeitet.</p>
        <p class="blue-title"><i>3.3.9.
                Empfänger</i></p>
        <p>Empfänger ist eine natürliche oder juristische Person, Behörde, Einrichtung oder andere Stelle,
            der personenbezogene
            Daten offengelegt werden, unabhängig davon, ob es sich bei ihr um einen Dritten handelt oder
            nicht. Behörden, die im
            Rahmen eines bestimmten Untersuchungsauftrags nach dem Unionsrecht oder dem Recht der
            Mitgliedstaaten möglicherweise
            personenbezogene Daten erhalten, gelten jedoch nicht als Empfänger.</p>
        <p class="blue-title"><i>3.3.10.
                Dritter</i></p>
        <p>Dritter ist eine natürliche oder juristische Person, Behörde, Einrichtung oder andere Stelle
            außer der betroffenen
            Person, dem Verantwortlichen, dem Auftragsverarbeiter und den Personen, die unter der
            unmittelbaren Verantwortung
            des Verantwortlichen oder des Auftragsverarbeiters befugt sind, die personenbezogenen Daten zu
            verarbeiten.</p>
        <p class="blue-title"><i>3.3.11.
                Einwilligung</i></p>
        <p>Einwilligung ist jede von der betroffenen Person freiwillig für den bestimmten Fall in
            informierter Weise und
            unmissverständlich abgegebene Willensbekundung in Form einer Erklärung oder einer sonstigen
            eindeutigen
            bestätigenden Handlung, mit der die betroffene Person zu verstehen gibt, dass sie mit der
            Verarbeitung der sie
            betreffenden personenbezogenen Daten einverstanden ist.
        <p>
        <p class="blue-title"><i>3.3.12.
                Statistische
                Reichweitenmessung</i></p>
        <p>Die statistische Reichweitenmessung umfasst Verfahren, die in erster Linie der Analyse der
            Nutzung der Website zu
            dienen bestimmt sind. Hierzu zählen beispielsweise Angaben wie Verweildauer, verwendetes
            Endgerät,
            Spracheinstellungen, Herkunft, Region, Standort oder auch Nutzeraktionen. Dieses kann
            insbesondere durch
            Technologien wie Java Script, Cookies sowie Zählpixel erfolgen.</p>
        <p class="blue-title"><i>3.3.13.
                Tracking</i></p>
        <p>Das Tracking umfasst Verfahren, bei welchen in erster Linie die Auswertung der Besucher im
            Vordergrund stehen.
            Hierunter fallen insbesondere all jene Verfahren, die eine Identifikation des Nutzers sowie eine
            Analyse des
            Verhaltens über einen längeren Zeitraum ermöglichen, um dem Nutzer ggfs. persönliche Merkmale
            oder Interessen
            zuzuordnen (Profiling). Anhand solcher ggfs. gebildeter Nutzerprofile können hierbei
            Werbemaßnahmen gezielter und
            möglichst personalisiert an einen bestimmten Nutzer ausgespielt werden.</p>
        <h1>
            4. Allgemeines zur Datenverarbeitung
        </h1>
        <p style="font-size:14px; color:#0C50DA; font-weight:bold;">4.1. Umfang der Verarbeitung
            personenbezogener Daten</p>
        <p>Wir erheben und verwenden personenbezogene Daten unserer Nutzer grundsätzlich nur, soweit dies
            zur Bereitstellung
            einer funktionsfähigen Website sowie unserer Inhalte und Leistungen erforderlich ist. Die
            Erhebung und Verwendung
            personenbezogener Daten unserer Nutzer erfolgt regelmäßig nur nach Einwilligung des Nutzers.
            Eine Ausnahme gilt in
            solchen Fällen, in denen eine vorherige Einholung einer Einwilligung aus tatsächlichen Gründen
            nicht möglich ist und
            die Verarbeitung der Daten durch gesetzliche Vorschriften gestattet ist. </p>
        <p style="font-size:14px; color:#0C50DA; font-weight:bold;">4.2. Rechtsgrundlage für die
            Verarbeitung personenbezogener
            Daten</p>
        <p>4.2.1. Soweit wir für Verarbeitungsvorgänge personenbezogener Daten eine Einwilligung der
            betroffenen Person
            einholen, dient Art. 6 Abs. 1 S. 1 lit. a EU-Datenschutzgrundverordnung (DSGVO) als
            Rechtsgrundlage.</p>
        <p>4.2.2. Bei der Verarbeitung von personenbezogenen Daten, die zur Erfüllung eines Vertrages,
            dessen Vertragspartei die
            betroffene Person ist, erforderlich ist, dient Art. 6 Abs. 1 S. 1 lit. b DSGVO als
            Rechtsgrundlage. Dies gilt auch
            für Verarbeitungsvorgänge, die zur Durchführung vorvertraglicher Maßnahmen erforderlich sind.
        </p>
        <p>4.2.3. Soweit eine Verarbeitung personenbezogener Daten zur Erfüllung einer rechtlichen
            Verpflichtung erforderlich
            ist, der unser Unternehmen unterliegt, dient Art. 6 Abs. 1 S. 1 lit. c DSGVO als
            Rechtsgrundlage.</p>
        <p>4.2.4. Für den Fall, dass lebenswichtige Interessen der betroffenen Person oder einer anderen
            natürlichen Person eine
            Verarbeitung personenbezogener Daten erforderlich machen, dient Art. 6 Abs. 1 S. 1 lit. d DSGVO
            als Rechtsgrundlage.
        </p>
        <p>4.2.5. Ist die Verarbeitung zur Wahrung eines berechtigten Interesses unseres Unternehmens oder
            eines Dritten
            erforderlich und überwiegen die Interessen, Grundrechte und Grundfreiheiten des Betroffenen das
            erstgenannte
            Interesse nicht, so dient Art. 6 Abs. 1 S. 1 lit. f DSGVO als Rechtsgrundlage für die
            Verarbeitung. </p>
        <p style="font-size:14px; color:#0C50DA; font-weight:bold;">4.3. Datenlöschung und Speicherdauer</p>
        <p>4.3.1. Die personenbezogenen Daten der betroffenen Person werden gelöscht oder gesperrt, sobald
            der Zweck der
            Speicherung entfällt. Eine Speicherung kann darüber hinaus erfolgen, wenn dies durch den
            europäischen oder
            nationalen Gesetzgeber in unionsrechtlichen Verordnungen, Gesetzen oder sonstigen Vorschriften,
            denen der
            Verantwortliche unterliegt, vorgesehen wurde.</p>
        <p>4.3.2. Eine Sperrung oder Löschung der Daten erfolgt auch dann, wenn eine durch die genannten
            Normen vorgeschriebene
            Speicherfrist abläuft, es sei denn, dass eine Erforderlichkeit zur weiteren Speicherung der
            Daten für einen
            Vertragsabschluss oder eine Vertragserfüllung besteht.</p>
        <h1>5. Technische und organisatorische Maßnahmen</h1>
        <p>5.1. Um zu gewährleisten, dass personenbezogene Daten bei der elektronischen Übertragung, während
            des Transports oder
            der Speicherung auf Datenträgern nicht unbefugt gelesen, kopiert, verändert oder entfernt werden
            können nutzen wir
            gem. Art. 9 DSGVO ein Verschlüsselungsverfahren nach dem Stand der Technik.</p>
        <p>5.2. Diese Seite nutzt aus Gründen der Sicherheit und zum Schutz der Übertragung vertraulicher
            Inhalte, wie zum
            Beispiel der Anfragen, die Sie an unser System senden, eine TLS-Verschlüsselung (Transport Layer
            Security). Daten,
            die Sie an unser System übermitteln können hierdurch nicht ohne weiteres von Dritten mitgelesen
            werden.</p>
        <p>5.3. Eine verschlüsselte Verbindung erkennen Sie daran, dass die Adresszeile des Browsers von
            "http://" auf
            "https://" wechselt und an dem Schloss-Symbol in Ihrer Browserzeile.</p>
        <h1>6. Bereitstellung der Website und Erstellung von Logfiles</h1>
        <p style="font-size:14px; color:#0C50DA; font-weight:bold;">6.1. Beschreibung und Umfang der
            Datenverarbeitung</p>
        <p>6.1.1. Bei jedem Aufruf unserer Internetseite erfasst unser System automatisiert Daten und
            Informationen vom
            Computersystem des aufrufenden Rechners. </p>
        <p>6.1.2. Folgende Daten werden hierbei erhoben:</p>
        <ul><span class="red-marker">
                <li> Informationen über den Browsertyp und die verwendete Version</li>
                <li> das Betriebssystem des Nutzers</li>
                <li> den Internet-Service-Provider des Nutzers</li>
                <li> die IP-Adresse des Nutzers</li>
                <li> das Datum und die Uhrzeit des Zugriffs</li>
                <li> Websites, von denen das System des Nutzers auf unsere Internetseite gelangt </li>
                <li> Websites, die vom System des Nutzers über unsere Website aufgerufen werden</li>
            </span></ul>
        <p>6.1.3. Die Daten werden ebenfalls in den Logfiles unseres Systems gespeichert. Eine Speicherung
            dieser Daten zusammen
            mit anderen personenbezogenen Daten des Nutzers findet nicht statt.</p>
        <p style="font-size:14px; color:#0C50DA; font-weight:bold;">6.2. Rechtsgrundlage für die
            Datenverarbeitung</p>
        <p>6.3. Rechtsgrundlage für die vorübergehende Speicherung der Daten und der Logfiles ist Art. 6
            Abs. 1 S. 1 lit. f
            DSGVO.</p>
        <p>6.4. Zweck der Datenverarbeitung</p>
        <p>6.5. Die vorübergehende Speicherung der IP-Adresse durch das System ist notwendig, um eine
            Auslieferung der Website
            an den Rechner des Nutzers zu ermöglichen. Hierfür muss die IP-Adresse des Nutzers für die Dauer
            der Sitzung
            gespeichert bleiben. </p>
        <p>6.6. Die Speicherung in Logfiles erfolgt, um die Funktionsfähigkeit der Website sicherzustellen.
            Zudem dienen uns die
            Daten zur Optimierung der Website und zur Sicherstellung der Sicherheit unserer
            informationstechnischen Systeme.
            Eine Auswertung der Daten zu Marketingzwecken findet in diesem Zusammenhang nicht statt.</p>
        <p>6.7. In diesen Zwecken liegt auch unser berechtigtes Interesse an der Datenverarbeitung nach Art.
            6 Abs. 1 S. 1 lit.
            f DSGVO.</p>
        <p style="font-size:14px; color:#0C50DA; font-weight:bold;">6.8. Dauer der Speicherung</p>
        <p>6.9. Die Daten werden gelöscht, sobald sie für die Erreichung des Zweckes ihrer Erhebung nicht
            mehr erforderlich
            sind. Im Falle der Erfassung der Daten zur Bereitstellung der Website ist dies der Fall, wenn
            die jeweilige Sitzung
            beendet ist. </p>
        <p>6.10. Im Falle der Speicherung der Daten in Logfiles ist dies nach spätestens sieben Tagen der
            Fall. Eine
            darüberhinausgehende Speicherung ist möglich. In diesem Fall werden die IP-Adressen der Nutzer
            gelöscht oder
            verfremdet, sodass eine Zuordnung des aufrufenden Clients nicht mehr möglich ist.</p>
        <p style="font-size:14px; color:#0C50DA; font-weight:bold;">6.11. Widerspruchs- und
            Beseitigungsmöglichkeit</p>
        <p>Die Erfassung der Daten zur Bereitstellung der Website und die Speicherung der Daten in Logfiles
            ist für den Betrieb
            der Internetseite zwingend erforderlich. Es besteht folglich seitens des Nutzers keine
            Widerspruchsmöglichkeit. </p>
        <h1>7. Verwendung von Cookies</h1>
        <p style="font-size:14px; color:#0C50DA; font-weight:bold;">7.1. Beschreibung und Umfang der
            Datenverarbeitung</p>
        <p>7.1.1. Wir nutzen auf Grundlage unserer berechtigten Interessen auf dieser Internetseite sog.
            Cookies. Bei Cookies
            handelt es sich um Textdateien, die im Internetbrowser bzw. vom Internetbrowser auf dem
            Computersystem des Nutzers
            gespeichert werden. Ruft ein Nutzer eine Website auf, so kann ein Cookie auf dem Betriebssystem
            des Nutzers
            gespeichert werden. Dieses Cookie enthält eine charakteristische Zeichenfolge, die eine
            eindeutige Identifizierung
            des Browsers beim erneuten Aufrufen der Website ermöglicht. </p>
        <p><u>7.1.2. Technisch notwendige Cookies</u></p>
        <p>7.1.2.1. Wir setzen Cookies ein, um unsere Website nutzerfreundlicher zu gestalten. Einige
            Elemente unserer
            Internetseite erfordern es, dass der aufrufende Browser auch nach einem Seitenwechsel
            identifiziert werden kann.</p>
        <p>7.1.2.2. In den Cookies können dabei folgende Daten gespeichert und übermittelt:</p>
        <ul>
            <li> Session-Cookies, die bestimmte Einstellungen des Nutzers speichern, z.B.:
                <ul>
                    <li> Warenkorbinhalte</li>
                    <li> Spracheinstellungen</li>
                    <li> Merken von Suchbegriffen</li>
                    <li> Log-In-Daten</li>
                </ul>
            <li> Opt-Out-Cookies, mit der Information, ob der Nutzer aktiv in das Setzen technisch
                notwendiger wie nicht
                notwendiger Cookies eingewilligt hat.</li>
            <li> Test-Cookies, mit der Information, ob der Nutzer in seinem Browser das Setzen von Cookies
                erlaubt</li>
            <li> Cookies, zu Analyse-, Werbe-, und Marketingzwecke.</li>
        </ul>
        <p>7.1.2.3. Beim Aufruf unserer Website werden die Nutzer durch ein Infobanner über die Verwendung
            von technisch
            notwendigen Cookies informiert und auf diese Datenschutzerklärung verwiesen. Es erfolgt in
            diesem Zusammenhang auch
            ein Hinweis darauf, wie die Speicherung von Cookies in den Browsereinstellungen unterbunden
            werden kann.</p>
        <p>7.1.3. <u>Technisch nicht notwendige Cookies</u></p>
        <p>7.1.3.1. Sofern wir über technisch notwendige Cookies hinaus, weitere Cookies verwenden, werden
            Sie hierüber auf der
            Webseite informiert. </p>
        <p style="font-size:14px; color:#0C50DA; font-weight:bold;">7.2. Rechtsgrundlage für die
            Datenverarbeitung </p>
        <p>7.2.1. Die Rechtsgrundlage für die Verarbeitung personenbezogener Daten unter Verwendung von
            Cookies zu Analyse-,
            Marketing- und/oder Trackingzwecken ist bei Vorliegen einer diesbezüglichen Einwilligung des
            Nutzers Art. 6 Abs. 1
            S. 1 lit. a DSGVO. </p>
        <p>7.2.2. Die Rechtsgrundlage für die Verarbeitung personenbezogener Daten unter Verwendung
            technisch notwendiger
            Cookies ist bei Vorliegen einer diesbezüglichen Einwilligung des Nutzers Art. 6 Abs. 1 S. 1 lit.
            a DSGVO. Liegt eine
            Einwilligung des Nutzers nicht vor, so ist Rechtsgrundlage Art. 6 Abs. 1 S. 1 lit. f DSGVO.</p>
        <p style="font-size:14px; color:#0C50DA; font-weight:bold;">7.3. Zweck der Datenverarbeitung</p>
        <p>7.3.1. Die Verwendung der technisch notwendigen Cookies erfolgt zu dem Zweck, die Nutzung von
            Websites für die Nutzer
            zu vereinfachen. Einige Funktionen unserer Internetseite können ohne den Einsatz von Cookies
            nicht angeboten werden.
            Für diese ist es erforderlich, dass der Browser auch nach einem Seitenwechsel wiedererkannt
            wird. Die durch
            technisch notwendige Cookies erhobenen Nutzerdaten werden nicht zur Erstellung von
            Nutzerprofilen verwendet.</p>
        <p style="font-size:14px; color:#0C50DA; font-weight:bold;">7.4. Dauer der Speicherung,
            Widerspruchs- und
            Beseitigungsmöglichkeit</p>
        <p>7.4.1. Cookies werden auf dem Rechner des Nutzers gespeichert und von diesem an unserer Seite
            übermittelt. Daher
            haben Sie als Nutzer auch die volle Kontrolle über die Verwendung von Cookies. Durch eine
            Änderung der Einstellungen
            in Ihrem Internetbrowser können Sie die Übertragung von Cookies deaktivieren oder einschränken.
            Bereits gespeicherte
            Cookies können jederzeit gelöscht werden. Dies kann auch automatisiert erfolgen. Werden Cookies
            für unsere Website
            deaktiviert, können möglicherweise nicht mehr alle Funktionen der Website vollumfänglich genutzt
            werden.</p>
        <p>7.4.1.1. Beim Aufruf unserer Website werden die Nutzer durch ein Infobanner über die Verwendung
            von Cookies
            informiert und der Nutzer gefragt, ob dieser dem Setzen auch der benannten Cookies zustimmt. Der
            Nutzer kann frei
            entscheiden, ob er eine Auswahl tätigt sowie, ob eine Einwilligung erfolgt. Erteilt der Nutzer
            keine Einwilligung,
            so werden keine / nur technisch notwendige Cookies gesetzt und eine weitergehende Speicherung
            von Cookies findet
            nicht statt.</p>
        <h1>8. Newsletter
        </h1>
        <p style="font-size:14px; color:#0C50DA; font-weight:bold;">8.1. Beschreibung und Umfang der
            Datenverarbeitung</p>
        <p>8.1.1. Wir bieten auf Grundlage unserer berechtigten Interessen auf dieser Internetseite unseren
            Nutzern die
            Möglichkeit, einen kostenfreien Newsletter zu abonnieren.</p>
        <p>8.1.2. Wir versenden Newsletter, E-Mails und weitere elektronische Benachrichtigungen mit
            werblichen Informationen
            (nachfolgend „Newsletter“) nur mit der Einwilligung der Empfänger oder einer gesetzlichen
            Erlaubnis. Dabei werden
            bei der Anmeldung zum Newsletter die Daten aus der Eingabemaske an uns übermittelt.</p>
        <p>8.1.3. Sofern im Rahmen einer Anmeldung zum Newsletter dessen Inhalte konkret umschrieben werden,
            sind sie für die
            Einwilligung der Nutzer maßgeblich. <span class="red-marker">Im Übrigen
                enthalten unsere
                Newsletter Informationen zu unseren Produkten, Angeboten, Aktionen und unserem
                Unternehmen.</span></p>
        <p>8.1.4. Es werden hierbei nachfolgende Daten des Nutzers erhoben: </p>
        <ul>
            <li> <span class="red-marker">die E-Mail-Adresse </span></li>
            <li> <span class="red-marker">der Vor- und Nachname</span></li>
        </ul>
        <p>8.1.5. Zudem werden folgende Daten bei der Anmeldung erhoben: </p>
        <ul>
            <li>
                <span class="red-marker">IP-Adresse des aufrufenden Rechners</span>
            </li>
            <li>
                <span class="red-marker">Datum und Uhrzeit der Registrierung</span>
            </li>
        </ul>
        <p>8.1.6. Für die Verarbeitung der Daten wird im Rahmen des Anmeldevorgangs Ihre Einwilligung
            eingeholt und auf diese
            Datenschutzerklärung verwiesen.</p>
        <p>8.1.7. Die Anmeldung zu unserem Newsletter erfolgt in einem sog. Double-Opt-In-Verfahren. D.h.
            Sie erhalten nach der
            Anmeldung eine E-Mail, in der Sie um die Bestätigung Ihrer Anmeldung gebeten werden. Diese
            Bestätigung ist
            notwendig, damit sich niemand mit fremden E-Mailadressen anmelden kann. Die Anmeldungen zum
            Newsletter werden
            protokolliert, um den Anmeldeprozess entsprechend den rechtlichen Anforderungen nachweisen zu
            können. Hierzu gehört
            die Speicherung des Anmelde- und des Bestätigungszeitpunkts, als auch der IP-Adresse. Ebenso
            werden die Änderungen
            Ihrer bei dem Versanddienstleister gespeicherten Daten protokolliert.</p>
        <p>8.1.8. Wenn Sie auf unserer Internetseite Waren oder Dienstleistungen erwerben und hierbei Ihre
            E-Mail-Adresse
            hinterlegen, kann diese in der Folge durch uns für den Versand eines Newsletters verwendet
            werden. In einem solchen
            Fall wird über den Newsletter ausschließlich Direktwerbung für eigene ähnliche Waren oder
            Dienstleistungen
            versendet.</p>
        <p>8.1.9. Es erfolgt im Zusammenhang mit der Datenverarbeitung für den Versand von Newslettern keine
            Weitergabe der
            Daten an Dritte. Die Daten werden ausschließlich für den Versand des Newsletters verwendet.</p>
        <p style="font-size:14px; color:#0C50DA; font-weight:bold;">8.2. Rechtsgrundlage für die
            Datenverarbeitung</p>
        <p>8.2.1. Rechtsgrundlage für die Verarbeitung der Daten nach Anmeldung zum Newsletters durch den
            Nutzer ist bei
            Vorliegen einer Einwilligung des Nutzers Art. 6 Abs. 1 S. 1 lit. a DSGVO.</p>
        <p>8.2.2. Rechtsgrundlage für den Versand des Newsletters infolge des Verkaufs von Waren oder
            Dienstleistungen ist § 7
            Abs. 3 UWG.</p>
        <p>8.2.3. Die Rechtsgrundlage der statistischen Erhebungen und Analysen ist bei Vorliegen einer
            diesbezüglichen
            Einwilligung des Nutzers Art. 6 Abs. 1 S. 1 lit. a DSGVO. Liegt eine Einwilligung des Nutzers
            nicht vor, so ist
            Rechtsgrundlage Art. 6 Abs. 1 S. 1 lit. f DSGVO.</p>
        <p style="font-size:14px; color:#0C50DA; font-weight:bold;">8.3. Zweck der Datenverarbeitung</p>
        <p>8.3.1. Um sich für den Newsletter anzumelden, reicht es aus, wenn Sie Ihre E-Mailadresse angeben.
            Die Erhebung der
            E-Mail-Adresse des Nutzers dient dazu, den Newsletter zuzustellen. Optional bitten wir Sie einen
            Namen, zwecks
            persönlicher Ansprache im Newsletters anzugeben.</p>
        <p>8.3.2. Die Erhebung sonstiger personenbezogener Daten im Rahmen des Anmeldevorgangs dient dazu,
            einen Missbrauch der
            Dienste oder der verwendeten E-Mail-Adresse zu verhindern.</p>
        <p>8.3.3. Zweck der statistischen Erhebungen ist auf den Einsatz eines nutzerfreundlichen sowie
            sicheren
            Newsletter-Systems gerichtet, das sowohl unseren geschäftlichen Interessen dient, als auch den
            Erwartungen der
            Nutzer entspricht.</p>
        <p style="font-size:14px; color:#0C50DA; font-weight:bold;">8.4. Dauer der Speicherung</p>
        <p>8.4.1. Die Daten werden gelöscht, sobald sie für die Erreichung des Zweckes ihrer Erhebung nicht
            mehr erforderlich
            sind. Die E-Mail-Adresse des Nutzers wird demnach solange gespeichert, wie das Abonnement des
            Newsletters aktiv ist.
        </p>
        <p>8.4.2. Die sonstigen im Rahmen des Anmeldevorgangs erhobenen personenbezogenen Daten werden in
            der Regel nach einer
            Frist von sieben Tagen gelöscht.</p>
        <p style="font-size:14px; color:#0C50DA; font-weight:bold;">8.5. Widerspruchs- und
            Beseitigungsmöglichkeit</p>
        <p>8.5.1. Das Abonnement des Newsletters kann durch den betroffenen Nutzer jederzeit gekündigt
            werden. Zu diesem Zweck
            findet sich in jedem Newsletter ein entsprechender Link. </p>
        <p>8.5.2. Hierdurch wird ebenfalls ein Widerruf der Einwilligung der Speicherung der während des
            Anmeldevorgangs
            erhobenen personenbezogenen Daten ermöglicht.</p>
        <p>8.5.3. Zugleich erlischt Ihre Einwilligung in die statistischen Analysen. Ein getrennter Widerruf
            des Versandes durch
            den Versanddienstleister oder die statistische Auswertung ist leider nicht möglich.</p>
        <p>8.5.4. Wenn die Nutzer sich nur zum Newsletter angemeldet und diese Anmeldung gekündigt haben,
            werden ihre
            personenbezogenen Daten gelöscht.</p>
        <h1>
            9. Registrierung
        </h1>
        <p style="font-size:14px; color:#0C50DA; font-weight:bold;">9.1. Beschreibung und Umfang der
            Datenverarbeitung</p>
        <p>9.1.1. Wir bieten auf Grundlage unserer berechtigten Interessen auf dieser Internetseite Nutzern
            die Möglichkeit,
            sich unter Angabe personenbezogener Daten zu registrieren. Die Daten werden dabei in eine
            Eingabemaske eingegeben
            und an uns übermittelt und gespeichert. Eine Weitergabe der Daten an Dritte findet nicht statt.
        </p>
        <p>9.1.2. Folgende Daten werden im Rahmen des Registrierungsprozesses erhoben:</p>
        <ul><span class="red-marker">
                <li> die E-Mail-Adresse </li>
                <li> der Vor- und Nachname</li>
                <li> die Anschrift</li>
                <li> die Telefonnummer</li>
                <li> […]</li>
            </span></ul>
        <p>9.1.3. Im Zeitpunkt der Registrierung werden zudem folgende Daten gespeichert:</p>
        <ul><span class="red-marker">
                <li> IP-Adresse des aufrufenden Rechners</li>
                <li> Datum und Uhrzeit der Registrierung</li>
            </span></ul>
        <p>9.1.4. Im Rahmen des Registrierungsprozesses wird eine Einwilligung des Nutzers zur Verarbeitung
            dieser Daten
            eingeholt. </p>
        <p style="font-size:14px; color:#0C50DA; font-weight:bold;">9.2. Rechtsgrundlage für die
            Datenverarbeitung </p>
        <p>9.2.1. Rechtsgrundlage für die Verarbeitung der Daten ist bei Vorliegen einer Einwilligung des
            Nutzers Art. 6 Abs. 1
            S. 1 lit. a DSGVO.</p>
        <p>9.2.2. Dient die Registrierung der Erfüllung eines Vertrages, dessen Vertragspartei der Nutzer
            ist oder der
            Durchführung vorvertraglicher Maßnahmen, so ist zusätzliche Rechtsgrundlage für die Verarbeitung
            der Daten Art. 6
            Abs. 1 S. 1 lit. b DSGVO.</p>
        <p style="font-size:14px; color:#0C50DA; font-weight:bold;">9.3. Zweck der Datenverarbeitung</p>
        <p>9.3.1. Eine Registrierung des Nutzers ist für das Bereithalten bestimmter Inhalte und Leistungen
            auf unserer Website
            erforderlich.</p>
        <p>9.3.2. Eine Registrierung des Nutzers ist zur Erfüllung eines Vertrages mit dem Nutzer oder zur
            Durchführung
            vorvertraglicher Maßnahmen erforderlich.</p>
        <p style="font-size:14px; color:#0C50DA; font-weight:bold;">9.4. Dauer der Speicherung</p>
        <p>9.4.1. Die Daten werden gelöscht, sobald sie für die Erreichung des Zweckes ihrer Erhebung nicht
            mehr erforderlich
            sind.</p>
        <p>9.4.2. Dies ist für die während des Registrierungsvorgangs erhobenen Daten der Fall, wenn die
            Registrierung auf
            unserer Internetseite aufgehoben oder abgeändert wird.</p>
        <p>9.4.3. Dies ist für die während des Registrierungsvorgangs zur Erfüllung eines Vertrags oder zur
            Durchführung
            vorvertraglicher Maßnahmen dann der Fall, wenn die Daten für die Durchführung des Vertrages
            nicht mehr erforderlich
            sind. Auch nach Abschluss des Vertrags kann eine Erforderlichkeit, personenbezogene Daten des
            Vertragspartners zu
            speichern, bestehen, um vertraglichen oder gesetzlichen Verpflichtungen nachzukommen.</p>
        <p>9.4.4. Dauerschuldverhältnisse erfordern die Speicherung der personenbezogenen Daten während der
            Vertragslaufzeit.
            Zudem müssen Gewährleistungsfristen beachtet werden und die Speicherung von Daten für
            steuerliche Zwecke. Welche
            Speicherfristen hierbei einzuhalten sind, lässt sich nicht pauschal festlegen, sondern muss für
            die jeweils
            geschlossenen Verträge und Vertragsparteien im Einzelfall ermittelt werden.</p>
        <p style="font-size:14px; color:#0C50DA; font-weight:bold;">9.5. Widerspruchs- und
            Beseitigungsmöglichkeit</p>
        <p>9.5.1. Als Nutzer haben sie jederzeit die Möglichkeit, die Registrierung aufzulösen. Die über Sie
            gespeicherten Daten
            können Sie jederzeit abändern lassen. </p>
        <p>9.5.2. Sind die Daten zur Erfüllung eines Vertrages oder zur Durchführung vorvertraglicher
            Maßnahmen erforderlich,
            ist eine vorzeitige Löschung der Daten nur möglich, soweit nicht vertragliche oder gesetzliche
            Verpflichtungen einer
            Löschung entgegenstehen.</p>
        <h1>
            10. Kontaktformular und E-Mail-Kontakt
        </h1>
        <p style="font-size:14px; color:#0C50DA; font-weight:bold;">10.1. Beschreibung und Umfang der
            Datenverarbeitung</p>
        <p>10.1.1. Wir nutzen auf Grundlage unserer berechtigten Interessen auf dieser Internetseite ein
            Kontaktformular,
            welches für die elektronische Kontaktaufnahme genutzt werden kann. Nimmt ein Nutzer diese
            Möglichkeit wahr, so
            werden die in der Eingabemaske eingegeben Daten an uns übermittelt und gespeichert.</p>
        <p>10.1.2. Diese Daten sind:</p>
        <ul><span class="red-marker">
                <li> die E-Mail-Adresse </li>
                <li> der Vor- und Nachname</li>
                <li> die Anschrift</li>
                <li> die Telefonnummer</li>
                <li> […]</li>
            </span></ul>
        <p>10.1.3. Im Zeitpunkt der Absendung der Nachricht werden zudem folgende Daten gespeichert:</p>
        <ul><span class="red-marker">
                <li> IP-Adresse des aufrufenden Rechners
                <li>
                <li> Datum und Uhrzeit der Registrierung</li>
            </span></ul>
        <p>10.1.4. Für die Verarbeitung der Daten wird im Rahmen des Absendevorgangs Ihre Einwilligung
            eingeholt und auf diese
            Datenschutzerklärung verwiesen.</p>
        <p>10.1.5. Alternativ ist eine Kontaktaufnahme über die bereitgestellte E-Mail-Adresse möglich. In
            diesem Fall werden
            die mit der E-Mail übermittelten personenbezogenen Daten des Nutzers gespeichert. </p>
        <p>10.1.6. Es erfolgt in diesem Zusammenhang keine Weitergabe der Daten an Dritte. Die Daten werden
            ausschließlich für
            die Verarbeitung der Konversation verwendet.</p>
        <p style="font-size:14px; color:#0C50DA; font-weight:bold;">10.2. Rechtsgrundlage für die
            Datenverarbeitung </p>
        <p>10.2.1. Rechtsgrundlage für die Verarbeitung der Daten ist bei Vorliegen einer Einwilligung des
            Nutzers Art. 6 Abs. 1
            S. 1 lit. a DSGVO.</p>
        <p>10.2.2. Rechtsgrundlage für die Verarbeitung der Daten, die im Zuge einer Übersendung einer
            E-Mail übermittelt
            werden, ist Art. 6 Abs. 1 S. 1 lit. f DSGVO. Zielt der E-Mail-Kontakt auf den Abschluss eines
            Vertrages ab, so ist
            zusätzliche Rechtsgrundlage für die Verarbeitung Art. 6 Abs. 1 S. 1 lit. b DSGVO.</p>
        <p style="font-size:14px; color:#0C50DA; font-weight:bold;">10.3. Zweck der Datenverarbeitung</p>
        <p>10.3.1. Die Verarbeitung der personenbezogenen Daten aus der Eingabemaske dient uns allein zur
            Bearbeitung der
            Kontaktaufnahme. Im Falle einer Kontaktaufnahme per E-Mail liegt hieran auch das erforderliche
            berechtigte Interesse
            an der Verarbeitung der Daten.</p>
        <p>10.3.2. Die sonstigen während des Absendevorgangs verarbeiteten personenbezogenen Daten dienen
            dazu, einen Missbrauch
            des Kontaktformulars zu verhindern und die Sicherheit unserer informationstechnischen Systeme
            sicherzustellen.</p>
        <p style="font-size:14px; color:#0C50DA; font-weight:bold;">10.4. Dauer der Speicherung</p>
        <p>10.4.1. Die Daten werden gelöscht, sobald sie für die Erreichung des Zweckes ihrer Erhebung nicht
            mehr erforderlich
            sind. Für die personenbezogenen Daten aus der Eingabemaske des Kontaktformulars und diejenigen,
            die per E-Mail
            übersandt wurden, ist dies dann der Fall, wenn die jeweilige Konversation mit dem Nutzer beendet
            ist. Beendet ist
            die Konversation dann, wenn sich aus den Umständen entnehmen lässt, dass der betroffene
            Sachverhalt abschließend
            geklärt ist. </p>
        <p>10.4.2. Die während des Absendevorgangs zusätzlich erhobenen personenbezogenen Daten werden
            spätestens nach einer
            Frist von sieben Tagen gelöscht.</p>
        <p style="font-size:14px; color:#0C50DA; font-weight:bold;">10.5. Widerspruchs- und
            Beseitigungsmöglichkeit</p>
        <p>10.5.1. Der Nutzer hat jederzeit die Möglichkeit, seine Einwilligung zur Verarbeitung der
            personenbezogenen Daten zu
            widerrufen. Nimmt der Nutzer per E-Mail Kontakt mit uns auf, so kann er der Speicherung seiner
            personenbezogenen
            Daten jederzeit widersprechen. In einem solchen Fall kann die Konversation nicht fortgeführt
            werden.</p>
        <p>10.5.2. Widerruf wie Widerspruch sind insbesondere auch in Textform per E-Mail an unsere
            vorbenannte E-Mailadresse
            möglich. Um uns eine eindeutige Zuordnung zu erleichtern, sollte der Widerruf nach Möglichkeit
            von jener
            E-Mailadresse abgesendet werden, welche mitunter auch mit möglichen Daten des Nutzers verknüpft
            ist.</p>
        <p>10.5.3. Alle personenbezogenen Daten, die im Zuge der Kontaktaufnahme gespeichert wurden, werden
            in diesem Fall
            gelöscht, sofern steuer- und/oder handelsrechtliche Regelungen diesem nicht entgegenstehen. Ist
            eine Löschung
            aufgrund gesetzlicher steuer- und/oder handelsrechtlicher Regelungen (z.B. § 147 Abs. 1 AO, §
            257 Abs. 1 HGB) wird
            der Nutzer gesondert informiert.</p>
        <h1>
            11. Zahlungsabwicklung Stripe
        </h1>
        <p>Zahlungsabwicklung Stripe

            Wir bieten Ihnen in unserem Webshop die Möglichkeit, Zahlungsvorgänge über den Zahlungsdienstleister Stripe,
            510 Townsend St., San Francisco, CA 94103 (Stripe) abzuwickeln. Dies entspricht unserem berechtigten
            Interesse, eine effiziente und sichere Zahlungsmethode anzubieten (Art. 6 Abs. 1 lit. f DSGVO). In dem
            Zusammenhang geben wir folgende Daten an Stripe weiter, soweit es für die Vertragserfüllung erforderlich ist
            (Art. 6 Abs. 1 lit b. DSGVO). Bedingung ist hierfür, dass Sie tatsächlich auch über den Stripe bezahlen. Auf
            die angeforderten Daten durch Stripe haben wir keinen Einfluss.



            Name des Karteninhabers

            E-Mail-Adresse

            Kundennummer

            Bestellnummer

            Bankverbindung

            Kreditkartendaten

            Gültigkeitsdauer der Kreditkarte

            Prüfnummer der Kreditkarte (CVC)

            Datum und Uhrzeit der Transaktion

            Transaktionssumme

            Name des Anbieters

            Ort



            Die Verarbeitung der unter diesem Abschnitt angegebenen Daten ist weder gesetzlich noch vertraglich
            vorgeschrieben. Ohne die Übermittlung Ihrer personenbezogenen Daten können wir eine Zahlung über Stripe
            nicht durchführen. Es besteht für Sie die Möglichkeit, eine andere Zahlungsmethode zu wählen.



            Stripe nimmt bei Datenverarbeitungstätigkeiten eine Doppelrolle als Verantwortlicher und Auftragsverarbeiter
            ein. Als Verantwortlicher verwendet Stripe Ihre übermittelten Daten zur Erfüllung rechtlicher
            Verpflichtungen. Dies entspricht dem berechtigten Interesse Stripes (gem. Art. 6 Abs. 1 lit. f DSGVO) und
            dient der Vertragsdurchführung (gem. Art. 6 Abs. 1 lit. b DSGVO). Wir haben auf diesen Prozess keinen
            Einfluss. Stripe wickelt die Zahlungen direkt selbst ab, ohne, dass wir Details der Transaktionen erfahren.



            Als Auftragsverarbeiter fungiert Stripe, um Transaktionen innerhalb der Zahlungsnetzwerke abschließen zu
            können. Im Rahmen des Auftragsverarbeitungsverhältnisses wird Stripe ausschließlich nach unserer Weisung
            tätig und wurde im Sinne des Art. 28 DSGVO vertraglich verpflichtet, die datenschutzrechtlichen Bestimmungen
            einzuhalten.



            Stripe hat Compliance-Maßnahmen für internationale Datenübermittlungen umgesetzt. Diese gelten für alle
            weltweiten Aktivitäten, bei denen Stripe personenbezogene Daten von natürlichen Personen in der EU
            verarbeitet. Diese Maßnahmen basieren auf den EU-Standardvertragsklauseln (SCC), die Stripe akzeptiert hat.



            Weitere Informationen zu Widerspruchs- und Beseitigungsmöglichkeiten gegenüber Stripe finden Sie unter:
            https://stripe.com/privacy-center/legal



            Ihre Daten werden von uns bis zum Abschluss der Zahlungsabwicklung gespeichert. Dazu zählt auch der Zeitraum
            der für die Bearbeitung von Rückerstattungen, Forderungsmanagement und Betrugsprävention erforderlich ist.
            Auf Grund steuerlicher und handelsrechtslicher Aufbewahrungspflichten werden diese Daten in der Regel nach
            Schluss des Jahres in dem Sie bei uns eingekauft haben noch weitere sechs Jahre aufbewahrt.
        </p>
        <h1>
            12. Facebook
        </h1>
        <p style="font-size:14px; color:#0C50DA; font-weight:bold;">12.1. Beschreibung und Umfang der
            Datenverarbeitung</p>
        <p>12.1.1. Wir nutzen auf Grundlage unserer berechtigten Interessen Social Plugins ("Plugins") des
            sozialen Netzwerkes
            facebook.com, welches von der Facebook Ireland Ltd., 4 Grand Canal Square, Grand Canal Harbour,
            Dublin 2, Irland
            betrieben wird ("Facebook"). Bei einem Zugriff von Nutzern, die ihren gewöhnlichen Aufenthalt
            nicht im Europäischen
            Wirtschaftsraum haben, könnten Anfragen an Facebook über Server des Unternehmens Facebook, Inc.,
            1601 Willow Road,
            Menlo Park, California 94025, USA, erfolgen. Auf eine mögliche Weiterleitung von Anfragen durch
            Facebook haben wir
            keinen Einfluss.</p>
        <p>12.1.2. Facebook Inc. hat die Standardvertragsklauseln der EU-Kommission für die Übermittlung
            personenbezogener Daten
            in Drittländer anerkannt und bietet hierdurch eine Garantie, das europäische Datenschutzrecht
            einzuhalten.</p>
        <p>12.1.3. Die Plugins können Interaktionselemente oder Inhalte (z.B. Videos, Grafiken oder
            Textbeiträge) darstellen und
            sind an einem der Facebook Logos erkennbar (weißes „f“ auf blauer Kachel, den Begriffen "Like",
            "Gefällt mir" oder
            einem „Daumen hoch“-Zeichen) oder sind mit dem Zusatz "Facebook Social Plugin" gekennzeichnet.
            Die Liste und das
            Aussehen der Facebook Social Plugins kann hier eingesehen werden:
            https://developers.facebook.com/docs/plugins/.
        <p>12.1.4. Wenn ein Nutzer eine Funktion dieses Onlineangebotes aufruft, die ein solches Plugin
            enthält, baut sein Gerät
            eine direkte Verbindung mit den Servern von Facebook auf. Der Inhalt des Plugins wird von
            Facebook direkt an das
            Gerät des Nutzers übermittelt und von diesem in das Onlineangebot eingebunden. Dabei können aus
            den verarbeiteten
            Daten Nutzungsprofile der Nutzer erstellt werden. Wir haben daher keinen Einfluss auf den Umfang
            der Daten, die
            Facebook mit Hilfe dieses Plugins erhebt und informiert die Nutzer daher entsprechend unserem
            Kenntnisstand.</p>
        <p>12.1.5. Durch die Einbindung der Plugins erhält Facebook die Information, dass ein Nutzer die
            entsprechende Seite des
            Onlineangebotes aufgerufen hat. Ist der Nutzer bei Facebook eingeloggt, kann Facebook den Besuch
            seinem
            Facebook-Konto zuordnen. Wenn Nutzer mit den Plugins interagieren, zum Beispiel den Like Button
            betätigen oder einen
            Kommentar abgeben, wird die entsprechende Information von Ihrem Gerät direkt an Facebook
            übermittelt und dort
            gespeichert. Falls ein Nutzer kein Mitglied von Facebook ist, besteht trotzdem die Möglichkeit,
            dass Facebook seine
            IP-Adresse in Erfahrung bringt und speichert. Laut Facebook wird in Deutschland nur eine
            anonymisierte IP-Adresse
            gespeichert.</p>
        <p style="font-size:14px; color:#0C50DA; font-weight:bold;">12.2. Rechtsgrundlage für die
            Datenverarbeitung</p>
        <p>Rechtsgrundlage für die Verarbeitung der personenbezogenen Daten der Nutzer ist Art. 6 Abs. 1 S.
            1 lit. f DSGVO.</p>
        <p style="font-size:14px; color:#0C50DA; font-weight:bold;">12.3. Zweck der Datenverarbeitung</p>
        <p>12.3.1. Die Datenverarbeitung erfolgt im Interesse an der Analyse, Optimierung und dem
            wirtschaftlichen Betrieb des
            Onlineangebotes.</p>
        <p>12.3.2. Zweck und Umfang der Datenerhebung und die weitere Verarbeitung und Nutzung der Daten
            durch Facebook sowie
            die diesbezüglichen Rechte und Einstellungsmöglichkeiten zum Schutz der Privatsphäre der Nutzer,
            können diese den
            Datenschutzhinweisen von Facebook entnehmen: https://www.facebook.com/about/privacy/.</p>
        <p style="font-size:14px; color:#0C50DA; font-weight:bold;">12.4. Dauer der Speicherung</p>
        <p>12.4.1. Die Daten werden gelöscht, sobald sie für die Erreichung des Zweckes ihrer Erhebung nicht
            mehr erforderlich
            sind.</p>
        <p style="font-size:14px; color:#0C50DA; font-weight:bold;">12.5. Widerspruchs- und
            Beseitigungsmöglichkeit</p>
        <p>12.5.1. Wenn ein Nutzer Facebook-Mitglied ist und nicht möchte, dass Facebook über dieses
            Onlineangebot Daten über
            ihn sammelt und mit seinen bei Facebook gespeicherten Mitgliedsdaten verknüpft, muss er sich vor
            der Nutzung unseres
            Onlineangebotes bei Facebook ausloggen und seine Cookies löschen.</p>
        <p>12.5.2. Weitere Einstellungen und Widersprüche zur Nutzung von Daten für Werbezwecke, sind
            innerhalb der
            Facebook-Profileinstellungen möglich: https://www.facebook.com/settings?tab=ads oder über die
            US-amerikanische Seite
            https://www.aboutads.info/choices/ oder die EU-Seite https://www.youronlinechoices.com/.</p>
        <p>Die Einstellungen erfolgen plattformunabhängig, d.h. sie werden für alle Geräte, wie
            Desktopcomputer oder mobile
            Geräte übernommen.</p>
        <h1>13. Facebook-, Custom Audiences und Facebook-Marketing-Dienste</h1>
        <p style="font-size:14px; color:#0C50DA; font-weight:bold;">13.1. Beschreibung und Umfang der
            Datenverarbeitung</p>
        <p>13.1.1. Wir nutzen auf Grundlage unserer berechtigten Interessen ein sog. "Facebook-Pixel" des
            sozialen Netzwerkes
            facebook.com, welches von der Facebook Ireland Ltd., 4 Grand Canal Square, Grand Canal Harbour,
            Dublin 2, Irland
            betrieben wird ("Facebook"). Bei einem Zugriff von Nutzern, die ihren gewöhnlichen Aufenthalt
            nicht im Europäischen
            Wirtschaftsraum haben, könnten Anfragen an Facebook über Server des Unternehmens Facebook, Inc.,
            1601 Willow Road,
            Menlo Park, California 94025, USA, erfolgen. Auf eine mögliche Weiterleitung von Anfragen durch
            Facebook haben wir
            keinen Einfluss.</p>
        <p>13.1.2. Facebook Inc. hat die Standardvertragsklauseln der EU-Kommission für die Übermittlung
            personenbezogener Daten
            in Drittländer anerkannt und bietet hierdurch eine Garantie, das europäische Datenschutzrecht
            einzuhalten.</p>
        <p>13.1.3. Mit Hilfe des Facebook-Pixels ist es Facebook zum einen möglich, die Besucher unseres
            Onlineangebotes als
            Zielgruppe für die Darstellung von Anzeigen (sog. "Facebook-Ads") zu bestimmen. Dementsprechend
            setzen wir das
            Facebook-Pixel ein, um die durch uns geschalteten Facebook-Ads nur solchen Facebook-Nutzern
            anzuzeigen, die auch ein
            Interesse an unserem Onlineangebot gezeigt haben oder die bestimmte Merkmale (z.B. Interessen an
            bestimmten Themen
            oder Produkten, die anhand der besuchten Webseiten bestimmt werden) aufweisen, die wir an
            Facebook übermitteln (sog.
            „Custom Audiences“). Mit Hilfe des Facebook-Pixels möchten wir auch sicherstellen, dass unsere
            Facebook-Ads dem
            potentiellen Interesse der Nutzer entsprechen und nicht belästigend wirken. Mit Hilfe des
            Facebook-Pixels können wir
            ferner die Wirksamkeit der Facebook-Werbeanzeigen für statistische und Marktforschungszwecke
            nachvollziehen, in dem
            wir sehen ob Nutzer nachdem Klick auf eine Facebook-Werbeanzeige auf unsere Website
            weitergeleitet wurden (sog.
            „Conversion“). </p>
        <p>13.1.4. Das Facebook-Pixel wird beim Aufruf unserer Webseiten unmittelbar durch Facebook
            eingebunden und kann auf
            Ihrem Gerät ein sog. Cookie, d.h. eine kleine Datei abspeichern. Wenn Sie sich anschließend bei
            Facebook einloggen
            oder im eingeloggten Zustand Facebook besuchen, wird der Besuch unseres Onlineangebotes in Ihrem
            Profil vermerkt.
            Die über Sie erhobenen Daten sind für uns anonym, bieten uns also keine Rückschlüsse auf die
            Identität der Nutzer.
            Allerdings werden die Daten von Facebook gespeichert und verarbeitet, sodass eine Verbindung zum
            jeweiligen
            Nutzerprofil möglich ist und von Facebook sowie zu eigenen Marktforschungs- und Werbezwecken
            verwendet werden kann.
            Sofern wir Daten zu Abgleichzwecken an Facebook übermitteln sollten, werden diese lokal in auf
            dem Browser
            verschlüsselt und erst dann an Facebook über eine gesicherte https-Verbindung gesendet. Dies
            erfolgt alleine mit dem
            Zweck, einen Abgleich mit den gleichermaßen durch Facebook verschlüsselten Daten herzustellen.
        </p>
        <p>Des Weiteren nutzen wir beim Einsatz des Facebook-Pixels die Zusatzfunktion „erweiterter
            Abgleich“ (hierbei werden
            Daten wie Telefonnummern, E-Mailadressen oder Facebook-IDs der Nutzer) zur Bildung von
            Zielgruppen („Custom
            Audiences“ oder „Look Alike Audiences“) an Facebook (verschlüsselt) übermittelt. Weitere
            Hinweise zum „erweiterten
            Abgleich“: https://www.facebook.com/business/help/611774685654668).</p>
        <p>13.1.5. Ebenfalls auf der Grundlage unserer berechtigten Interessen nutzen wir das Verfahren
            „Custom Audiences from
            File“ von Facebook. In diesem Fall werden die E-Mail-Adressen der Newsletterempfänger bei
            Facebook hochgeladen. Der
            Upload-Vorgang findet verschlüsselt statt. Der Upload dient allein dazu, um Empfänger unserer
            Facebook-Anzeigen zu
            bestimmen. Wir möchten damit sicherstellen, dass die Anzeigen nur Nutzern angezeigt werden, die
            ein Interesse an
            unseren Informationen und Leistungen haben.</p>
        <p>13.1.6. Die Verarbeitung der Daten durch Facebook erfolgt im Rahmen von Facebooks
            Datenverwendungsrichtlinie.
            Dementsprechend generelle Hinweise zur Darstellung von Facebook-Ads, in der
            Datenverwendungsrichtlinie von Facebook:
            https://www.facebook.com/policy.php. Spezielle Informationen und Details zum Facebook-Pixel und
            seiner
            Funktionsweise erhalten Sie im Hilfebereich von Facebook:
            https://www.facebook.com/business/help/651294705016616.
        </p>
        <p style="font-size:14px; color:#0C50DA; font-weight:bold;">13.2. Rechtsgrundlage für die
            Datenverarbeitung</p>
        <p>Rechtsgrundlage für die Verarbeitung der personenbezogenen Daten der Nutzer zum Zwecke
            statistischer
            Reichweitenmessung ist jeweilig Art. 6 Abs. 1 S. 1 lit. f DSGVO.</p>
        <p>Rechtsgrundlage für die Verarbeitung der personenbezogenen Daten der Nutzer zum Zwecke des
            Tracking ist bei Vorliegen
            einer diesbezüglichen Einwilligung des Nutzers jeweilig Art. 6 Abs. 1 S. 1 lit. a DSGVO.</p>
        <p style="font-size:14px; color:#0C50DA; font-weight:bold;">13.3. Zweck der Datenverarbeitung</p>
        <p>Die Datenverarbeitung erfolgt im Interesse an der Analyse, Optimierung und dem wirtschaftlichen
            Betrieb des
            Onlineangebotes.</p>
        <p>13.3.1. Zweck und Umfang der Datenerhebung und die weitere Verarbeitung und Nutzung der Daten
            durch Facebook sowie
            die diesbezüglichen Rechte und Einstellungsmöglichkeiten zum Schutz der Privatsphäre der Nutzer,
            können diese den
            Datenschutzhinweisen von Facebook entnehmen: https://www.facebook.com/about/privacy/.</p>
        <p style="font-size:14px; color:#0C50DA; font-weight:bold;">13.4. Dauer der Speicherung</p>
        <p>13.4.1. Die Daten werden gelöscht, sobald sie für die Erreichung des Zweckes ihrer Erhebung nicht
            mehr erforderlich
            sind.</p>
        <p style="font-size:14px; color:#0C50DA; font-weight:bold;">13.5. Widerspruchs- und
            Beseitigungsmöglichkeit</p>
        <p>13.5.1. Sie können der Erfassung durch den Facebook-Pixel und Verwendung Ihrer Daten zur
            Darstellung von Facebook-Ads
            widersprechen. Um einzustellen, welche Arten von Werbeanzeigen Ihnen innerhalb von Facebook
            angezeigt werden, können
            Sie die von Facebook eingerichtete Seite aufrufen und dort die Hinweise zu den Einstellungen
            nutzungsbasierter
            Werbung befolgen: https://www.facebook.com/settings?tab=ads. Die Einstellungen erfolgen
            plattformunabhängig, d.h.
            sie werden für alle Geräte, wie Desktopcomputer oder mobile Geräte übernommen.</p>
        <p>13.5.2. Sie können dem Einsatz von Cookies, die der Reichweitenmessung und Werbezwecken dienen,
            ferner über die
            Deaktivierungsseite der Netzwerkwerbeinitiative (http://optout.networkadvertising.org/) und
            zusätzlich die
            US-amerikanische Webseite (http://www.aboutads.info/choices) oder die europäische Webseite
            (http://www.youronlinechoices.com/uk/your-ad-choices/) widersprechen.</p>
        <p>13.5.3. Ein Tracking erfolgt ausschließlich aufgrund zuvor erteilter Einwilligung des Nutzers.
            Die Einwilligung kann
            jederzeit widerrufen werden.</p>
        <h1>
            14. Instagram
        </h1>
        <p style="font-size:14px; color:#0C50DA; font-weight:bold;">14.1. Beschreibung und Umfang der
            Datenverarbeitung</p>
        <p>14.1.1. Wir nutzen auf Grundlage unserer berechtigten Interessen Komponenten des Dienstes
            Instagram, welcher von der
            Facebook Ireland Ltd., 4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Irland, betrieben
            wird ("Instagram").
            Bei einem Zugriff von Nutzern, die ihren gewöhnlichen Aufenthalt nicht im Europäischen
            Wirtschaftsraum haben,
            könnten Anfragen an Instagram über alternative Server des Unternehmens erfolgen; auf eine
            mögliche Weiterleitung von
            Anfragen durch Facebook haben wir keinen Einfluss.</p>
        <p>14.1.2. Instagram ist ein Dienst, der als audiovisuelle Plattform zu qualifizieren ist und den
            Nutzern das Teilen von
            Fotos und Videos und zudem eine Weiterverbreitung solcher Daten in anderen sozialen Netzwerken
            ermöglicht.</p>
        <p>14.1.3. Durch jeden Aufruf einer der Einzelseiten dieser Internetseite, die durch den für die
            Verarbeitung
            Verantwortlichen betrieben wird und auf welcher eine Instagram-Komponente (Insta-Button)
            integriert wurde, wird der
            Internetbrowser auf dem informationstechnologischen System der betroffenen Person automatisch
            durch die jeweilige
            Instagram-Komponente veranlasst, eine Darstellung der entsprechenden Komponente von Instagram
            herunterzuladen. Im
            Rahmen dieses technischen Verfahrens erhält Instagram Kenntnis darüber, welche konkrete
            Unterseite unserer
            Internetseite durch die betroffene Person besucht wird.</p>
        <p>14.1.4. Sofern die betroffene Person gleichzeitig bei Instagram eingeloggt ist, erkennt Instagram
            mit jedem Aufruf
            unserer Internetseite durch die betroffene Person und während der gesamten Dauer des jeweiligen
            Aufenthaltes auf
            unserer Internetseite, welche konkrete Unterseite die betroffene Person besucht. Diese
            Informationen werden durch
            die Instagram-Komponente gesammelt und durch Instagram dem jeweiligen Instagram-Account der
            betroffenen Person
            zugeordnet. Betätigt die betroffene Person einen der auf unserer Internetseite integrierten
            Instagram-Buttons,
            werden die damit übertragenen Daten und Informationen dem persönlichen Instagram-Benutzerkonto
            der betroffenen
            Person zugeordnet und von Instagram gespeichert und verarbeitet.</p>
        <p>14.1.5. Instagram erhält über die Instagram-Komponente immer dann eine Information darüber, dass
            die betroffene
            Person unsere Internetseite besucht hat, wenn die betroffene Person zum Zeitpunkt des Aufrufs
            unserer Internetseite
            gleichzeitig bei Instagram eingeloggt ist; dies findet unabhängig davon statt, ob die betroffene
            Person die
            Instagram-Komponente anklickt oder nicht. Ist eine derartige Übermittlung dieser Informationen
            an Instagram von der
            betroffenen Person nicht gewollt, kann diese die Übermittlung dadurch verhindern, dass sie sich
            vor einem Aufruf
            unserer Internetseite aus ihrem Instagram-Account ausloggt.</p>
        <p style="font-size:14px; color:#0C50DA; font-weight:bold;">14.2. Rechtsgrundlage für die
            Datenverarbeitung</p>
        <p>Rechtsgrundlage für die Verarbeitung der personenbezogenen Daten der Nutzer ist Art. 6 Abs. 1 S.
            1 lit. f DSGVO.</p>
        <p style="font-size:14px; color:#0C50DA; font-weight:bold;">14.3. Zweck der Datenverarbeitung</p>
        <p>14.3.1. Die Datenverarbeitung erfolgt im Interesse an der Analyse, Optimierung und dem
            wirtschaftlichen Betrieb des
            Onlineangebotes.</p>
        <p>14.3.2. Zweck und Umfang der Datenerhebung und die weitere Verarbeitung und Nutzung der Daten
            durch Instagram kann
            der Datenschutzerklärung von Instagram unter https://help.instagram.com/155833707900388 und
            https://www.instagram.com/about/legal/privacy/ entnommen werden.</p>
        <p style="font-size:14px; color:#0C50DA; font-weight:bold;">14.4. Dauer der Speicherung</p>
        <p>14.4.1. Die Daten werden gelöscht, sobald sie für die Erreichung des Zweckes ihrer Erhebung nicht
            mehr erforderlich
            sind.</p>
        <p style="font-size:14px; color:#0C50DA; font-weight:bold;">14.5. Widerspruchs- und
            Beseitigungsmöglichkeit</p>
        <p>14.5.1. Wenn ein Nutzer zugleich Nutzer der Dienste von Instagram ist und nicht möchte, dass
            Instagram über dieses
            Onlineangebot Daten über ihn sammelt und mit seinen bei Instagram gespeicherten Nutzerdaten
            verknüpft, muss er sich
            vor der Nutzung unseres Onlineangebotes bei Instagram ausloggen und seine Cookies löschen.</p>
        <h1>
            15. Facebook / Instagram Seite
        </h1>
        <p style="font-size:14px; color:#0C50DA; font-weight:bold;">15.1. Beschreibung und Umfang der
            Datenverarbeitung</p>
        <p>15.1.1. Wir unterhalten auf Grundlage unserer berechtigten Interessen eine Onlinepräsenz in den
            sozialen Netzwerken
            facebook.com sowie instagram.com, welche von der Facebook Ireland Ltd., 4 Grand Canal Square,
            Grand Canal Harbour,
            Dublin 2, Irland betrieben werden ("Facebook"). Bei einem Zugriff von Nutzern, die ihren
            gewöhnlichen Aufenthalt
            nicht im Europäischen Wirtschaftsraum haben, könnten Anfragen an Facebook über Server des
            Unternehmens Facebook,
            Inc., 1601 Willow Road, Menlo Park, California 94025, USA, erfolgen. Auf eine mögliche
            Weiterleitung von Anfragen
            durch Facebook haben wir keinen Einfluss.</p>
        <p>15.1.2. Facebook Inc. hat die Standardvertragsklauseln der EU-Kommission für die Übermittlung
            personenbezogener Daten
            in Drittländer anerkannt und bietet hierdurch eine Garantie, das europäische Datenschutzrecht
            einzuhalten.</p>
        <p>15.1.3. Facebook ist es hierbei insbesondere möglich, Daten der Nutzer für Marktforschungs- und
            Wettbewerbszwecke zu
            verarbeiten, so auch die Besucher des Onlineangebotes als Zielgruppe für die Darstellung von
            Anzeigen zu bestimmen.
            Facebook kann entsprechend gewonnene Daten, z.B. aus dem Nutzerverhalten und sich daraus
            ergebenden Interessen der
            Nutzer, zu Nutzerprofilen zusammenführen. In den Nutzungsprofilen können auch Daten unabhängig
            der von den Nutzern
            verwendeten Geräte gespeichert werden. Dieses kann insbesondere dann erfolgen, wenn die Nutzer
            Mitglieder der
            jeweiligen Plattformen sind und bei diesen eingeloggt sind. Facebook stellt uns für die
            Facebook-Seite sog.
            Seiten-Insights zur Verfügung. Bei Seiten-Insights handelt es sich um zusammengefasste Daten,
            durch die neben
            Facebook auch wir Aufschluss darüber erlangen können, wie mit unserer Facebook-Seite
            interagieren. Seiten-Insights
            können auf personenbezogenen Daten basieren, die im Zusammenhang mit einem Besuch oder einer
            Interaktion von
            Personen auf bzw. mit unserer Facebook-Seite und ihren Inhalten erfasst wurden.</p>
        <p>15.1.4. Zu diesen Zwecken werden im Regelfall Cookies auf den Rechnern der Nutzer gespeichert, in
            denen das
            Nutzungsverhalten und die Interessen der Nutzer gespeichert werden.</p>
        <p>15.1.5. Die Verarbeitung der Daten durch Facebook erfolgt im Rahmen von Facebooks
            Datenverwendungsrichtlinie
            (Facebook: https://www.facebook.com/policy.php / Instagram:
            https://help.instagram.com/519522125107875). Sofern eine
            gemeinsame Verantwortung der Verarbeitung von personenbezogenen Daten der Nutzer besteht,
            erfolgt eine Verarbeitung
            auf Grundlage einer Vereinbarung zwischen Facebook und uns über die gemeinsame Verarbeitung
            personenbezogener Daten.
            Für die Verarbeitung von Insights-Daten sind Facebook und wir gemeinsam Verantwortliche; die
            primäre Verantwortung
            gemäß DSGVO für die Verarbeitung von Insights-Daten obliegt Facebook. </p>
        <p>15.1.6. Die wesentlichen Inhalte der Vereinbarung über die gemeinsame Verarbeitung
            personenbezogener Daten können
            unter der URL https://www.facebook.com/legal/terms/page_controller_addendum abgerufen werden.
        </p>
        <p style="font-size:14px; color:#0C50DA; font-weight:bold;">15.2. Rechtsgrundlage für die
            Datenverarbeitung</p>
        <p>Rechtsgrundlage für die Verarbeitung der personenbezogenen Daten der Nutzer ist Art. 6 Abs. 1 S.
            1 lit. f, 26 Abs. 3
            S. 1 DSGVO.</p>
        <p>Rechtsgrundlage für die Verarbeitung personenbezogener Daten bei Vorliegen einer diesbezüglichen
            Einwilligung des
            Nutzers ist Art. 6 Abs. 1 S. 1 lit. a, 7 DSGVO.</p>
        <p style="font-size:14px; color:#0C50DA; font-weight:bold;">15.3. Zweck der Datenverarbeitung</p>
        <p>15.3.1. Die Datenverarbeitung erfolgt im Interesse an der Analyse, Optimierung und dem
            wirtschaftlichen Betrieb
            unseres Onlineangebotes, hier insbesondere auch auf Grundlage unserer berechtigten Interessen an
            einer effektiven
            Information der Nutzer und Kommunikation mit diesen.</p>
        <p>15.3.2. Zweck und Umfang der Datenerhebung und die weitere Verarbeitung und Nutzung der Daten
            durch Facebook sowie
            die diesbezüglichen Rechte und Einstellungsmöglichkeiten zum Schutz der Privatsphäre der Nutzer,
            können den
            Datenschutzhinweisen von Facebook entnommen werden: https://www.facebook.com/about/privacy/.</p>
        <p style="font-size:14px; color:#0C50DA; font-weight:bold;">15.4. Dauer der Speicherung</p>
        <p>15.4.1. Die Daten werden gelöscht, sobald sie für die Erreichung des Zweckes ihrer Erhebung nicht
            mehr erforderlich
            sind. Entscheidungen hinsichtlich der Verarbeitung von Insights-Daten kann allein Facebook
            treffen und umsetzen.</p>
        <p style="font-size:14px; color:#0C50DA; font-weight:bold;">15.5. Widerspruchs- und
            Beseitigungsmöglichkeit</p>
        <p>15.5.1. Sie können der Erfassung durch Facebook und der Verwendung Ihrer Daten insbesondere
            gegenüber Facebook
            widersprechen. Im Falle einer gemeinsamen Verantwortlichkeit könne Sie ihre Rechte auch
            gegenüber uns bzw. jedem
            einzelnen der Verantwortlichen geltend machen. Wir weisen jedoch darauf hin, dass wir
            entsprechende Anfragen
            hinsichtlich der Verarbeitung von Insights-Daten an Facebook weiterleiten. Facebook wird
            Anfragen im Einklang mit
            den dieser obliegenden Pflichten beantworten.</p>
        <p>15.5.2. Um einzustellen, welche Arten von Werbeanzeigen Ihnen innerhalb von Facebook angezeigt
            werden, können Sie die
            von Facebook eingerichtete Seite aufrufen und dort die Hinweise zu den Einstellungen
            nutzungsbasierter Werbung
            befolgen: https://www.facebook.com/settings oder
            https://www.instagram.com/accounts/privacy_and_security/. Die
            Einstellungen erfolgen plattformunabhängig, d.h. sie werden für alle Geräte, wie Desktopcomputer
            oder mobile Geräte
            übernommen; dieses setzt jedoch voraus, dass die Einstellung durch Facebook einem bestimmten
            Nutzer zugeordnet
            werden kann. Hierauf haben wir keinen Einfluss.</p>
        <p>15.5.3. Sie können dem Einsatz von Cookies, die der Reichweitenmessung und Werbezwecken dienen,
            ferner über die
            Deaktivierungsseite der Netzwerkwerbeinitiative (http://optout.networkadvertising.org/) und
            zusätzlich die
            US-amerikanische Webseite (http://www.aboutads.info/choices) oder die europäische Webseite
            (http://www.youronlinechoices.com/uk/your-ad-choices/) widersprechen.</p>
        <h1>
            16. Pinterest-Seite
        </h1>
        <p style="font-size:14px; color:#0C50DA; font-weight:bold;">16.1. Beschreibung und Umfang der
            Datenverarbeitung</p>
        <p>16.1.1. Wir unterhalten auf Grundlage unserer berechtigten Interessen eine Onlinepräsenz in dem
            sozialen Netzwerk
            pinterest.de, welcher von der Pinterest Europe Ltd., Palmerston House, 2nd Floor, Fenian Street,
            Dublin 2, Irland,
            betrieben wird ("Pinterest"). Bei einem Zugriff von Nutzern, die ihren gewöhnlichen Aufenthalt
            nicht im Europäischen
            Wirtschaftsraum haben, könnten Anfragen an Pinterest über alternative Server des Unternehmens
            erfolgen; auf eine
            mögliche Weiterleitung von Anfragen durch Pinterest haben wir keinen Einfluss.</p>
        <p>16.1.2. Pinterest ist ein internetbasierendes soziales Netzwerk. Ein soziales Netzwerk ist ein im
            Internet
            betriebener sozialer Treffpunkt, eine Online-Gemeinschaft, die es den Nutzern in der Regel
            ermöglicht, untereinander
            zu kommunizieren und im virtuellen Raum zu interagieren. Ein soziales Netzwerk kann als
            Plattform zum Austausch von
            Meinungen und Erfahrungen dienen oder ermöglicht es der Internetgemeinschaft, persönliche oder
            unternehmensbezogene
            Informationen bereitzustellen. Pinterest ermöglicht den Nutzern des sozialen Netzwerkes unter
            anderem,
            Bilderkollektionen und Einzelbilder sowie Beschreibungen an virtuellen Pinnwänden zu
            veröffentlichen (sogenanntes
            pinnen), welche dann wiederum von anderen Nutzern geteilt (sogenanntes repinnen) oder
            kommentiert werden können.</p>
        <p>16.1.3. Mehr Informationen zu Pinterest sind unter https://pinterest.com/ abrufbar. Die
            Verarbeitung der Daten durch
            Pinterest erfolgt im Rahmen von Pinterests Datenverwendungsrichtlinie
            (https://policy.pinterest.com/de/privacy-policy).</p>
        <p style="font-size:14px; color:#0C50DA; font-weight:bold;">16.2. Rechtsgrundlage für die
            Datenverarbeitung</p>
        <p>Rechtsgrundlage für die Verarbeitung der personenbezogenen Daten der Nutzer ist Art. 6 Abs. 1 S.
            1 lit. f DSGVO.</p>
        <p>Rechtsgrundlage für die Verarbeitung personenbezogener Daten bei Vorliegen einer diesbezüglichen
            Einwilligung des
            Nutzers ist Art. 6 Abs. 1 S. 1 lit. a, 7 DSGVO.</p>
        <p style="font-size:14px; color:#0C50DA; font-weight:bold;">16.3. Zweck der Datenverarbeitung</p>
        <p>16.3.1. Die Datenverarbeitung erfolgt im Interesse an der Analyse, Optimierung und dem
            wirtschaftlichen Betrieb
            unseres Onlineangebotes, hier insbesondere auch auf Grundlage unserer berechtigten Interessen an
            einer effektiven
            Information der Nutzer und Kommunikation mit diesen.</p>
        <p>16.3.2. Zweck und Umfang der Datenerhebung und die weitere Verarbeitung und Nutzung der Daten
            durch Pinterest kann
            der Datenschutzerklärung von Pinterest unter https://about.pinterest.com/privacy-policy
            entnommen werden. Die
            Cookie-Richtlinie von Pinterest ist unter https://policy.pinterest.com/cookies abrufbar.</p>
        <p style="font-size:14px; color:#0C50DA; font-weight:bold;">16.4. Dauer der Speicherung</p>
        <p>16.4.1. Die Daten werden gelöscht, sobald sie für die Erreichung des Zweckes ihrer Erhebung nicht
            mehr erforderlich
            sind.</p>
        <p style="font-size:14px; color:#0C50DA; font-weight:bold;">16.5. Widerspruchs- und
            Beseitigungsmöglichkeit</p>
        <p>16.5.1. Wenn ein Nutzer zugleich Nutzer der Dienste von Pinterest ist und nicht möchte, dass
            Pinterest über dieses
            Onlineangebot Daten über ihn sammelt und mit seinen bei Pinterest gespeicherten Nutzerdaten
            verknüpft, muss er sich
            vor der Nutzung unseres Onlineangebotes bei Pinterest ausloggen und seine Cookies löschen.</p>
        <p>16.5.2. Pinterest bietet unter https://help.pinterest.com/en/articles/personalization-and-data
            die Möglichkeit,
            zielgerichtete Anzeigen abzubestellen.</p>
        <h1>
            17. LinkedIn
        </h1>
        <p style="font-size:14px; color:#0C50DA; font-weight:bold;">17.1. Beschreibung und Umfang der
            Datenverarbeitung</p>
        <p>17.1.1. Wir nutzen auf Grundlage unserer berechtigten Interessen Komponenten des Dienstes
            LinkedIn, welcher von der
            LinkedIn Ireland Unlimited Company, Wilton Place, Dublin 2, Irland, betrieben wird ("LinkedIn").
            Bei einem Zugriff
            von Nutzern, die ihren gewöhnlichen Aufenthalt nicht im Europäischen Wirtschaftsraum haben,
            könnten Anfragen an
            LinkedIn über alternative Server des Unternehmens erfolgen; auf eine mögliche Weiterleitung von
            Anfragen durch
            LinkiedIn haben wir keinen Einfluss.</p>
        <p>17.1.2. LinkedIn ist ein internetbasiertes soziales Netzwerk, das eine Konnektierung der Nutzer
            mit bestehenden
            Geschäftskontakten sowie das Knüpfen von neuen Businesskontakten ermöglicht.</p>
        <p>17.1.3. Bei jedem einzelnen Abruf unserer Internetseite, die mit einer LinkedIn-Komponente
            (LinkedIn-Plug-In)
            ausgestattet ist, veranlasst diese Komponente, dass der von der betroffenen Person verwendete
            Browser eine
            entsprechende Darstellung der Komponente von LinkedIn herunterlädt.</p>
        <p>17.1.4. Weitere Informationen zu den LinkedIn-Plug-Ins können unter
            https://developer.linkedin.com/plugins abgerufen
            werden. Im Rahmen dieses technischen Verfahrens erhält LinkedIn Kenntnis darüber, welche
            konkrete Unterseite unserer
            Internetseite durch die betroffene Person besucht wird.</p>
        <p>17.1.5. Sofern die betroffene Person gleichzeitig bei LinkedIn eingeloggt ist, erkennt LinkedIn
            mit jedem Aufruf
            unserer Internetseite durch die betroffene Person und während der gesamten Dauer des jeweiligen
            Aufenthaltes auf
            unserer Internetseite, welche konkrete Unterseite unserer Internetseite die betroffene Person
            besucht. Diese
            Informationen werden durch die LinkedIn-Komponente gesammelt und durch LinkedIn dem jeweiligen
            LinkedIn-Account der
            betroffenen Person zugeordnet. Betätigt die betroffene Person einen auf unserer Internetseite
            integrierten
            LinkedIn-Button, ordnet LinkedIn diese Information dem persönlichen LinkedIn-Benutzerkonto der
            betroffenen Person zu
            und speichert diese personenbezogenen Daten.</p>
        <p>17.1.6. LinkedIn erhält über die LinkedIn-Komponente immer dann eine Information darüber, dass
            die betroffene Person
            unsere Internetseite besucht hat, wenn die betroffene Person zum Zeitpunkt des Aufrufes unserer
            Internetseite
            gleichzeitig bei LinkedIn eingeloggt ist; dies findet unabhängig davon statt, ob die betroffene
            Person die
            LinkedIn-Komponente anklickt oder nicht. Ist eine derartige Übermittlung dieser Informationen an
            LinkedIn von der
            betroffenen Person nicht gewollt, kann diese die Übermittlung dadurch verhindern, dass sie sich
            vor einem Aufruf
            unserer Internetseite aus ihrem LinkedIn-Account ausloggt.</p>
        <p style="font-size:14px; color:#0C50DA; font-weight:bold;">17.2. Rechtsgrundlage für die
            Datenverarbeitung</p>
        <p>Rechtsgrundlage für die Verarbeitung der personenbezogenen Daten der Nutzer ist Art. 6 Abs. 1 S.
            1 lit. f DSGVO.</p>
        <p style="font-size:14px; color:#0C50DA; font-weight:bold;">17.3. Zweck der Datenverarbeitung</p>
        <p>17.3.1. Die Datenverarbeitung erfolgt im Interesse an der Analyse, Optimierung und dem
            wirtschaftlichen Betrieb des
            Onlineangebotes.</p>
        <p>17.3.2. Zweck und Umfang der Datenerhebung und die weitere Verarbeitung und Nutzung der Daten
            durch LinkedIn kann der
            Datenschutzerklärung von Linkedin unter https://www.linkedin.com/legal/privacy-policy entnommen
            werden. Die
            Cookie-Richtlinie von LinkedIn ist unter https://www.linkedin.com/legal/cookie-policy abrufbar.
        </p>
        <p style="font-size:14px; color:#0C50DA; font-weight:bold;">17.4. Dauer der Speicherung</p>
        <p>17.4.1. Die Daten werden gelöscht, sobald sie für die Erreichung des Zweckes ihrer Erhebung nicht
            mehr erforderlich
            sind.</p>
        <p style="font-size:14px; color:#0C50DA; font-weight:bold;">17.5. Widerspruchs- und
            Beseitigungsmöglichkeit</p>
        <p>17.5.1. Wenn ein Nutzer zugleich Nutzer der Dienste von LinkedIn ist und nicht möchte, dass
            LinkedIn über dieses
            Onlineangebot Daten über ihn sammelt und mit seinen bei LinkedIn gespeicherten Nutzerdaten
            verknüpft, muss er sich
            vor der Nutzung unseres Onlineangebotes bei LinkedIn ausloggen und seine Cookies löschen.</p>
        <p>17.5.2. LinkedIn bietet unter https://www.linkedin.com/psettings/guest-controls die Möglichkeit,
            E-Mail-Nachrichten,
            SMS-Nachrichten und zielgerichtete Anzeigen abzubestellen sowie Anzeigen-Einstellungen zu
            verwalten.</p>
        <p>17.5.3. LinkedIn nutzt ferner Partner wie Quantcast, Google Analytics, BlueKai, DoubleClick,
            Nielsen, Comscore,
            Eloqua und Lotame, die Cookies setzen können. Solche Cookies können unter
            https://www.linkedin.com/legal/cookie-policy abgelehnt werden.</p>
        <h1>
            18. Pinterest
        </h1>
        <p style="font-size:14px; color:#0C50DA; font-weight:bold;">18.1. Beschreibung und Umfang der
            Datenverarbeitung</p>
        <p>18.1.1. Wir nutzen auf Grundlage unserer berechtigten Interessen Komponenten des Dienstes
            Pinterest, welcher von der
            Pinterest Europe Ltd., Palmerston House, 2nd Floor, Fenian Street, Dublin 2, Irland, betrieben
            wird ("Pinterest").
            Bei einem Zugriff von Nutzern, die ihren gewöhnlichen Aufenthalt nicht im Europäischen
            Wirtschaftsraum haben,
            könnten Anfragen an Pinterest über alternative Server des Unternehmens erfolgen; auf eine
            mögliche Weiterleitung von
            Anfragen durch Pinterest haben wir keinen Einfluss.</p>
        <p>18.1.2. Pinterest ist ein internetbasierendes soziales Netzwerk. Ein soziales Netzwerk ist ein im
            Internet
            betriebener sozialer Treffpunkt, eine Online-Gemeinschaft, die es den Nutzern in der Regel
            ermöglicht, untereinander
            zu kommunizieren und im virtuellen Raum zu interagieren. Ein soziales Netzwerk kann als
            Plattform zum Austausch von
            Meinungen und Erfahrungen dienen oder ermöglicht es der Internetgemeinschaft, persönliche oder
            unternehmensbezogene
            Informationen bereitzustellen. Pinterest ermöglicht den Nutzern des sozialen Netzwerkes unter
            anderem,
            Bilderkollektionen und Einzelbilder sowie Beschreibungen an virtuellen Pinnwänden zu
            veröffentlichen (sogenanntes
            pinnen), welche dann wiederum von anderen Nutzern geteilt (sogenanntes repinnen) oder
            kommentiert werden können.</p>
        <p>18.1.3. Durch jeden Aufruf einer der Einzelseiten dieser Internetseite, die durch den für die
            Verarbeitung
            Verantwortlichen betrieben wird und auf welcher eine Pinterest-Komponente (Pinterest-Plug-In)
            integriert wurde, wird
            der Internetbrowser auf dem informationstechnologischen System der betroffenen Person
            automatisch durch die
            jeweilige Pinterest-Komponente veranlasst, eine Darstellung der entsprechenden
            Pinterest-Komponente von Pinterest
            herunterzuladen. Mehr Informationen zu Pinterest sind unter https://pinterest.com/ abrufbar. Im
            Rahmen dieses
            technischen Verfahrens erhält Pinterest Kenntnis darüber, welche konkrete Unterseite unserer
            Internetseite durch die
            betroffene Person besucht wird.</p>
        <p>18.1.4. Sofern die betroffene Person gleichzeitig bei Pinterest eingeloggt ist, erkennt Pinterest
            mit jedem Aufruf
            unserer Internetseite durch die betroffene Person und während der gesamten Dauer des jeweiligen
            Aufenthaltes auf
            unserer Internetseite, welche konkrete Unterseite unserer Internetseite die betroffene Person
            besucht. Diese
            Informationen werden durch die Pinterest-Komponente gesammelt und durch Pinterest dem jeweiligen
            Pinterest-Account
            der betroffenen Person zugeordnet. Betätigt die betroffene Person einen auf unserer
            Internetseite integrierten
            Pinterest-Button, ordnet Pinterest diese Information dem persönlichen Pinterest-Benutzerkonto
            der betroffenen Person
            zu und speichert diese personenbezogenen Daten.</p>
        <p>18.1.5. Pinterest erhält über die Pinterest-Komponente immer dann eine Information darüber, dass
            die betroffene
            Person unsere Internetseite besucht hat, wenn die betroffene Person zum Zeitpunkt des Aufrufs
            unserer Internetseite
            gleichzeitig bei Pinterest eingeloggt ist; dies findet unabhängig davon statt, ob die betroffene
            Person die
            Pinterest-Komponente anklickt oder nicht. Ist eine derartige Übermittlung dieser Informationen
            an Pinterest von der
            betroffenen Person nicht gewollt, kann diese die Übermittlung dadurch verhindern, dass sie sich
            vor einem Aufruf
            unserer Internetseite aus ihrem Pinterest-Account ausloggt.</p>
        <p style="font-size:14px; color:#0C50DA; font-weight:bold;">18.2. Rechtsgrundlage für die
            Datenverarbeitung</p>
        <p>Rechtsgrundlage für die Verarbeitung der personenbezogenen Daten der Nutzer ist Art. 6 Abs. 1 S.
            1 lit. f DSGVO.</p>
        <p style="font-size:14px; color:#0C50DA; font-weight:bold;">18.3. Zweck der Datenverarbeitung</p>
        <p>18.3.1. Die Datenverarbeitung erfolgt im Interesse an der Analyse, Optimierung und dem
            wirtschaftlichen Betrieb des
            Onlineangebotes.</p>
        <p>18.3.2. Zweck und Umfang der Datenerhebung und die weitere Verarbeitung und Nutzung der Daten
            durch Pinterest kann
            der Datenschutzerklärung von Pinterest unter https://about.pinterest.com/privacy-policy
            entnommen werden. Die
            Cookie-Richtlinie von Pinterest ist unter https://policy.pinterest.com/cookies abrufbar.</p>
        <p style="font-size:14px; color:#0C50DA; font-weight:bold;">18.4. Dauer der Speicherung</p>
        <p>18.4.1. Die Daten werden gelöscht, sobald sie für die Erreichung des Zweckes ihrer Erhebung nicht
            mehr erforderlich
            sind.</p>
        <p style="font-size:14px; color:#0C50DA; font-weight:bold;">18.5. Widerspruchs- und
            Beseitigungsmöglichkeit</p>
        <p>18.5.1. Wenn ein Nutzer zugleich Nutzer der Dienste von Pinterest ist und nicht möchte, dass
            Pinterest über dieses
            Onlineangebot Daten über ihn sammelt und mit seinen bei Pinterest gespeicherten Nutzerdaten
            verknüpft, muss er sich
            vor der Nutzung unseres Onlineangebotes bei Pinterest ausloggen und seine Cookies löschen.</p>
        <p>18.5.2. Pinterest bietet unter https://help.pinterest.com/en/articles/personalization-and-data
            die Möglichkeit,
            zielgerichtete Anzeigen abzubestellen.</p>
        <h1>
            19. Twitter
        </h1>
        <p style="font-size:14px; color:#0C50DA; font-weight:bold;">19.1. Beschreibung und Umfang der
            Datenverarbeitung</p>
        <p>19.1.1. Wir nutzen auf Grundlage unserer berechtigten Interessen Komponenten des Dienstes
            Twitter, welcher von der
            Twitter International Company, One Cumberland Place, Fenian Street, Dublin 2, D02 AX07, Irland,
            betrieben wird
            ("Twitter"). Bei einem Zugriff von Nutzern, die ihren gewöhnlichen Aufenthalt nicht im
            Europäischen Wirtschaftsraum
            haben, könnten Anfragen an Twitter über alternative Server des Unternehmens erfolgen; auf eine
            mögliche
            Weiterleitung von Anfragen durch Twitter haben wir keinen Einfluss.</p>
        <p>19.1.2. Twitter ist ein multilingualer öffentlich zugänglicher Mikroblogging-Dienst, auf welchem
            die Nutzer
            sogenannte Tweets, also Kurznachrichten, die auf 140 Zeichen begrenzt sind, veröffentlichen und
            verbreiten können.
            Diese Kurznachrichten sind für jedermann, also auch für nicht bei Twitter angemeldete Personen
            abrufbar. Die Tweets
            werden aber auch den sogenannten Followern des jeweiligen Nutzers angezeigt. Follower sind
            andere Twitter-Nutzer,
            die den Tweets eines Nutzers folgen. Ferner ermöglicht Twitter über Hashtags, Verlinkungen oder
            Retweets die
            Ansprache eines breiten Publikums.</p>
        <p>19.1.3. Durch jeden Aufruf einer der Einzelseiten dieser Internetseite, die durch den für die
            Verarbeitung
            Verantwortlichen betrieben wird und auf welcher eine Twitter-Komponente (Twitter-Button)
            integriert wurde, wird der
            Internetbrowser auf dem informationstechnologischen System der betroffenen Person automatisch
            durch die jeweilige
            Twitter-Komponente veranlasst, eine Darstellung der entsprechenden Twitter-Komponente von
            Twitter herunterzuladen.
            Weitere Informationen zu den Twitter-Buttons sind unter
            https://about.twitter.com/de/resources/buttons abrufbar. Im
            Rahmen dieses technischen Verfahrens erhält Twitter Kenntnis darüber, welche konkrete Unterseite
            unserer
            Internetseite durch die betroffene Person besucht wird. Zweck der Integration der
            Twitter-Komponente ist es, unseren
            Nutzern eine Weiterverbreitung der Inhalte diese Internetseite zu ermöglichen, diese
            Internetseite in der digitalen
            Welt bekannt zu machen und unsere Besucherzahlen zu erhöhen.</p>
        <p>19.1.4. Sofern die betroffene Person gleichzeitig bei Twitter eingeloggt ist, erkennt Twitter mit
            jedem Aufruf
            unserer Internetseite durch die betroffene Person und während der gesamten Dauer des jeweiligen
            Aufenthaltes auf
            unserer Internetseite, welche konkrete Unterseite unserer Internetseite die betroffene Person
            besucht. Diese
            Informationen werden durch die Twitter-Komponente gesammelt und durch Twitter dem jeweiligen
            Twitter-Account der
            betroffenen Person zugeordnet. Betätigt die betroffene Person einen der auf unserer
            Internetseite integrierten
            Twitter-Buttons, werden die damit übertragenen Daten und Informationen dem persönlichen
            Twitter-Benutzerkonto der
            betroffenen Person zugeordnet und von Twitter gespeichert und verarbeitet.</p>
        <p>19.1.5. Twitter erhält über die Twitter-Komponente immer dann eine Information darüber, dass die
            betroffene Person
            unsere Internetseite besucht hat, wenn die betroffene Person zum Zeitpunkt des Aufrufs unserer
            Internetseite
            gleichzeitig bei Twitter eingeloggt ist; dies findet unabhängig davon statt, ob die betroffene
            Person die
            Twitter-Komponente anklickt oder nicht. Ist eine derartige Übermittlung dieser Informationen an
            Twitter von der
            betroffenen Person nicht gewollt, kann diese die Übermittlung dadurch verhindern, dass sie sich
            vor einem Aufruf
            unserer Internetseite aus ihrem Twitter-Account ausloggt.</p>
        <p style="font-size:14px; color:#0C50DA; font-weight:bold;">19.2. Rechtsgrundlage für die
            Datenverarbeitung</p>
        <p>Rechtsgrundlage für die Verarbeitung der personenbezogenen Daten der Nutzer ist Art. 6 Abs. 1 S.
            1 lit. f DSGVO.</p>
        <p style="font-size:14px; color:#0C50DA; font-weight:bold;">19.3. Zweck der Datenverarbeitung</p>
        <p>19.3.1. Die Datenverarbeitung erfolgt im Interesse an der Analyse, Optimierung und dem
            wirtschaftlichen Betrieb des
            Onlineangebotes.</p>
        <p>19.3.2. Zweck und Umfang der Datenerhebung und die weitere Verarbeitung und Nutzung der Daten
            durch Twitter kann der
            Datenschutzerklärung von Twitter unter <a
                href="https://twitter.com/privacy?lang=de">https://twitter.com/privacy?lang=de</a> entnommen
            werden.</p>
        <p style="font-size:14px; color:#0C50DA; font-weight:bold;">19.4. Dauer der Speicherung</p>
        <p>19.4.1. Die Daten werden gelöscht, sobald sie für die Erreichung des Zweckes ihrer Erhebung nicht
            mehr erforderlich
            sind.</p>
        <p style="font-size:14px; color:#0C50DA; font-weight:bold;">19.5. Widerspruchs- und
            Beseitigungsmöglichkeit</p>
        <p>19.5.1. Wenn ein Nutzer zugleich Nutzer der Dienste von Twitter ist und nicht möchte, dass
            Twitter über dieses
            Onlineangebot Daten über ihn sammelt und mit seinen bei Twitter gespeicherten Nutzerdaten
            verknüpft, muss er sich
            vor der Nutzung unseres Onlineangebotes bei Twitter ausloggen und seine Cookies löschen.</p>
        <p>19.5.2. Twitter bietet Ihnen die Möglichkeit, entsprechende Daten, die Twitter aus Rückschlüssen
            aus Ihrer Aktivität
            auf und außerhalb von Twitter bestimmt hat unter https://twitter.com/your_twitter_data
            einzusehen.</p>
        <h1>
            20. XING
        </h1>
        <p style="font-size:14px; color:#0C50DA; font-weight:bold;">20.1. Beschreibung und Umfang der
            Datenverarbeitung</p>
        <p>20.1.1. Wir nutzen auf Grundlage unserer berechtigten Interessen Komponenten des Dienstes XING,
            welcher von der XING
            AG, Dammtorstraße 30, 20354 Hamburg, Deutschland, betrieben wird ("Xing"). </p>
        <p>20.1.2. Xing ist ein Internetbasiertes soziales Netzwerk, das die Konnektierung der Nutzer mit
            bestehenden
            Geschäftskontakten sowie das Knüpfen von neuen Businesskontakten ermöglicht. Die einzelnen
            Nutzer können bei Xing
            ein persönliches Profil von sich anlegen. Unternehmen können beispielsweise Unternehmensprofile
            erstellen oder
            Stellenangebote auf Xing veröffentlichen.</p>
        <p>20.1.3. Durch jeden Aufruf einer der Einzelseiten dieser Internetseite, die durch den für die
            Verarbeitung
            Verantwortlichen betrieben wird und auf welcher eine Xing-Komponente (Xing-Plug-In) integriert
            wurde, wird der
            Internetbrowser auf dem informationstechnologischen System der betroffenen Person automatisch
            durch die jeweilige
            Xing-Komponente veranlasst, eine Darstellung der entsprechenden Xing-Komponente von Xing
            herunterzuladen. Weitere
            Informationen zum den Xing-Plug-Ins können unter https://dev.xing.com/plugins abgerufen werden.
            Im Rahmen dieses
            technischen Verfahrens erhält Xing Kenntnis darüber, welche konkrete Unterseite unserer
            Internetseite durch die
            betroffene Person besucht wird.</p>
        <p>20.1.4. Sofern die betroffene Person gleichzeitig bei Xing eingeloggt ist, erkennt Xing mit jedem
            Aufruf unserer
            Internetseite durch die betroffene Person und während der gesamten Dauer des jeweiligen
            Aufenthaltes auf unserer
            Internetseite, welche konkrete Unterseite unserer Internetseite die betroffene Person besucht.
            Diese Informationen
            werden durch die Xing-Komponente gesammelt und durch Xing dem jeweiligen Xing-Account der
            betroffenen Person
            zugeordnet. Betätigt die betroffene Person einen der auf unserer Internetseite integrierten
            Xing-Buttons,
            beispielsweise den „Share“-Button, ordnet Xing diese Information dem persönlichen
            Xing-Benutzerkonto der betroffenen
            Person zu und speichert diese personenbezogenen Daten.</p>
        <p>20.1.5. Xing erhält über die Xing-Komponente immer dann eine Information darüber, dass die
            betroffene Person unsere
            Internetseite besucht hat, wenn die betroffene Person zum Zeitpunkt des Aufrufs unserer
            Internetseite gleichzeitig
            bei Xing eingeloggt ist; dies findet unabhängig davon statt, ob die betroffene Person die
            Xing-Komponente anklickt
            oder nicht. Ist eine derartige Übermittlung dieser Informationen an Xing von der betroffenen
            Person nicht gewollt,
            kann diese die Übermittlung dadurch verhindern, dass sie sich vor einem Aufruf unserer
            Internetseite aus ihrem
            Xing-Account ausloggt.</p>
        <p style="font-size:14px; color:#0C50DA; font-weight:bold;">20.2. Rechtsgrundlage für die
            Datenverarbeitung</p>
        <p>Rechtsgrundlage für die Verarbeitung der personenbezogenen Daten der Nutzer ist Art. 6 Abs. 1 S.
            1 lit. f DSGVO.</p>
        <p style="font-size:14px; color:#0C50DA; font-weight:bold;">20.3. Zweck der Datenverarbeitung</p>
        <p>20.3.1. Die Datenverarbeitung erfolgt im Interesse an der Analyse, Optimierung und dem
            wirtschaftlichen Betrieb des
            Onlineangebotes.</p>
        <p>20.3.2. Zweck und Umfang der Datenerhebung und die weitere Verarbeitung und Nutzung der Daten
            durch Xing kann der
            Datenschutzerklärung von Xing unter https://www.xing.com/privacy entnommen werden.
            Datenschutzhinweise des
            Share-Buttons finden Sie unter https://www.xing.com/app/share?op=data_protection.</p>
        <p style="font-size:14px; color:#0C50DA; font-weight:bold;">20.4. Dauer der Speicherung</p>
        <p>20.4.1. Die Daten werden gelöscht, sobald sie für die Erreichung des Zweckes ihrer Erhebung nicht
            mehr erforderlich
            sind.</p>
        <p style="font-size:14px; color:#0C50DA; font-weight:bold;">20.5. Widerspruchs- und
            Beseitigungsmöglichkeit</p>
        <p>20.5.1. Wenn ein Nutzer zugleich Nutzer der Dienste von Xing ist und nicht möchte, dass Xing über
            dieses
            Onlineangebot Daten über ihn sammelt und mit seinen bei Xing gespeicherten Nutzerdaten
            verknüpft, muss er sich vor
            der
            Nutzung unseres Onlineangebotes bei Xing ausloggen und seine Cookies löschen.</p>
        <p>20.5.2. Xing bietet unter https://nats.xing.com/optout.html?popup=1&locale=de_DE die Möglichkeit,
            einer Webanalyse
            zu widersprechen. Weitere Opt-Out Optionen von Xing finden Sie unter
            https://www.xing.com/privacy. </p>
        <h1>
            21. Youtube
        </h1>
        <p style="font-size:14px; color:#0C50DA; font-weight:bold;">21.1. Beschreibung und Umfang der
            Datenverarbeitung</p>
        <p>21.1.1. Wir nutzen auf Grundlage unserer berechtigten Interessen Komponenten des Dienstes YouTube
            der Google Ireland
            Limited, Google Building Gordon House, 4 Barrow St, Dublin, D04 E5W5, Irland („YouTube“). Bei
            einem Zugriff von
            Nutzern, die ihren gewöhnlichen Aufenthalt nicht im Europäischen Wirtschaftsraum oder der
            Schweiz haben, könnten
            Anfragen an Google über Server des Unternehmens Google Inc., 1600 Amphitheatre Parkway, Mountain
            View, CA 94043,
            USA, erfolgen. Auf eine mögliche Weiterleitung von Anfragen durch Google haben wir keinen
            Einfluss.</p>
        <p>21.1.2. Google Inc. hat die Standardvertragsklauseln der EU-Kommission für die Übermittlung
            personenbezogener Daten
            in Drittländer anerkannt und bietet hierdurch eine Garantie, das europäische Datenschutzrecht
            einzuhalten.</p>
        <p>21.1.3. YouTube ist ein Internet-Videoportal, dass Video-Publishern das kostenlose Einstellen von
            Videoclips und
            anderen Nutzern die ebenfalls kostenfreie Betrachtung, Bewertung und Kommentierung dieser
            ermöglicht. YouTube
            gestattet die Publikation aller Arten von Videos, weshalb sowohl komplette Film- und
            Fernsehsendungen, aber auch
            Musikvideos, Trailer oder von Nutzern selbst angefertigte Videos über das Internetportal
            abrufbar sind.</p>
        <p>21.1.4. Durch jeden Aufruf einer der Einzelseiten dieser Internetseite, die durch den für die
            Verarbeitung
            Verantwortlichen betrieben wird und auf welcher eine YouTube-Komponente (YouTube-Video)
            integriert wurde, wird der
            Internetbrowser auf dem informationstechnologischen System der betroffenen Person automatisch
            durch die jeweilige
            YouTube-Komponente veranlasst, eine Darstellung der entsprechenden YouTube-Komponente von
            YouTube herunterzuladen.
            Weitere Informationen zu YouTube können unter https://www.youtube.com/yt/about/de/ abgerufen
            werden. Im Rahmen
            dieses technischen Verfahrens erhalten YouTube und Google Kenntnis darüber, welche konkrete
            Unterseite unserer
            Internetseite durch die betroffene Person besucht wird.</p>
        <p>21.1.5. Sofern die betroffene Person gleichzeitig bei YouTube eingeloggt ist, erkennt YouTube mit
            dem Aufruf einer
            Unterseite, die ein YouTube-Video enthält, welche konkrete Unterseite unserer Internetseite die
            betroffene Person
            besucht. Diese Informationen werden durch YouTube und Google gesammelt und dem jeweiligen
            YouTube-Account der
            betroffenen Person zugeordnet.</p>
        <p>21.1.6. YouTube und Google erhalten über die YouTube-Komponente immer dann eine Information
            darüber, dass die
            betroffene Person unsere Internetseite besucht hat, wenn die betroffene Person zum Zeitpunkt des
            Aufrufs unserer
            Internetseite gleichzeitig bei YouTube eingeloggt ist; dies findet unabhängig davon statt, ob
            die betroffene Person
            ein YouTube-Video anklickt oder nicht. Ist eine derartige Übermittlung dieser Informationen an
            YouTube und Google
            von der betroffenen Person nicht gewollt, kann diese die Übermittlung dadurch verhindern, dass
            sie sich vor einem
            Aufruf unserer Internetseite aus ihrem YouTube-Account ausloggt.</p>
        <p style="font-size:14px; color:#0C50DA; font-weight:bold;">21.2. Rechtsgrundlage für die
            Datenverarbeitung</p>
        <p>Rechtsgrundlage für die Verarbeitung der personenbezogenen Daten der Nutzer ist Art. 6 Abs. 1 S.
            1 lit. f DSGVO.</p>
        <p style="font-size:14px; color:#0C50DA; font-weight:bold;">21.3. Zweck der Datenverarbeitung</p>
        <p>21.3.1. Die Datenverarbeitung erfolgt im Interesse an der Analyse, Optimierung und dem
            wirtschaftlichen Betrieb des
            Onlineangebotes.</p>
        <p>21.3.2. Zweck und Umfang der Datenerhebung und die weitere Verarbeitung und Nutzung der Daten
            durch YouTube kann der
            Datenschutzerklärung von YouTube unter https://www.google.de/intl/de/policies/privacy/ entnommen
            werden. </p>
        <p style="font-size:14px; color:#0C50DA; font-weight:bold;">21.4. Dauer der Speicherung</p>
        <p>21.4.1. Die Daten werden gelöscht, sobald sie für die Erreichung des Zweckes ihrer Erhebung nicht
            mehr erforderlich
            sind.</p>
        <p style="font-size:14px; color:#0C50DA; font-weight:bold;">21.5. Widerspruchs- und
            Beseitigungsmöglichkeit</p>
        <p>21.5.1. Wenn ein Nutzer zugleich Nutzer der Dienste von YouTube ist und nicht möchte, dass
            YouTube über dieses
            Onlineangebot Daten über ihn sammelt und mit seinen bei YouTube gespeicherten Nutzerdaten
            verknüpft, muss er sich
            vor der Nutzung unseres Onlineangebotes bei YouTube ausloggen und seine Cookies löschen.</p>
        <p>21.5.2. YouTube bietet unter https://www.google.de/settings/ads/authenticated die Möglichkeit,
            einer zielgerichteten
            Werbung zu widersprechen.</p>
        <h1>
            22. Youtube-Seite
        </h1>
        <p style="font-size:14px; color:#0C50DA; font-weight:bold;">22.1. Beschreibung und Umfang der
            Datenverarbeitung</p>
        <p>22.1.1. Wir unterhalten auf Grundlage unserer berechtigten Interessen eine Onlinepräsenz in dem
            sozialen Netzwerk
            youtube.com der Google Ireland Limited, Google Building Gordon House, 4 Barrow St, Dublin, D04
            E5W5, Irland
            („YouTube“). Bei einem Zugriff von Nutzern, die ihren gewöhnlichen Aufenthalt nicht im
            Europäischen Wirtschaftsraum
            oder der Schweiz haben, könnten Anfragen an Google über Server des Unternehmens Google Inc.,
            1600 Amphitheatre
            Parkway, Mountain View, CA 94043, USA, erfolgen. Auf eine mögliche Weiterleitung von Anfragen
            durch Google haben wir
            keinen Einfluss.</p>
        <p>22.1.2. Google Inc. hat die Standardvertragsklauseln der EU-Kommission für die Übermittlung
            personenbezogener Daten
            in Drittländer anerkannt und bietet hierdurch eine Garantie, das europäische Datenschutzrecht
            einzuhalten.</p>
        <p>22.1.3. YouTube ist ein Internet-Videoportal, dass Video-Publishern das kostenlose Einstellen von
            Videoclips und
            anderen Nutzern die ebenfalls kostenfreie Betrachtung, Bewertung und Kommentierung dieser
            ermöglicht. YouTube
            gestattet die Publikation aller Arten von Videos, weshalb sowohl komplette Film- und
            Fernsehsendungen, aber auch
            Musikvideos, Trailer oder von Nutzern selbst angefertigte Videos über das Internetportal
            abrufbar sind.</p>
        <p>22.1.4. Weitere Informationen zu YouTube können unter https://www.youtube.com/yt/about/de/
            abgerufen werden. Die
            Verarbeitung der Daten durch YouTube erfolgt im Rahmen von Googles Datenverwendungsrichtlinie
            (https://www.google.de/intl/de/policies/privacy/).</p>
        <p style="font-size:14px; color:#0C50DA; font-weight:bold;">22.2. Rechtsgrundlage für die
            Datenverarbeitung</p>
        <p>Rechtsgrundlage für die Verarbeitung der personenbezogenen Daten der Nutzer ist Art. 6 Abs. 1 S.
            1 lit. f DSGVO.</p>
        <p>Rechtsgrundlage für die Verarbeitung personenbezogener Daten bei Vorliegen einer diesbezüglichen
            Einwilligung des
            Nutzers ist Art. 6 Abs. 1 S. 1 lit. a, 7 DSGVO.</p>
        <p style="font-size:14px; color:#0C50DA; font-weight:bold;">22.3. Zweck der Datenverarbeitung</p>
        <p>22.3.1. Die Datenverarbeitung erfolgt im Interesse an der Analyse, Optimierung und dem
            wirtschaftlichen Betrieb
            unseres Onlineangebotes, hier insbesondere auch auf Grundlage unserer berechtigten Interessen an
            einer effektiven
            Information der Nutzer und Kommunikation mit diesen.</p>
        <p>22.3.2. Zweck und Umfang der Datenerhebung und die weitere Verarbeitung und Nutzung der Daten
            durch YouTube kann der
            Datenschutzerklärung von YouTube unter https://www.google.de/intl/de/policies/privacy/ entnommen
            werden. </p>
        <p style="font-size:14px; color:#0C50DA; font-weight:bold;">22.4. Dauer der Speicherung</p>
        <p>22.4.1. Die Daten werden gelöscht, sobald sie für die Erreichung des Zweckes ihrer Erhebung nicht
            mehr erforderlich
            sind.</p>
        <p style="font-size:14px; color:#0C50DA; font-weight:bold;">22.5. Widerspruchs- und
            Beseitigungsmöglichkeit</p>
        <p>22.5.1. Wenn ein Nutzer zugleich Nutzer der Dienste von YouTube ist und nicht möchte, dass
            YouTube über dieses
            Onlineangebot Daten über ihn sammelt und mit seinen bei YouTube gespeicherten Nutzerdaten
            verknüpft, muss er sich
            vor der Nutzung unseres Onlineangebotes bei YouTube ausloggen und seine Cookies löschen.</p>
        <p>22.5.2. YouTube bietet unter https://www.google.de/settings/ads/authenticateddie Möglichkeit,
            einer zielgerichteten
            Werbung zu widersprechen.</p>

        <h1>
            23. Rechte der betroffenen Person
        </h1>
        <p>Werden personenbezogene Daten von Ihnen verarbeitet, sind Sie Betroffener i.S.d. DSGVO und es
            stehen Ihnen folgende
            Rechte gegenüber dem Verantwortlichen zu:</p>
        <p style="font-size:14px; color:#0C50DA; font-weight:bold;">23.1. Auskunftsrecht</p>
        <p>23.1.1. Sie können von dem Verantwortlichen eine Bestätigung darüber verlangen, ob
            personenbezogene Daten, die Sie
            betreffen, von uns verarbeitet werden. </p>
        <p>23.1.2. Liegt eine solche Verarbeitung vor, können Sie von dem Verantwortlichen über folgende
            Informationen Auskunft
            verlangen:</p>
        <ol>
            <li> die Zwecke, zu denen die personenbezogenen Daten verarbeitet werden;</li>
            <li> die Kategorien von personenbezogenen Daten, welche verarbeitet werden;</li>
            <li> die Empfänger bzw. die Kategorien von Empfängern, gegenüber denen die Sie betreffenden
                personenbezogenen Daten
                offengelegt wurden oder noch offengelegt werden;</li>
            <li> die geplante Dauer der Speicherung der Sie betreffenden personenbezogenen Daten oder, falls
                konkrete Angaben
                hierzu nicht möglich sind, Kriterien für die Festlegung der Speicherdauer;</li>
            <li> das Bestehen eines Rechts auf Berichtigung oder Löschung der Sie betreffenden
                personenbezogenen Daten, eines
                Rechts auf Einschränkung der Verarbeitung durch den Verantwortlichen oder eines
                Widerspruchsrechts gegen diese
                Verarbeitung; </li>
            <li> das Bestehen eines Beschwerderechts bei einer Aufsichtsbehörde;</li>
            <li> alle verfügbaren Informationen über die Herkunft der Daten, wenn die personenbezogenen
                Daten nicht bei der
                betroffenen Person erhoben werden;</li>
            <li> das Bestehen einer automatisierten Entscheidungsfindung einschließlich Profiling gemäß Art.
                22 Abs. 1 und 4
                DSGVO und – zumindest in diesen Fällen – aussagekräftige Informationen über die involvierte
                Logik sowie die
                Tragweite und die angestrebten Auswirkungen einer derartigen Verarbeitung für die betroffene
                Person.</li>
        </ol>
        <p>23.1.3. Ihnen steht das Recht zu, Auskunft darüber zu verlangen, ob die Sie betreffenden
            personenbezogenen Daten in
            ein Drittland oder an eine internationale Organisation übermittelt werden. In diesem
            Zusammenhang können Sie
            verlangen, über die geeigneten Garantien gem. Art. 46 DSGVO im Zusammenhang mit der Übermittlung
            unterrichtet zu
            werden.</p>
        <p style="font-size:14px; color:#0C50DA; font-weight:bold;">23.2. Recht auf Berichtigung </p>
        <p>23.2.1. Sie haben ein Recht auf Berichtigung und/oder Vervollständigung gegenüber dem
            Verantwortlichen, sofern die
            verarbeiteten personenbezogenen Daten, die Sie betreffen, unrichtig oder unvollständig sind. Der
            Verantwortliche hat
            die Berichtigung unverzüglich vorzunehmen.</p>
        <p style="font-size:14px; color:#0C50DA; font-weight:bold;">23.3. Recht auf Einschränkung der
            Verarbeitung</p>
        <p>23.3.1. Unter den folgenden Voraussetzungen können Sie die Einschränkung der Verarbeitung der Sie
            betreffenden
            personenbezogenen Daten verlangen:</p>
        <ol>
            <li> wenn Sie die Richtigkeit der Sie betreffenden personenbezogenen für eine Dauer bestreiten,
                die es dem
                Verantwortlichen ermöglicht, die Richtigkeit der personenbezogenen Daten zu überprüfen;</li>
            <li> die Verarbeitung unrechtmäßig ist und Sie die Löschung der personenbezogenen Daten ablehnen
                und stattdessen die
                Einschränkung der Nutzung der personenbezogenen Daten verlangen;</li>
            <li> der Verantwortliche die personenbezogenen Daten für die Zwecke der Verarbeitung nicht
                länger benötigt, Sie
                diese jedoch zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen benötigen,
                oder</li>
            <li> wenn Sie Widerspruch gegen die Verarbeitung gemäß Art. 21 Abs. 1 DSGVO eingelegt haben und
                noch nicht
                feststeht, ob die berechtigten Gründe des Verantwortlichen gegenüber Ihren Gründen
                überwiegen.</li>
        </ol>
        <p>23.3.2. Wurde die Verarbeitung der Sie betreffenden personenbezogenen Daten eingeschränkt, dürfen
            diese Daten – von
            ihrer Speicherung abgesehen – nur mit Ihrer Einwilligung oder zur Geltendmachung, Ausübung oder
            Verteidigung von
            Rechtsansprüchen oder zum Schutz der Rechte einer anderen natürlichen oder juristischen Person
            oder aus Gründen
            eines wichtigen öffentlichen Interesses der Union oder eines Mitgliedstaats verarbeitet werden.
        </p>
        <p>23.3.3. Wurde die Einschränkung der Verarbeitung nach den o.g. Voraussetzungen eingeschränkt,
            werden Sie von dem
            Verantwortlichen unterrichtet bevor die Einschränkung aufgehoben wird.</p>
        <p style="font-size:14px; color:#0C50DA; font-weight:bold;">23.4. Recht auf Löschung</p>
        <p>23.4.1. Sie können von dem Verantwortlichen verlangen, dass die Sie betreffenden
            personenbezogenen Daten unverzüglich
            gelöscht werden, und der Verantwortliche ist verpflichtet, diese Daten unverzüglich zu löschen,
            sofern einer der
            folgenden Gründe zutrifft:</p>
        <ol>
            <li> Die Sie betreffenden personenbezogenen Daten sind für die Zwecke, für die sie erhoben oder
                auf sonstige Weise
                verarbeitet wurden, nicht mehr notwendig.</li>
            <li> Sie widerrufen Ihre Einwilligung, auf die sich die Verarbeitung gem. Art. 6 Abs. 1 S. 1
                lit. a oder Art. 9 Abs.
                2 lit. a DSGVO stützte, und es fehlt an einer anderweitigen Rechtsgrundlage für die
                Verarbeitung. </li>
            <li> Sie legen gem. Art. 21 Abs. 1 DSGVO Widerspruch gegen die Verarbeitung ein und es liegen
                keine vorrangigen
                berechtigten Gründe für die Verarbeitung vor, oder Sie legen gem. Art. 21 Abs. 2 DSGVO
                Widerspruch gegen die
                Verarbeitung ein. </li>
            <li> Die Sie betreffenden personenbezogenen Daten wurden unrechtmäßig verarbeitet. </li>
            <li> Die Löschung der Sie betreffenden personenbezogenen Daten ist zur Erfüllung einer
                rechtlichen Verpflichtung
                nach dem Unionsrecht oder dem Recht der Mitgliedstaaten erforderlich, dem der
                Verantwortliche unterliegt. </li>
            <li> Die Sie betreffenden personenbezogenen Daten wurden in Bezug auf angebotene Dienste der
                Informationsgesellschaft gemäß Art. 8 Abs. 1 DSGVO erhoben.</li>
        </ol>
        <p style="font-size:14px; color:#0C50DA; font-weight:bold;">23.5. Information an Dritte</p>
        <p>Hat der Verantwortliche die Sie betreffenden personenbezogenen Daten öffentlich gemacht und ist
            er gem. Art. 17 Abs.
            1 DSGVO zu deren Löschung verpflichtet, so trifft er unter Berücksichtigung der verfügbaren
            Technologie und der
            Implementierungskosten angemessene Maßnahmen, auch technischer Art, um für die Datenverarbeitung
            Verantwortliche,
            die die personenbezogenen Daten verarbeiten, darüber zu informieren, dass Sie als betroffene
            Person von ihnen die
            Löschung aller Links zu diesen personenbezogenen Daten oder von Kopien oder Replikationen dieser
            personenbezogenen
            Daten verlangt haben. </p>
        <p style="font-size:14px; color:#0C50DA; font-weight:bold;">23.6. Ausnahmen</p>
        <p>Das Recht auf Löschung besteht nicht, soweit die Verarbeitung erforderlich ist</p>
        <ol>
            <li> zur Ausübung des Rechts auf freie Meinungsäußerung und Information; </li>
            <li> zur Erfüllung einer rechtlichen Verpflichtung, die die Verarbeitung nach dem Recht der
                Union oder der
                Mitgliedstaaten, dem der Verantwortliche unterliegt, erfordert, oder zur Wahrnehmung einer
                Aufgabe, die im
                öffentlichen Interesse liegt oder in Ausübung öffentlicher Gewalt erfolgt, die dem
                Verantwortlichen übertragen
                wurde;</li>
            <li> aus Gründen des öffentlichen Interesses im Bereich der öffentlichen Gesundheit gemäß Art. 9
                Abs. 2 lit. h und i
                sowie Art. 9 Abs. 3 DSGVO;</li>
            <li> für im öffentlichen Interesse liegende Archivzwecke, wissenschaftliche oder historische
                Forschungszwecke oder
                für statistische Zwecke gem. Art. 89 Abs. 1 DSGVO, soweit das unter Abschnitt a) genannte
                Recht voraussichtlich
                die Verwirklichung der Ziele dieser Verarbeitung unmöglich macht oder ernsthaft
                beeinträchtigt, oder</li>
            <li> zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.</li>
        </ol>
        <p style="font-size:14px; color:#0C50DA; font-weight:bold;">23.7. Recht auf Unterrichtung</p>
        <p>23.7.1. Haben Sie das Recht auf Berichtigung, Löschung oder Einschränkung der Verarbeitung
            gegenüber dem
            Verantwortlichen geltend gemacht, ist dieser verpflichtet, allen Empfängern, denen die Sie
            betreffenden
            personenbezogenen Daten offengelegt wurden, diese Berichtigung oder Löschung der Daten oder
            Einschränkung der
            Verarbeitung mitzuteilen, es sei denn, dies erweist sich als unmöglich oder ist mit einem
            unverhältnismäßigen
            Aufwand verbunden.</p>
        <p>23.7.2. Ihnen steht gegenüber dem Verantwortlichen das Recht zu, über diese Empfänger
            unterrichtet zu werden.</p>
        <p style="font-size:14px; color:#0C50DA; font-weight:bold;">23.8. Recht auf Datenübertragbarkeit</p>
        <p>23.8.1. Sie haben das Recht, die Sie betreffenden personenbezogenen Daten, die Sie dem
            Verantwortlichen
            bereitgestellt haben, in einem strukturierten, gängigen und maschinenlesbaren Format zu
            erhalten. Außerdem haben Sie
            das Recht diese Daten einem anderen Verantwortlichen ohne Behinderung durch den
            Verantwortlichen, dem die
            personenbezogenen Daten bereitgestellt wurden, zu übermitteln, sofern</p>
        <ol>
            <li> die Verarbeitung auf einer Einwilligung gem. Art. 6 Abs. 1 S. 1 lit. a DSGVO oder Art. 9
                Abs. 2 lit. a DSGVO
                oder auf einem Vertrag gem. Art. 6 Abs. 1 S. 1 lit. b DSGVO beruht und</li>
            <li> die Verarbeitung mithilfe automatisierter Verfahren erfolgt.</li>
        </ol>
        <p>23.8.2. In Ausübung dieses Rechts haben Sie ferner das Recht, zu erwirken, dass die Sie
            betreffenden
            personenbezogenen Daten direkt von einem Verantwortlichen einem anderen Verantwortlichen
            übermittelt werden, soweit
            dies technisch machbar ist. Freiheiten und Rechte anderer Personen dürfen hierdurch nicht
            beeinträchtigt werden.</p>
        <p>23.8.3. Das Recht auf Datenübertragbarkeit gilt nicht für eine Verarbeitung personenbezogener
            Daten, die für die
            Wahrnehmung einer Aufgabe erforderlich ist, die im öffentlichen Interesse liegt oder in Ausübung
            öffentlicher Gewalt
            erfolgt, die dem Verantwortlichen übertragen wurde.</p>
        <h1>
            24. Widerspruchsrecht
        </h1>
        <p>24.1. Sie haben das Recht, aus Gründen, die sich aus ihrer besonderen Situation ergeben,
            jederzeit gegen die
            Verarbeitung der Sie betreffenden personenbezogenen Daten, die aufgrund von Art. 6 Abs. 1 S. 1
            lit. e oder f DSGVO
            erfolgt, Widerspruch einzulegen; dies gilt auch für ein auf diese Bestimmungen gestütztes
            Profiling. </p>
        <p>24.2. Der Verantwortliche verarbeitet die Sie betreffenden personenbezogenen Daten nicht mehr, es
            sei denn, er kann
            zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre Interessen, Rechte und
            Freiheiten
            überwiegen, oder die Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von
            Rechtsansprüchen.</p>
        <p>24.3. Werden die Sie betreffenden personenbezogenen Daten verarbeitet, um Direktwerbung zu
            betreiben, haben Sie das
            Recht, jederzeit Widerspruch gegen die Verarbeitung der Sie betreffenden personenbezogenen Daten
            zum Zwecke
            derartiger Werbung einzulegen; dies gilt auch für das Profiling, soweit es mit solcher
            Direktwerbung in Verbindung
            steht.</p>
        <p>24.4. Widersprechen Sie der Verarbeitung für Zwecke der Direktwerbung, so werden die Sie
            betreffenden
            personenbezogenen Daten nicht mehr für diese Zwecke verarbeitet.</p>
        <p>24.5. Sie haben die Möglichkeit, im Zusammenhang mit der Nutzung von Diensten der
            Informationsgesellschaft –
            ungeachtet der Richtlinie 2002/58/EG – Ihr Widerspruchsrecht mittels automatisierter Verfahren
            auszuüben, bei denen
            technische Spezifikationen verwendet werden.</p>
        <p>24.6. Sie haben auch das Recht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, bei
            der Verarbeitung
            Sie betreffender personenbezogener Daten, die zu wissenschaftlichen oder historischen
            Forschungszwecken oder zu
            statistischen Zwecken gem. Art. 89 Abs. 1 DSGVO erfolgt, dieser zu widersprechen.</p>
        <p>24.7. Ihr Widerspruchsrecht kann insoweit beschränkt werden, als es voraussichtlich die
            Verwirklichung der
            Forschungs- oder Statistikzwecke unmöglich macht oder ernsthaft beeinträchtigt und die
            Beschränkung für die
            Erfüllung der Forschungs- oder Statistikzwecke notwendig ist.</p>
        <h1>
            25. Recht auf Widerruf der datenschutzrechtlichen Einwilligungserklärung
        </h1>
        <p>Sie haben das Recht, Ihre datenschutzrechtliche Einwilligungserklärung jederzeit zu widerrufen.
            Durch den Widerruf
            der Einwilligung wird die Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf
            erfolgten Verarbeitung nicht
            berührt.</p>
        <h1>
            26. Automatisierte Entscheidung im Einzelfall einschließlich Profiling
        </h1>
        <p>26.1. Sie haben das Recht, nicht einer ausschließlich auf einer automatisierten Verarbeitung –
            einschließlich
            Profiling – beruhenden Entscheidung unterworfen zu werden, die Ihnen gegenüber rechtliche
            Wirkung entfaltet oder Sie
            in ähnlicher Weise erheblich beeinträchtigt. Dies gilt nicht, wenn die Entscheidung </p>
        <ol>
            <li> für den Abschluss oder die Erfüllung eines Vertrags zwischen Ihnen und dem Verantwortlichen
                erforderlich ist,
            </li>
            <li> aufgrund von Rechtsvorschriften der Union oder der Mitgliedstaaten, denen der
                Verantwortliche unterliegt,
                zulässig ist und diese Rechtsvorschriften angemessene Maßnahmen zur Wahrung Ihrer Rechte und
                Freiheiten sowie
                Ihrer berechtigten Interessen enthalten oder</li>
            <li> mit Ihrer ausdrücklichen Einwilligung erfolgt.</li>
        </ol>
        <p>26.2. Allerdings dürfen diese Entscheidungen nicht auf besonderen Kategorien personenbezogener
            Daten nach Art. 9 Abs.
            1 DSGVO beruhen, sofern nicht Art. 9 Abs. 2 lit. a oder g DSGVO gilt und angemessene Maßnahmen
            zum Schutz der Rechte
            und Freiheiten sowie Ihrer berechtigten Interessen getroffen wurden.</p>
        <p>26.3. Hinsichtlich der in (1) und (3) genannten Fälle trifft der Verantwortliche angemessene
            Maßnahmen, um die Rechte
            und Freiheiten sowie Ihre berechtigten Interessen zu wahren, wozu mindestens das Recht auf
            Erwirkung des Eingreifens
            einer Person seitens des Verantwortlichen, auf Darlegung des eigenen Standpunkts und auf
            Anfechtung der Entscheidung
            gehört.</p>
        <h1>
            27. Recht auf Beschwerde bei einer Aufsichtsbehörde
        </h1>
        <p>27.1. Unbeschadet eines anderweitigen verwaltungsrechtlichen oder gerichtlichen Rechtsbehelfs
            steht Ihnen das Recht
            auf Beschwerde bei einer Aufsichtsbehörde, insbesondere in dem Mitgliedstaat ihres
            Aufenthaltsorts, ihres
            Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes, zu, wenn Sie der Ansicht sind, dass die
            Verarbeitung der
            Sie betreffenden personenbezogenen Daten gegen die DSGVO verstößt. </p>
        <p>27.2. Die Aufsichtsbehörde, bei der die Beschwerde eingereicht wurde, unterrichtet den
            Beschwerdeführer über den
            Stand und die Ergebnisse der Beschwerde einschließlich der Möglichkeit eines gerichtlichen
            Rechtsbehelfs nach Art.
            78 DSGVO.</p>
    </div>
</section>