import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './home/home.component';
import { AgbComponent } from './agb/agb.component';
import { ImpressumComponent } from './impressum/impressum.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { CheckEmailComponent } from './check-email/check-email.component';
import { CheckPaymentComponent } from './check-payment/check-payment.component';
import { RegisterComponent } from './account/register/register.component';
import { DashboardComponent } from './user-area/dashboard/dashboard.component';
import { BranchesComponent } from './user-area/branches/branches.component';
import { CreateCompanyComponent } from './user-area/create-company/create-company.component';
import { CreateBranchesComponent } from './user-area/create-branches/create-branches.component';
import { EditBranchesComponent } from './user-area/edit-branches/edit-branches.component';
import { PricingComponent } from './user-area/pricing/pricing.component';
import { BranchListComponent } from './branch/list/branch-list.component';
import { BranchViewComponent } from './branch/view/branch-view.component';
import { ContactComponent } from './contact/contact.component';
import { ForgetPasswordComponent } from './forget-password/forget-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { CheckResetComponent } from './check-reset/check-reset.component';
import { AuthGuard } from './_helpers';
import { ConfirmEmailComponent } from './confirm-email/confirm-email.component';
import { PaymentSuccessComponent } from './payment-success/payment-success.component';
import { PaymentComponent } from './payment/payment.component';
import { InvoicesComponent } from './invoices/invoices.component';


const routes: Routes = [
  //{ path: "", redirectTo: "presentation", pathMatch: "full" },
  { path: '', component: HomeComponent },
  { path: 'login', component: RegisterComponent },
  { path: 'register', component: RegisterComponent, data: { view: 'SignUp' } },
  { path: 'login', component: RegisterComponent, data: { view: 'SignIn' } },
  { path: 'contact', component: ContactComponent },
  { path: 'agb', component: AgbComponent },
  { path: 'privacy', component: PrivacyComponent },
  { path: 'impressum', component: ImpressumComponent },
  { path: 'check-email', component: CheckEmailComponent },
  { path: 'confirm-email', component: ConfirmEmailComponent },
  { path: 'forget-password', component: ForgetPasswordComponent },
  { path: 'reset-password', component: ResetPasswordComponent },
  { path: 'check-reset', component: CheckResetComponent },
  { path: 'branches/:category/:location', pathMatch: 'full', component: BranchListComponent },
  { path: 'branch/:id', pathMatch: 'full', component: BranchViewComponent },
  { path: 'check-payment', component: CheckPaymentComponent },
  { path: 'payment-success', component: PaymentSuccessComponent },
  {
    path: 'user-area',
    canActivate: [AuthGuard],
    children: [
      { path: 'payment', component: PaymentComponent },
      { path: 'dashboard', component: DashboardComponent, data: { extraParameter: 'materialLayout' } },
      { path: 'branches', component: BranchesComponent, data: { extraParameter: 'materialLayout' } },
      { path: 'company/:pack', component: CreateCompanyComponent, data: { extraParameter: 'materialLayout' } },
      { path: 'add-branch', component: CreateBranchesComponent, data: { extraParameter: 'materialLayout' } },
      { path: 'edit-branch/:id', component: EditBranchesComponent, data: { extraParameter: 'materialLayout' } },
      { path: 'pricing', component: PricingComponent, data: { extraParameter: 'materialLayout' } },
      { path: 'invoices', component: InvoicesComponent, data: { extraParameter: 'materialLayout' } },
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
      scrollOffset: [0, 64]
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
