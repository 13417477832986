import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AccountService } from '../_services/account.service';

@Component({
  selector: 'volkshandwerker-check-email',
  templateUrl: './payment-success.component.html',
  styleUrls: ['./payment-success.component.scss']
})
export class PaymentSuccessComponent implements OnInit {

  constructor(private accountService: AccountService, private router: Router) { }


  ngOnInit(): void {
    /*  this.accountService.updateUserInfo() */
    const interval = setInterval(() => {
      this.accountService.updateUserInfo()
      if (this.accountService.isSubscriber()) {
        this.router.navigate(['/user-area/dashboard'])
        clearInterval(interval)
      }
    }, 5000);
  }

}
