import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Toaster } from 'ngx-toast-notifications';
import { env } from 'process';
import { AccountService } from 'src/app/_services';
import { environment } from 'src/environments/environment';
import { BaseComponent } from '../../base/components/base/base-component';

@Component({
  selector: 'volkshandwerker-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss']
})
export class ContactFormComponent extends BaseComponent implements OnInit {

  form: FormGroup;

  @Input() sendToEmail: string = '';

  @Input() companyName: string = '';

  constructor(
    private fb: FormBuilder,
    private accountService: AccountService,
    private toaster: Toaster
  ) {
    super();

    this.form = this.fb.group({
      name: ['', Validators.required],
      email: ['', Validators.compose([Validators.required, Validators.email])],
      message: ['', Validators.required]
    });
  }

  ngOnInit() {
  }

  resolve($event: any): void {
    this.onSubmit();
  }

  unresolve($event: any): void {
  }

  // Send email
  onSubmit(): void {

    // Stop here if form is invalid
    if (this.form.invalid === true) {
      return;
    }

    const body = {
      to: this.sendToEmail,
      from: 'anfrage@volkshandwerker.de',
      cc: this.form.value.email,
      replyTo: this.form.value.email,
      subject: `Volkshandwerker: Kontaktanfrage von ${this.form.value.name}`,
      text: this.form.value.message,
      html: `
      <p>${this.form.value.message}</p>
      <p>Dem Erhalt von E-Mails hinsichtlich Abonnement Paketen und Kundenanfragen, die im Zusammenhang mit Ihrem getätigten Abschluss stehen,  können Sie jederzeit per E-Mail an: info@volkshandwerker.de  oder per Post an VHW UG ( haftungsbeschränkt ) , Kelterstraße 2, 75179 Pforzheim widersprechen. </p>
      <p>Volkshandwerker – Die große Handwerker Suchmaschine für Deutschland, Österreich und die Schweiz</p>
      <p>VHW UG ( haftungsbeschränkt ), Kelterstraße 2 , 75179 Pforzheim</p>
      <p>Telefon:  07231 46 220 60</p>
      <p>E-Mail : info@volkshandwerker.de</p>
      <p>Web: www.volkshandwerker.de</p>
      <p>Geschäftsführer: Volkan Celikten</p>
      <p>Amtsgericht Mannheim HRB 725 936 I Umsatzsteuer – ID : DE30 88 36 209</p>
      <p>AGB I Datenschutz I  Impressum</p>
      `
    }

    if (this.isLoadingSubmit === false) {
      this.isLoadingSubmit = true;

      this.accountService.makePostRequest('/email', body).then(data => {
        this.form.reset();
        this.isLoadingSubmit = false;
        this.toaster.open({
          caption: 'Erfolgreich!',
          type: 'success',
          text: 'Ihre Kontaktanfrage wurde erfolgreich verschickt.',
          position: 'bottom-left'
        });
      }).catch(error => {
        this.form.reset();
        this.isLoadingSubmit = false;
        this.toaster.open({
          caption: 'Fehler!',
          type: 'danger',
          text: 'Es ist ein Fehler aufgetreten. Bitte kontaktieren Sie uns.',
          position: 'bottom-left'
        });
      });
    }
  }

}
