import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AccountService, AlertService } from 'src/app/_services';
import { ResponseData } from '../../models/Response';

@Component({
  selector: 'volkshandwerker-search-form',
  templateUrl: './search-form.component.html',
  styleUrls: ['./search-form.component.scss']
})
export class SearchFormComponent implements OnInit {

  public form: FormGroup;
  public categories: any = null;

  constructor(
    private fb: FormBuilder,
    private accountService: AccountService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.form = this.fb.group({
      location: ['', Validators.required],
      category: ['', Validators.required]
    })
  }

  ngOnInit() {
    this.form.get('category').setValue(-1);

    this.accountService.makeGetRequest('/categories').then((data: any) => {
      this.categories = data;
    });

    this.route.params.subscribe((params: Params) => {
      this.form.get('location').setValue(params.location);
    })
  }

  onSubmit(location, category) {
    if (location.length <= 0) {
      return;
    }
    this.router.navigateByUrl(`/branches/${category}/${location}`);
  }


}
