// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

/* export const environment = {
  production: false,
  // apiUrl: 'https://volkshandwerker-api-qa.herokuapp.com/api',
  apiUrl: 'http://localhost:1337/api',
  stripeUrl: 'http://192.168.1.22:2086',
  mapbox: {
    accessToken: 'pk.eyJ1Ijoidm9sa3NoYW5kd2Vya2VyIiwiYSI6ImNrbm03NDg0ZDBraGQzMXFwdDJ0dG80eHEifQ.DZWxvdWOzqeVIzOSa9ngwg'
  },
  environmentName: 'DEV'
}; */

// PROD
export const environment = {
  production: false,
  // apiUrl: 'https://volkshandwerker-api-qa.herokuapp.com/api',
  apiUrl: 'https://api.volkshandwerker.de/api',
  //apiUrl: 'http://localhost:1337/api',
  stripeUrl: 'https://payment.volkshandwerker.de:2096',
  mapbox: {
    accessToken: 'pk.eyJ1Ijoidm9sa3NoYW5kd2Vya2VyIiwiYSI6ImNrbm03NDg0ZDBraGQzMXFwdDJ0dG80eHEifQ.DZWxvdWOzqeVIzOSa9ngwg'
  },
  environmentName: 'DEV'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
