import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'volkshandwerker-check-reset',
  templateUrl: './check-reset.component.html',
  styleUrls: ['./check-reset.component.scss']
})
export class CheckResetComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
