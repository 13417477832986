import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BsDropdownConfig } from 'ngx-bootstrap/dropdown';
import { Toaster } from 'ngx-toast-notifications';
import { AccountService } from '../_services/account.service';
import { AlertService } from '../_services/alert.service';

@Component({
  selector: 'volkshandwerker-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss'],
  providers: [{ provide: BsDropdownConfig, useValue: { isAnimated: true, autoClose: true } }]
})
export class PaymentComponent implements OnInit {

  firmenForm: FormGroup;
  step = 1;
  submitted = false;
  email = JSON.parse(localStorage.getItem('user'))?.user?.email;

  paymentLoading = false;
  isLoadingCompany = false
  isSubscriberExists = false
  company = null
  subscriber = null

  cb1_checked = false;
  cb2_checked = false;
  cb3_checked = false;


  constructor(
    private router: Router,
    private accountService: AccountService,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    private toaster: Toaster
  ) { }


  ngOnInit(): void {
    this.firmenForm = this.formBuilder.group({
      companyName: ['', Validators.required],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      street: ['', Validators.required],
      houseNumber: ['', Validators.required],
      postalCode: ['', Validators.required],
      city: ['', Validators.required],
      countryIso2: ['de', Validators.required],
      gender: ['female', Validators.required],
      genderText: 'Frau',
      country: 'Deutschland',
      language: ['de'],
      packId: ['2'],
      telephone: ['', Validators.required],
      vatNumber: ['', Validators.required],
    })

    if (this.accountService.userValue.user.subscriber?.company?.subscriptionStatus == 'active') this.router.navigate(['/user-area/branches']);

    /*  this.accountService.makeGetRequest('/my-subscription').then(data => {
       if (data) {
         this.isSubscriberExists = true;
 
         this.firmenForm.controls['firstName'].setValue(data.firstName);
         this.firmenForm.controls['lastName'].setValue(data.lastName);
         this.firmenForm.controls['street'].setValue(data.street);
         this.firmenForm.controls['houseNumber'].setValue(data.houseNumber);
         this.firmenForm.controls['postalCode'].setValue(data.postalCode);
         this.firmenForm.controls['city'].setValue(data.city);
         this.firmenForm.controls['countryIso2'].setValue(data.countryIso2);
         this.firmenForm.controls['BIC'].setValue(data.BIC);
         this.firmenForm.controls['NameZahlungsempfaenger'].setValue(data.NameZahlungsempfaenger);
         this.firmenForm.controls['vatNumber'].setValue(data.vatNumber);
         this.firmenForm.controls['companyName'].setValue(data.company.name);
         this.firmenForm.controls['telephone'].setValue(data.telephone);
         this.company = data.company;
         this.subscriber = data;
       }
     }).catch(e => { }) */
    const data = this.accountService.userValue.user.subscriber
    if (data) {
      this.isSubscriberExists = true;
      this.firmenForm.controls['firstName'].setValue(data.firstName);
      this.firmenForm.controls['lastName'].setValue(data.lastName);
      this.firmenForm.controls['street'].setValue(data.street);
      this.firmenForm.controls['houseNumber'].setValue(data.houseNumber);
      this.firmenForm.controls['postalCode'].setValue(data.postalCode);
      this.firmenForm.controls['city'].setValue(data.city);
      this.firmenForm.controls['countryIso2'].setValue(data.countryIso2);
      this.firmenForm.controls['vatNumber'].setValue(data.vatNumber);
      this.firmenForm.controls['companyName'].setValue(data.company.name);
      this.firmenForm.controls['telephone'].setValue(data.telephone);
      this.firmenForm.controls['gender'].setValue(data.gender);
      this.company = data.company;
      this.subscriber = data;

      this.selectGender(data.gender)
      this.selectCountry(data.countryIso2)
    }
  }

  selectCountry(countryISO) {
    if (!countryISO) return
    this.firmenForm.controls.countryIso2.setValue(countryISO);
    let country = countryISO == 'de' ? 'Deutschland' : countryISO == 'at' ? 'Österreich' : 'Schweiz';
    this.firmenForm.controls.country.setValue(country);
  }

  selectGender(gender) {
    if (!gender) return
    this.firmenForm.controls.gender.setValue(gender)
    let _gender = gender == 'male' ? 'Herr' : gender == 'female' ? 'Frau' : gender == 'divers' ? 'Anrede' : 'Keine Angaben';
    this.firmenForm.controls.genderText.setValue(_gender)
  }

  next() {
    this.step++;
  }

  back() {
    this.step--;
  }

  createCompany() {
    this.isLoadingCompany = true;

    if (this.isSubscriberExists) {
      this.accountService.makePutRequest(`/subscribers/${this.subscriber.id}`, this.firmenForm.value).then(data => {
        this.isLoadingCompany = false;
        this.toaster.open({
          caption: 'Erfolgreich!',
          type: 'success',
          text: 'Ihre Unternehmensdaten wurden erfolgreich gespeichert.',
          position: 'bottom-left'
        });
        this.next();
        this.isSubscriberExists = true;
      }, error => {
        this.isLoadingCompany = false;
        this.toaster.open({
          caption: 'Fehler!',
          type: 'danger',
          text: error.error.error.message ?? 'Es ist ein Fehler aufgetreten. Bitte kontaktieren Sie uns.',
          position: 'bottom-left'
        });
      });
    }
    else {
      this.accountService.makePostRequest('/subscribers', this.firmenForm.value).then(company => {
        this.isLoadingCompany = false;

        this.alertService.success('Created Company successful', { keepAfterRouteChange: true });

        this.toaster.open({
          caption: 'Erfolgreich!',
          type: 'success',
          text: 'Ihre Daten wurden erfolgreich angelegt.',
          position: 'bottom-left'
        });

        // Redirect to payment
        this.next()

        this.isSubscriberExists = true;

        //this.accountService.updateUserInfo()
      },
        error => {
          this.isLoadingCompany = false;
          this.toaster.open({
            caption: 'Fehler!',
            type: 'danger',
            text: error.error.error.message ?? 'Es ist ein Fehler aufgetreten. Bitte kontaktieren Sie uns.',
            position: 'bottom-left'
          });
        });
    }


  }

  subscribe() {
    this.submitted = true;
    if (!(this.cb2_checked && this.cb3_checked))
      return

    this.accountService.makePutRequest(`/user/newsletter`, { newsletter: this.cb1_checked })
    this.paymentLoading = true;
    this.accountService.makePayment('/stripe').then(data => {
      document.location.href = data.url
    }, error => {
      this.toaster.open({
        caption: 'Fehler!',
        type: 'danger',
        text: error.message ?? 'An error has occurred. Please contact us.',
        position: 'bottom-left'
      });
      this.paymentLoading = false;
    })
  }

  onSubmit() {
    this.submitted = true;
    if (this.firmenForm.invalid) {
      return;
    }
    this.createCompany()
    //this.next();
  }

}
